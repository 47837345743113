import React from "react";
import "./DetailsGroup3.css";

function DetailsGroup3(props) {
  const { group1 } = props;

  return (
    <div className='details-group-2 poppins-medium-black-15px'>
      <div className='number-of-materials-2'>
        <img
          className='group-26'
          src={group1}
          alt={group1}
        />
        <div className='address-29 poppins-medium-black-15px'>5 Materials</div>
      </div>
      <div className='number-of-videos-2'>
        <div className='address-29'>350 Video</div>
        <img
          className='group-24'
          src='/img/group-80@2x.png'
          alt='Group'
        />
      </div>
      <div className='number-of-pdf-2'>
        <div className='address-29'>120 PDF</div>
        <img
          className='group-24'
          src='/img/group-81@2x.png'
          alt='Group'
        />
      </div>
      <div className='number-of-quizes-2'>
        <div className='address-29'>20 Quizes</div>
        <img
          className='quiz-icon-1-14'
          src='/img/quiz-icon-1-14@2x.svg'
          alt='quiz icon 1'
        />
      </div>
    </div>
  );
}

export default DetailsGroup3;
