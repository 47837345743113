import { useState } from "react";
import Button from "react-bootstrap/Button";
import { Row, Col, Form } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import { useTranslation } from "../translationUtils";
import { useDispatch } from "react-redux";
import { login, setTableName, setFadeLoginModal } from "../../store/CheckLogin";
import { useMediaQuery } from "react-responsive";
import CountryDropDown from "./CountryDropDown";
import ResetPwd from "./ResetPwd";
import TextTR from "../HomePage/TextTR";

const LoginByUser = (props) => {
  function formatDate(date) {
    const options = {
      day: "numeric",
      month: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    };

    return date.toLocaleString("en-US", options);
  }

  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ query: "(max-width:650px)" });

  const { getTranslatedWord } = useTranslation();

  const [parameter, setParameter] = useState("");
  const [password, setPassword] = useState("");
  const [countryCode, setCountryCode] = useState("961");
  const [isLoading, setIsLoading] = useState(false);

  const submitHandler = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    let formData = new FormData();
    formData.append(
      "token",
      localStorage.getItem("login-token") ??
        sessionStorage.getItem("login-token")
    );
    formData.append("parameter", parameter);
    formData.append("password", password);
    formData.append("country_code", countryCode);
    formData.append("is_web", true);
    formData.append("device_data", JSON.stringify(props.device_data));

    await axios
      .post(`${props.url}users/login`, formData)
      .then((res) => {
        if (res.data.succeeded) {
          localStorage.setItem("loginData", JSON.stringify(res.data));
          localStorage.setItem("enterTime", formatDate(new Date()));
          localStorage.setItem("login-token", res.data.token);
          dispatch(login(res.data.user));
          dispatch(setTableName(res.data.table_name));
          Swal.fire({
            icon: "success",
            title: getTranslatedWord(res.data.title),
            text: getTranslatedWord(res.data.message),
          });
        } else {
          Swal.fire({
            icon: "error",
            title: getTranslatedWord(res.data.title),
            text: getTranslatedWord(res.data.message),
          });
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const countryCodeHandler = (v) => {
    setCountryCode(v);
  };

  return (
    <Col xs={12} md={6} className="!grid !place-items-center">
      <Form onSubmit={submitHandler}>
        <Row className="!mb-4">
          <Form.Group>
            <Form.Label className="bold-text">
              <TextTR text={"lbl_phone_number"} />
            </Form.Label>
            <Row>
              <Col
                xs={isMobile ? 4 : 3}
                style={isMobile ? null : { paddingRight: "0px" }}
              >
                <CountryDropDown setValue={countryCodeHandler} />
              </Col>
              <Col
                xs={isMobile ? 8 : 9}
                style={isMobile ? { paddingLeft: "0px" } : null}
              >
                <Form.Control
                  type="text"
                  placeholder={
                    isMobile
                      ? "Phone Number / UserName"
                      : "Phone Number / UserName"
                  }
                  value={parameter}
                  onChange={(event) => setParameter(event.target.value)}
                />
              </Col>
            </Row>
          </Form.Group>
        </Row>
        <Row className="!my-4">
          <Form.Group>
            <Form.Label className="bold-text">
              <TextTR text={"lbl_enter_password"} />
            </Form.Label>
            <Form.Control
              type="password"
              placeholder={"Enter Password"}
              value={password}
              onChange={(event) => setPassword(event.target.value)}
            />
          </Form.Group>
          <div
            className="!mt-3 forget-password poppins-medium-blue-13px"
            style={{ cursor: "pointer" }}
            onClick={() => dispatch(setFadeLoginModal(true))}
          >
            <ResetPwd
              url={props.url}
              btnText={<TextTR text={"msg_forget_password"} />}
              isBtnDisabled={true}
              showResetPwd={true}
            />
          </div>
        </Row>
        <Row>
          <Button
            className="!bg-[#0d6efd] !text-white !m-auto !w-auto"
            variant="primary"
            type="submit"
            disabled={isLoading}
          >
            {isLoading ? "Please Wait" : <TextTR text={"lbl_submit"} />}
          </Button>
        </Row>
      </Form>
    </Col>
  );
};

export default LoginByUser;
