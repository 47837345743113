import { useEffect, useRef, useState } from "react";
import "./notification.css";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { Overlay, Popover } from "react-bootstrap";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import TextTR from "../HomePage/TextTR";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useMediaQuery } from "react-responsive";
import { useDispatch, useSelector } from "react-redux";
import {
  setNotificationDrawer,
  setUnReadNotifications,
} from "../../store/CheckLogin";
//new
import Tr from "../translation/Utils";
import Badge from "react-bootstrap/Badge";

function Notification(props) {
  const notificationDrawer = useSelector(
    (state) => state.checkLogin.notificationDrawer
  );
  // new
  const unRedNotifs = useSelector(
    (state) => state.checkLogin.unReadNotifications
  );
  //
  const dispatch = useDispatch();
  const [target, setTarget] = useState(null);
  const ref = useRef(null);

  const [getNotification, setNotification] = useState([]);
  const [NotificationLength, setNotificationLength] = useState();
  const [offset, setOffset] = useState(0);

  function getData() {
    let formData = new FormData();
    formData.append(
      "token",
      localStorage.getItem("login-token") ??
        sessionStorage.getItem("login-token")
    );
    formData.append("offset", offset);
    const url1 = `${props.url}side/get-notifications`;
    fetch(url1, {
      method: "POST",
      body: formData,
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(res.statusText);
        }
        return res.json();
      })
      .then((res) => {
        if (!res.logged_out) {
          setNotification((prevNotifications) => [
            ...prevNotifications,
            ...res.notifications,
          ]);
        }
        setNotificationLength(res?.notifications?.length);
      })
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    if (
      localStorage.getItem("login-token") ||
      sessionStorage.getItem("login-token")
    ) {
      getData();
    }
  }, []);

  const handleClick = (event) => {
    if (!notificationDrawer) {
      dispatch(setNotificationDrawer(true));
    } else {
      dispatch(setNotificationDrawer(false));
    }
    setTarget(event.target);
    setOffset(0);
    dispatch(setUnReadNotifications(0));
  };

  const [notificationEnd, setNotificationEnd] = useState(false);
  function handleLoadMore() {
    if (NotificationLength == 0) {
      setNotificationEnd(true);
      return;
    }
    setOffset(offset + 15);
    getData();
  }

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
    dispatch(setNotificationDrawer(false));
  };
  const handleClose = () => setOpen(false);

  /**for responsivity */
  const isMobile = useMediaQuery({ query: "(max-width:650px)" });

  return (
    <>
      <div
        className="notification-container"
        onClick={handleClick}
        style={{ cursor: "pointer" }}
      >
        {unRedNotifs > 0 && (
          <Badge pill bg="danger" className="!absolute !left-1/2 !-top-3">
            {unRedNotifs}
          </Badge>
        )}
        <NotificationsIcon />
      </div>
      <div ref={ref}>
        <Overlay
          show={notificationDrawer}
          target={target}
          placement="bottom"
          container={ref.current}
          containerPadding={20}
          style={{ zIndex: "10 !important" }}
        >
          <Popover
            id="popover-contained"
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Popover.Header
              as="h3"
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-start",
              }}
            >
              <TextTR text={"lbl_notifications"} />
              <TextTR
                text={"lbl_see_all"}
                onClick={handleOpen}
                style={{ cursor: "pointer" }}
              />
            </Popover.Header>
            <Popover.Body
              style={{
                marginTop: "5px",
                padding: "5px !important",
                height: "300px",
                overflowY: "auto",
                display: "block !important",
              }}
            >
              <div
                className="notification-items-container"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                {getNotification.length === 0 ? (
                  <h6 style={{ color: "#ccc" }}>No notifications</h6>
                ) : (
                  <>
                    {getNotification ? (
                      getNotification &&
                      getNotification?.map(
                        (notificationItem, notificationIndex) => {
                          return (
                            <div
                              key={notificationIndex}
                              className={`${
                                notificationItem.is_seen === 1
                                  ? "!bg-[#e6f9ff]"
                                  : "!bg-red-300"
                              }`}
                              style={{
                                width: "100%",
                                display: "flex",
                                gap: "3px",
                                alignItems: "flex-start",
                                padding: "5px",
                                borderRadius: "5px",
                              }}
                            >
                              <InfoOutlinedIcon style={{ color: "#340ffe" }} />
                              <div
                                className="notification-text-container"
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "flex-start",
                                }}
                              >
                                <h6 style={{ color: "#304ffe" }}>
                                  {" "}
                                  {notificationItem.title}
                                </h6>
                                <span>{notificationItem.body}</span>
                                <span style={{ color: "#304ffe" }}>
                                  {notificationItem.created_at}
                                </span>
                              </div>
                            </div>
                          );
                        }
                      )
                    ) : (
                      <img src="./img/notification-loading.gif" alt="loading" />
                    )}
                  </>
                )}
              </div>
            </Popover.Body>
          </Popover>
        </Overlay>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style} style={isMobile ? { width: "90% " } : null}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              style={{ color: "#343ffe" }}
            >
              {Tr("lbl_notifications")}
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <div
                className="notification-items-container"
                style={{
                  display: "block !important",
                  gap: "10px",
                  height: "60vh",
                  overflowY: "scroll",
                  textAlign: "center",
                }}
              >
                {getNotification?.length == 0 ? (
                  <h6 style={{ color: "#ccc" }}>No notifications</h6>
                ) : (
                  <>
                    {getNotification ? (
                      getNotification &&
                      getNotification?.map(
                        (notificationItem, notificationIndex) => {
                          return (
                            <div
                              key={notificationIndex}
                              className={`${
                                notificationItem.is_seen === 1
                                  ? "!bg-[#e6f9ff]"
                                  : "!bg-red-300"
                              }`}
                              style={{
                                width: "100%",
                                display: "flex",
                                gap: "3px",
                                alignItems: "flex-start",
                                padding: "5px",
                                borderRadius: "5px",
                                marginTop: "15px",
                              }}
                            >
                              <InfoOutlinedIcon style={{ color: "#340ffe" }} />
                              <div
                                className="notification-text-container"
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "flex-start",
                                }}
                              >
                                <h6 style={{ color: "#304ffe" }}>
                                  {" "}
                                  {notificationItem.title}
                                </h6>
                                <span>{notificationItem.body}</span>
                                <span style={{ color: "#304ffe" }}>
                                  {notificationItem.created_at}
                                </span>
                              </div>
                            </div>
                          );
                        }
                      )
                    ) : (
                      <img src="./img/notification-loading.gif" alt="loading" />
                    )}
                  </>
                )}
                <span
                  onClick={handleLoadMore}
                  className="load-more-notification !text-center"
                  style={
                    notificationEnd
                      ? { color: "#ccc", cursor: "default" }
                      : null
                  }
                >
                  {notificationEnd
                    ? "No More Notifications"
                    : Tr("lbl_load_more")}
                </span>
              </div>
            </Typography>
          </Box>
        </Modal>
      </div>
    </>
  );
}

export default Notification;
