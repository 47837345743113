import { useState, useEffect } from "react";
import axios from "axios";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Form } from "react-bootstrap";
import Tr from "../translation/Utils";
import Swal from "sweetalert2";
import { useTranslation } from "../translationUtils";

/**for the modal */
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
function RequestCode(props) {
  const { getTranslatedWord, setLanguage } = useTranslation();

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [requestCodeData, setRequestCodeData] = useState([]);

  /**handling the ReqCode Handler */

  const [request_text, setRequestText] = useState("Request Code");

  const [requestQty, setRequestQty] = useState(1);
  async function handleCodeBtnClick(event) {
    event.preventDefault();
    handleClose();
    setRequestText("Pending...");
    let formData = new FormData();
    formData.append(
      "token",
      localStorage.getItem("login-token") ??
        sessionStorage.getItem("login-token")
    );
    formData.append("group_id", props.group_id);
    formData.append("quantity", requestQty);

    const response = await axios
      .post(`${props.url}material/request-code`, formData)
      .then((res) => {
        setRequestCodeData(res);
        Swal.fire({
          icon: "success",
          title: getTranslatedWord("req_comp"),
          text: getTranslatedWord("your_req"),
        });
      })
      .catch((err) => {
        // alert(err.message);
      });
  }

  return (
    <div>
      <Button
        onClick={handleOpen}
        style={{ background: "green", color: "white", whiteSpace: "nowrap" }}
      >
        {request_text}
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <Typography
            id='modal-modal-title'
            variant='h6'
            component='h2'
          >
            How Many Codes Do You Want
          </Typography>
          <Typography
            id='modal-modal-description'
            sx={{ mt: 2 }}
          >
            <Form.Group>
              <Form.Control
                type='number'
                placeholder='Enter the Quantity'
                value={requestQty}
                onChange={(event) => setRequestQty(event.target.value)}
              />
            </Form.Group>
            <Button
              onClick={handleCodeBtnClick}
              variant='contained'
              style={{
                background: "green",
                color: "white",
                whiteSpace: "nowrap",
                marginTop: "15px",
              }}
            >
              Submit Request
            </Button>
          </Typography>
        </Box>
      </Modal>
    </div>
    // <>
    //   <div className='req-code-btn'>

    //   </div>
    // </>
  );
}
export default RequestCode;
