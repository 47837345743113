import { useState, useEffect } from "react";
import axios from "axios";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import RequestCode from "./RequestCode";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
function GetCode(props) {
  /**showing the dialog */
  const style = {
    // position: "absolute",
    // top: "50%",
    // left: "50%",
    // transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  const [open, setOpen] = useState(false);
  const history = useHistory();
  function handleOpen() {
    history.push("/payment-method");
    // if (
    //   localStorage.getItem("login-token") ||
    //   sessionStorage.getItem("login-token")
    // ) {
    //   setOpen(true);
    // } else {
    //   Swal.fire({
    //     icon: "warning",
    //     title: "Not Logged In",
    //     text: "Please Login!",
    //   });
    // }
  }
  const handleClose = () => setOpen(false);
  const [searchQuery, setSearchQuery] = useState("");

  const [requestCodeData, setRequestCodeData] = useState([]);
  function getRequestData(searched = "") {
    let formData = new FormData();
    formData.append(
      "token",
      localStorage.getItem("login-token") ??
        sessionStorage.getItem("login-token")
    );
    formData.append("offset", 0);
    formData.append("search_text", searched);
    const url1 = `${props.url}material/search-codes`;
    fetch(url1, {
      method: "POST",
      body: formData,
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(res.statusText);
        }
        return res.json();
      })
      .then((res) => {
        setRequestCodeData(res);
      })
      .catch((err) => console.log(err));
  }

  // console.log(gradeCards);

  useEffect(() => {
    getRequestData();
  }, []);

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
    getRequestData(event.target.value);
  };

  return (
    <>
      <div className="get-code-container">
        <div className="get-code" onClick={handleOpen}>
          Buy Course
        </div>
      </div>
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style} style={{ width: "40%" }}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Request a Code
            </Typography>
            <Typography>
              <TextField
                style={{ width: "100%", marginTop: "10px" }}
                id="outlined-basic"
                label="Search a Code"
                variant="outlined"
                value={searchQuery}
                onChange={handleSearch}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Typography>

            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <div className="request-codes-container">
                {/* for the search */}
                {/**search bu grade title */}
                <div className="request-codes-container">
                  {requestCodeData &&
                    requestCodeData.codes?.map((item, index) => {
                      return (
                        <div key={index} className="req-code-container">
                          <div className="req-code-text-container">
                            <span className="req-code-title">{item.title}</span>
                            <div className="req-code-sub-text">
                              <span className="req-code-price">
                                {item.price} L.L
                              </span>
                              <span className="req-code-date">
                                {item.days} days
                              </span>
                            </div>
                          </div>
                          <RequestCode url={props.url} group_id={item.id} />
                        </div>
                      );
                    })}
                </div>
              </div>
            </Typography>
          </Box>
        </Modal>
      </div>
    </>
  );
}
export default GetCode;

{
  /**search here */
}
{
  /* <div className="request-codes-container">
                {filteredRequestCodeData.map((item, index) => {
                  return (
                    <div key={index} className="req-code-container">
                      <div className="req-code-text-container">
                        <span className="req-code-title">{item.title}</span>
                        <div className="req-code-sub-text">
                          <span className="req-code-price">{item.price} L.L</span>
                          <span className="req-code-date">{item.days} days</span>
                        </div>
                      </div>
                      <RequestCode group_id={item.group_id} />
                    </div>
                  );
                })}
              </div> */
}
