import React from "react";
import "./DetailsGroup.css";

function DetailsGroup(props) {
  const { materialsCount, videosCount, pdfCount, quizesCount } = props;
  return (
    <div className="details-group poppins-medium-black-11px">
      <div className="number-of-materials">
        <img className="group-6" src="/img/group-33@2x.png" alt="Group" />
        <div className="address-22 poppins-medium-black-11px">
          {materialsCount}
        </div>
      </div>
      <div className="number-of-videos">
        <div className="address-23">{videosCount}</div>
        <img className="group-7" src="/img/group-34@2x.png" alt="Group" />
      </div>
      <div className="number-of-pdf">
        <div className="address-23">{pdfCount}</div>
        <img className="group-10" src="/img/group-35@2x.png" alt="Group" />
      </div>
      <div className="number-of-quizes">
        <div className="address-23">{quizesCount}</div>
        <img
          className="quiz-icon-1"
          src="/img/quiz-icon-1@2x.svg"
          alt="quiz icon 1"
        />
      </div>
    </div>
  );
}

export default DetailsGroup;
