import React from "react";
import VideosDropdownForMyLearning from "../VideosDropdownForMyLearning";
import "./UnitsDropDown.css";
import Accordion from "react-bootstrap/Accordion";

function UnitsDropDown(props) {
  const { videosDropdownForMyLearningProps } = props;

  return (
    <div className='units-drop-down'>
      <VideosDropdownForMyLearning
        frame1521Props={videosDropdownForMyLearningProps.frame1521Props}
        frame1522Props={videosDropdownForMyLearningProps.frame1522Props}
        frame1523Props={videosDropdownForMyLearningProps.frame1523Props}
      />
    </div>
  );
}

export default UnitsDropDown;
