import { Container } from "react-bootstrap";
import { useState, useEffect } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import TextTR from "../HomePage/TextTR";
import { setAboutUsLang } from "../../store/DataFetched";
import { translationAction } from "../../store/tr-slice";
import { useDispatch } from "react-redux";

const SelectLanguage = ({ languages }) => {
  const dispatch = useDispatch();

  const sc_local = localStorage.getItem("lang-shortcut") ?? "en";

  let sc;
  if (!sc_local || sc_local == "null") {
    sc = navigator.language.split("-")[0];
  } else {
    sc = sc_local;
  }
  if (sc) {
    dispatch(translationAction.setShortcut(sc));
    // console.log("i set the sc to the redux as: ", sc);
  } else {
    dispatch(translationAction.setShortcut("en"));
    // console.log("i set the sc to the redux as 'en'");
  }

  let selectedLanguage;
  const [language, setLanguage] = useState(
    localStorage.getItem("lang-shortcut")
      ? localStorage.getItem("lang-shortcut")
      : "en"
  );

  const setReduxLangId = (selectedLanguage) => {
    if (languages) {
      const languageObj = languages?.find(
        (lang) => lang.shortcut === selectedLanguage
      );
      return languageObj?.id ?? 1;
    } else {
      return 1;
    }
  };

  function getTranslation() {
    let formData = new FormData();
    var date = localStorage.getItem("tr_modif_date");
    if (!date) {
      date = "1970-01-01 00:00:00";
    }
    formData.append("date", date);

    const url1 = `https://stdev.beeflex.net/master/translations`;
    fetch(url1, {
      method: "POST",
      body: formData,
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(res.statusText);
        }
        return res.json();
      })
      .then((res) => {
        if (!res.is_updated) {
          var date = res.date;
          var list = res.list;

          localStorage.setItem("tr_modif_date", date);
          localStorage.setItem("tr_list", JSON.stringify(list));
        } else {
          var list = localStorage.getItem("tr_list");
        }
      })
      .catch((err) => console.log(err));

    dispatch(translationAction.setTranslation(localStorage.getItem("tr_list")));
  }

  useEffect(() => {
    getTranslation();
  }, []);

  const languageChangeHandler = (event) => {
    selectedLanguage = event.target.value;
    setLanguage(selectedLanguage);
    localStorage.setItem("lang-shortcut", selectedLanguage);
    dispatch(setAboutUsLang(setReduxLangId(selectedLanguage)));
  };

  return (
    <Container className="md:!mb-32 !mt-32 !p-4 !m-auto !w-[80%] !shadow-md !bg-[#fdfafa] !rounded-lg">
      <FormControl>
        <FormLabel id="demo-radio-buttons-group-label">
          <TextTR text={"lbl_app_language"} />
        </FormLabel>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue={language}
          value={language}
          name="radio-buttons-group"
          onChange={languageChangeHandler}
        >
          {languages?.map((l, i) => (
            <FormControlLabel
              key={i}
              value={l.shortcut}
              control={<Radio />}
              label={l.language_name?.toUpperCase()}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </Container>
  );
};

export default SelectLanguage;
