import { useState } from "react";
import DeliveryPaymentModal from "./DeliveryPaymentModal";
import WhishPaymentModal from "./WhishPaymentModal";
import OMTPaymentModal from "./OMTPaymentModal";

const PaymentMethod = () => {
  const [openDialog, setOpenDialog] = useState({
    delivery: false,
    whish: false,
    omt: false,
  });

  return (
    <>
      {openDialog.delivery && (
        <DeliveryPaymentModal
          open={openDialog.delivery}
          handleClose={() =>
            setOpenDialog((prev) => ({ ...prev, delivery: false }))
          }
        />
      )}
      {openDialog.whish && (
        <WhishPaymentModal
          open={openDialog.whish}
          handleClose={() =>
            setOpenDialog((prev) => ({ ...prev, whish: false }))
          }
        />
      )}
      {openDialog.omt && (
        <OMTPaymentModal
          open={openDialog.omt}
          handleClose={() => setOpenDialog((prev) => ({ ...prev, omt: false }))}
        />
      )}
      <div
        className="md:mt-36 max-w-full md:max-w-[500px] py-3 my-[195px] mx-auto
    flex flex-col gap-3 shadow-2xl shadow-gray-300 rounded-[10px]"
      >
        <h1 className="text-center font-bold text-lg md:text-xl">
          Order Methods
        </h1>
        <div className="flex flex-col gap-3 rounded-lg p-4">
          <button
            className="bg-[#304ffe] text-white rounded px-4 py-2 text-center font-semibold hover:opacity-90 md:px-6 md:py-3"
            onClick={() =>
              setOpenDialog((prev) => ({ ...prev, delivery: true }))
            }
          >
            Delivery
            <br />
            <span className="block md:inline">
              Delivery cost will be added to the card price
            </span>
          </button>
          <button
            className="bg-[#304ffe] text-white rounded px-4 py-2 text-center font-semibold hover:opacity-90 md:px-6 md:py-3"
            onClick={() => setOpenDialog((prev) => ({ ...prev, whish: true }))}
          >
            Order by WHISH MONEY
          </button>
          <button
            className="bg-[#304ffe] text-white rounded px-4 py-2 text-center font-semibold hover:opacity-90 md:px-6 md:py-3"
            onClick={() => setOpenDialog((prev) => ({ ...prev, omt: true }))}
          >
            Order by OMT
          </button>
        </div>
      </div>
    </>
  );
};

export default PaymentMethod;
