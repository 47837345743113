import React from "react";
import "./CustomersDropDown.css";
import Dropdown from "react-bootstrap/Dropdown";
import { useState, useEffect } from "react";

/*material drop down */
import InputLabel from "@mui/material/InputLabel";
// import option from "@mui/material/option";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Box } from "@mui/material";
import Tr from "../translation/Utils";
import NativeSelect from "@mui/material/NativeSelect";

function CustomersDropDown(props) {
  const { selectedLabel, option1, option2, className } = props;

  const [isOpen, setIsOpen] = useState(false);

  const [selectedValue, setSelectedValue] = useState("");

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    props.onSelection(event.target.value, props.type);
  };

  // useEffect(() => {
  //   console.log(props.gradeTypeDropMap);
  // }, [props.gradeTypeDropMap]);

  return (
    <>
      <Box sx={{ minWidth: 120 }}>
        <FormControl fullWidth>
          <InputLabel
            id='demo-simple-select-label'
            style={{ fontWeight: "bold", fontSize: "16px", color: "red" }}
          >
            {selectedLabel}
          </InputLabel>
          <NativeSelect
            value={selectedValue}
            label='Country'
            onChange={handleChange}
          >
            <option value={null}></option>
            {/* <option value={"All"}>All</option> */}
            {props.countryDropMap &&
              props.countryDropMap?.map((dropItem, dropIndex) => {
                return (
                  <option
                    key={dropIndex}
                    value={dropItem.id}
                  >
                    {dropItem.country_name}
                  </option>
                );
              })}
            {props.langDropMap &&
              props.langDropMap?.map((dropItem, dropIndex) => {
                if (dropItem.is_grade == "1") {
                  return (
                    <option
                      key={dropIndex}
                      value={dropItem.id}
                    >
                      {dropItem.language_name}
                    </option>
                  );
                }
              })}

            {props.gradeTypeDropMap &&
              props.gradeTypeDropMap?.map((dropItem, dropIndex) => {
                return (
                  <option
                    key={dropIndex}
                    value={dropItem.id}
                  >
                    {dropItem.type}
                  </option>
                );
              })}
          </NativeSelect>
          {/* <Select
            labelId='demo-simple-select-label'
            id='demo-simple-select'
            value={selectedValue}
            label='Country'
            onChange={handleChange}
          >
            <option value={null}>All</option>
            {props.countryDropMap &&
              props.countryDropMap?.map((dropItem, dropIndex) => {
                return (
                  <option
                    key={dropIndex}
                    value={dropItem.id}
                  >
                    {dropItem.country_name}
                  </option>
                );
              })}
            {props.langDropMap &&
              props.langDropMap?.map((dropItem, dropIndex) => {
                if (dropItem.is_grade == "1") {
                  return (
                    <option
                      key={dropIndex}
                      value={dropItem.id}
                    >
                      {dropItem.language_name}
                    </option>
                  );
                }
              })}

            {props.gradeTypeDropMap &&
              props.gradeTypeDropMap?.map((dropItem, dropIndex) => {
                return (
                  <option
                    key={dropIndex}
                    value={dropItem.id}
                  >
                    {dropItem.type}
                  </option>
                );
              })}
          </Select> */}
        </FormControl>
      </Box>
    </>
  );
}

export default CustomersDropDown;
