import { useState } from "react";
import { Button, DialogTitle, InputAdornment, TextField } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import Tr from "../translation/Utils";

const WhishPaymentModal = ({ open, handleClose }) => {
  const [price, setPrice] = useState(0);

  const offers = JSON.parse(localStorage.getItem("offers"));

  const handleNbCardsChange = (event) => {
    const { value } = event.target;
    const numberOfCards = parseInt(value, 10);

    if (!isNaN(numberOfCards) && numberOfCards > 0) {
      let closestOffer = null;
      for (let offer of offers) {
        if (offer.cards_count <= numberOfCards) {
          if (!closestOffer || offer.cards_count > closestOffer.cards_count) {
            closestOffer = offer;
          }
        }
      }

      if (closestOffer) {
        const calculatedPrice =
          (closestOffer.price / closestOffer.cards_count) * numberOfCards;
        setPrice(calculatedPrice);
      } else {
        setPrice(0);
      }
    } else {
      setPrice(0);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" sx={{ textAlign: "center" }}>
        WHISH Form
      </DialogTitle>
      <DialogContent
        dir="rtl"
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
        }}
      >
        <p>
          {Tr(
            "via the whish application or from any whish center to the  number :"
          )}{" "}
          <span className="m-1 font-bold text-blue-700">71299280</span>
        </p>
        <p>
            {Tr("بعد دفع المبلغ أرسل صورة عن الوصل للرقم 71299280 و بذات اليوم نرسل لك كود الإشتراك مع كيفية تفعيله")}
          </p>
        <div className="w-full my-2">
          <TextField
            label="Number of cards"
            name="numberOfCards"
            type="number"
            onChange={handleNbCardsChange}
            sx={{ m: 1, width: "100%" }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <FormatListNumberedIcon />
                </InputAdornment>
              ),
            }}
            required
          />
          <p>
            {Tr("Enter the number of needed cards to know the total price")}
          </p>
        </div>
      </DialogContent>
      <div dir="rtl" className="px-2">
        <p className="font-bold">Price: ${price}</p>
      </div>
      <DialogActions
        sx={{
          position: "sticky",
          bottom: 0,
          backgroundColor: "white",
          p: 3,
          z: 100,
        }}
      >
        <Button
          type="button"
          color="error"
          onClick={handleClose}
          variant="contained"
          sx={{ zIndex: 200, ml: 2 }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default WhishPaymentModal;
