import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { Button } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import TextTR from "../HomePage/TextTR";
import { useTranslation } from "../translationUtils";
//
import Modal from "react-bootstrap/Modal";
import Accordion from "react-bootstrap/Accordion";
import Checkbox from "@mui/material/Checkbox";
import Spinner from "react-bootstrap/Spinner";

function RedeemCode(props) {
  const { getTranslatedWord } = useTranslation();

  const [redeemCode, setredeemCode] = useState("");
  const [redeemCodeRes, setRedeemCodeRes] = useState();
  const [tries, setTries] = useState(
    localStorage.getItem("redeemCodeTries") || 0
  );
  const [isLocked, setIsLocked] = useState(false);
  const [lockEndTime, setLockEndTime] = useState(
    localStorage.getItem("redeemCodeLockEndTime") || null
  );
  //new
  const [openModal, setOpenModal] = useState(false);
  const [sendingCodeLoading, setSendingCodeLoading] = useState(false);
  const [btnDeisabled, setBtnDisabled] = useState(true);
  const [checkedOptions, setCheckedOptions] = useState([]);
  const [disableOptions, setDisableOptions] = useState(false);
  const [activatedLoading, setActivatedLoading] = useState(false);
  const [isSingleOption, setIsSingleOption] = useState(false);

  useEffect(() => {
    localStorage.setItem("redeemCodeTries", tries);
  }, [tries]);
  useEffect(() => {
    localStorage.setItem("redeemCodeIsLocked", isLocked);
  }, [isLocked]);
  useEffect(() => {
    localStorage.setItem("redeemCodeLockEndTime", lockEndTime);
  }, [lockEndTime]);
  useEffect(() => {
    // Check if the lock has ended and update the state accordingly
    if (isLocked) {
      const remainingTime = lockEndTime - new Date().getTime() / 1000;
      if (remainingTime <= 0) {
        setIsLocked(false);
        setTries(0);
      }
    }
  }, [isLocked, lockEndTime]);

  async function handleredeemCodeSubmit(event) {
    setSendingCodeLoading(true);
    event.preventDefault();

    let formData = new FormData();
    formData.append(
      "token",
      localStorage.getItem("login-token") ??
        sessionStorage.getItem("login-token")
    );
    formData.append("code_number", redeemCode);

    await axios
      .post(`${props.url}material/redeem-code`, formData)
      .then((res) => {
        if (res.data && res.data.should_select_option) {
          if (res.data.option_selection_limit === 1) {
            setIsSingleOption(true);
          }
          setRedeemCodeRes(res.data);
          setOpenModal(true);
          setSendingCodeLoading(false);
        } else {
          Swal.fire(getTranslatedWord(res.data.message));
          setSendingCodeLoading(false);
          setredeemCode("");
          setBtnDisabled(true);
          props.onRedeem();
        }
      })
      .catch((err) => {
        setSendingCodeLoading(false);
        setredeemCode("");
        setBtnDisabled(true);
        Swal.fire(
          "Redemption failed!",
          getTranslatedWord(err.message),
          "error"
        );
      });
  }

  const handleCheckOption = (e, id, isCheckBox) => {
    if (isCheckBox) {
      if (e.target.checked) {
        if (!disableOptions) {
          setCheckedOptions((prevOpt) => [...prevOpt, id]);
        }
      } else {
        setCheckedOptions((prevOpt) =>
          prevOpt.filter((optionId) => optionId !== id)
        );
      }
    } else {
      setCheckedOptions([id]);
    }
  };

  useEffect(() => {
    if (Object.keys(checkedOptions).length > 0) {
      if (
        redeemCodeRes?.option_selection_limit ===
        Object.keys(checkedOptions).length
      ) {
        setDisableOptions(true);
      } else {
        setDisableOptions(false);
      }
    }
  }, [checkedOptions]);

  const handleActivateOptions = async () => {
    setActivatedLoading(true);
    let formData = new FormData();
    formData.append(
      "token",
      localStorage.getItem("login-token") ??
        sessionStorage.getItem("login-token")
    );
    formData.append("code_number", redeemCode);
    formData.append("selected_options", JSON.stringify(checkedOptions));

    //const response =
    await axios
      .post(`${props.url}material/redeem-code`, formData)
      .then((res) => {
        if (res.data.succeeded) {
          setOpenModal(false);
          props.onRedeem();
          Swal.fire(getTranslatedWord(res.data.message));
        } else {
          Swal.fire(getTranslatedWord(res.data.message));
        }
        setActivatedLoading(false);
        setredeemCode("");
        setBtnDisabled(true);
      })
      .catch((err) => {
        setActivatedLoading(false);
        Swal.fire(
          "Redemption failed!",
          getTranslatedWord(err.message),
          "error"
        );
      });
  };

  const redeemCodeHandler = (e) => {
    if (e.target.value.length != 16) {
      setredeemCode(e.target.value);
      setBtnDisabled(true);
    } else {
      setBtnDisabled(false);
      setredeemCode(e.target.value);
    }
  };
  return (
    <>
      <div className="redeam-code-section">
        <Modal
          show={openModal}
          onHide={() => {
            setOpenModal(false);
            setDisableOptions(false);
            setCheckedOptions([]);
          }}
          scrollable={true}
          centered={true}
          animation={true}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              Allowed Options: {redeemCodeRes?.option_selection_limit}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="max-[500px]:!pl-2">
            {redeemCodeRes?.options?.length > 0 ? (
              <Accordion alwaysOpen>
                {redeemCodeRes?.options?.map((r, i) => (
                  <div
                    key={i}
                    className="mb-2 w-full flex items-center justify-start"
                  >
                    {!isSingleOption && (
                      <Checkbox
                        disabled={
                          disableOptions && !checkedOptions?.includes(r.id)
                        }
                        onChange={(e) => handleCheckOption(e, r.id, true)}
                      />
                    )}
                    {isSingleOption && (
                      <Form.Check
                        type="radio"
                        name="options"
                        value={r.id}
                        onChange={(e) => handleCheckOption(e, r.id, false)}
                        className="!mr-3 !cursor-pointer"
                      />
                    )}
                    <Accordion.Item eventKey={i} className="w-full">
                      <Accordion.Header>{r.title}</Accordion.Header>
                      <Accordion.Body>
                        <div>
                          {r.grades.length > 0 && (
                            <Accordion>
                              <Accordion.Item eventKey={i}>
                                <Accordion.Header>Grades</Accordion.Header>
                                <Accordion.Body>
                                  <ul className="list-[square]">
                                    {r.grades.map((g, j) => (
                                      <li key={j} className="!ml-2 !text-black">
                                        {g.title}
                                      </li>
                                    ))}
                                  </ul>
                                </Accordion.Body>
                              </Accordion.Item>
                            </Accordion>
                          )}
                          {r.materials.length > 0 && (
                            <Accordion className="!mt-2">
                              <Accordion.Item eventKey={i}>
                                <Accordion.Header>Materials</Accordion.Header>
                                <Accordion.Body>
                                  <ul className="list-disc">
                                    {r.materials.map((m, k) => (
                                      <li key={k} className="!ml-2 !text-black">
                                        {m.title}
                                      </li>
                                    ))}
                                  </ul>
                                </Accordion.Body>
                              </Accordion.Item>
                            </Accordion>
                          )}
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </div>
                ))}
              </Accordion>
            ) : (
              <p>No redeem codes available.</p>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              className="!bg-blue-700 !text-white !m-auto !w-1/3"
              onClick={handleActivateOptions}
              disabled={
                activatedLoading ||
                redeemCodeRes?.option_selection_limit !==
                  Object.keys(checkedOptions).length
              }
            >
              {activatedLoading ? (
                <span className="!flex !items-center !justify-around">
                  Please wait{" "}
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                </span>
              ) : (
                "Activate"
              )}
            </Button>
          </Modal.Footer>
        </Modal>
        <Form onSubmit={handleredeemCodeSubmit}>
          <InputGroup
            className={`mb-3 ${btnDeisabled ? "!cursor-not-allowed" : ""}`}
            style={{ width: "100%" }}
          >
            <Form.Control
              type="number"
              min={0}
              aria-label="Example text with button addon"
              aria-describedby="basic-addon1"
              style={{
                borderRadius: "20px 0 0 20px",
                height: "45px",
                background: "#f5f5f5",
              }}
              placeholder={
                isLocked
                  ? `Retry in: ${Math.ceil(
                      (lockEndTime - new Date().getTime() / 1000) / 60
                    )} minutes`
                  : "Enter Your Code"
              }
              onChange={redeemCodeHandler}
              value={redeemCode}
              className="custom-number-input"
            />

            <Button
              type="submit"
              variant="primary"
              id="button-addon1"
              disabled={sendingCodeLoading || btnDeisabled}
              style={{ cursor: btnDeisabled ? "not-allowed" : "pointer" }}
              className="bg-[#304ffe] font-bold text-sm"
            >
              <TextTR text={"lbl_redeem"} />
            </Button>
          </InputGroup>
        </Form>
      </div>
    </>
  );
}

export default RedeemCode;
