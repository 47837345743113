import { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import { Row, Col, Form } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import { useTranslation } from "../translationUtils";
import { useDispatch } from "react-redux";
import { login, setTableName } from "../../store/CheckLogin";
import { useMediaQuery } from "react-responsive";
import CountryDropDown from "./CountryDropDown";

const NewUserModal = (props) => {
  const isMobile = useMediaQuery({ query: "(max-width:650px)" });

  const dispatch = useDispatch();
  const { getTranslatedWord, setLanguage } = useTranslation();

  const [name, setName] = useState("");
  const [isPublicSchool, setIsPublicSchool] = useState();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [countryCode, setCountryCode] = useState("961");

  useEffect(() => {
    if (props.userName?.length > 0) {
      setName(props.userName);
    }
  }, [props.userName]);

  const submitHandler = async (e) => {
    e.preventDefault();
    let formData = new FormData();
    formData.append(
      "token",
      localStorage.getItem("login-token") ??
        sessionStorage.getItem("login-token")
    );
    formData.append("name", name);
    formData.append("is_public_school", isPublicSchool);
    formData.append("phone_number", phoneNumber);
    formData.append("country_code", countryCode);

    await axios
      .post(`${props.url}users/set-user-data`, formData)
      .then((res) => {
        if (res.data.succeeded) {
          dispatch(login(res.data.user));
          dispatch(setTableName(res.data.table_name));
          Swal.fire({
            icon: "success",
            title: getTranslatedWord(res.data.title),
            text: getTranslatedWord(res.data.message),
          });
        } else {
          Swal.fire({
            icon: "error",
            title: getTranslatedWord(res.data.title),
            text: getTranslatedWord(res.data.message),
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const schoolHandler = (e) => {
    if (e.target.id === "Public School") {
      setIsPublicSchool(true);
    } else if (e.target.id === "Private School") {
      setIsPublicSchool(false);
    }
  };

  const countryCodeHandler = (v) => {
    setCountryCode(v);
  };

  return (
    <Col xs={12} md={6} className="!grid !place-items-center">
      <Form onSubmit={submitHandler}>
        <Row>
          <h5 className="!p-0 !font-semibold">Personal Information</h5>
        </Row>
        <Row className="!my-4">
          <Form.Group controlId="nameInput">
            <Form.Label className="!font-semibold">Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Name"
              value={name}
              onChange={(event) => setName(event.target.value)}
              className="!mb-3"
              required
            />
          </Form.Group>
          <div className="!flex !items-start !justify-center">
            <Form.Check
              inline
              label="Public School"
              name="group1"
              type="radio"
              id="Public School"
              required
              // checked={isPublicSchool}
              onChange={schoolHandler}
            />
            <Form.Check
              inline
              label="Private School"
              name="group1"
              type="radio"
              id="Private School"
              required
              // checked={!isPublicSchool}
              onChange={schoolHandler}
            />
          </div>
          {props.isPhone && (
            <div>
              <Form.Group>
                <Form.Label className="bold-text">Phone Number</Form.Label>
                <Row>
                  <Col
                    xs={isMobile ? 4 : 3}
                    style={isMobile ? null : { paddingRight: "0px" }}
                  >
                    <CountryDropDown setValue={countryCodeHandler} />
                  </Col>
                  <Col
                    xs={isMobile ? 8 : 9}
                    style={isMobile ? { paddingLeft: "0px" } : null}
                  >
                    <Form.Control
                      type="text"
                      placeholder="Phone number"
                      value={phoneNumber}
                      onChange={(event) => setPhoneNumber(event.target.value)}
                      required
                    />
                  </Col>
                </Row>
              </Form.Group>
            </div>
          )}
        </Row>
        <Row>
          <Button
            className="!bg-[#0d6efd] !text-white"
            variant="primary"
            type="submit"
          >
            Submit
          </Button>
        </Row>
      </Form>
    </Col>
  );
};

export default NewUserModal;
