import React from "react";
import BTNComponent2 from "../BTNComponent2";
import "./UnlockPowerOfStudySection.css";
import { useMediaQuery } from "react-responsive";

function UnlockPowerOfStudySection(props) {
  const { group148, group147, className } = props;
  /**for responsivity */
  const isMobile = useMediaQuery({ query: "(max-width:650px)" });

  return (
    <div
      className={`unlock-power-of-study-section ${
        className || ""
      } max-lg:hidden`}
    >
      <img
        className="group-148"
        src="/img/group-148-1@2x.png"
        alt="Group 148"
      />
      <p className="unlock-the-power-of-your-study valign-text-middle poppins-semi-bold-blue-30px">
        Making Learning Fun!
      </p>
      {localStorage.getItem("login-token") ||
      localStorage.getItem("login-token") == "null" ? null : isMobile ? null : (
        <BTNComponent2 />
      )}

      <img
        className="group-147"
        src="/img/group-147-2@2x.png"
        alt="Group 147"
      />
    </div>
  );
}

export default UnlockPowerOfStudySection;
