import React from "react";
import { useState, useEffect } from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import Group150 from "../Group150";
import SupportedGradesCard1 from "../SupportedGradesCard1";
import SupportedGradesCardImage from "../SupportedGradesCardImage";
import MaterialsSlider from "../MaterialsSlider";
import "./SupportedGradesSwiperComponent22.css";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";

function SupportedGradesSwiperComponent22(props) {
  const { supGradeLang } = props;

  /*setting the props */
  const [categData, setCategData] = useState();
  useEffect(() => {
    setCategData(props.categData);
  }, []);

  return (
    <div className="supported-grades-swiper-component">
      <div className="supported-grades-swiper">
        <Swiper
          modules={[Navigation, Pagination, Scrollbar, A11y]}
          spaceBetween={60}
          slidesPerView={4}
          navigation
          autoplay={true}
          // centeredSlides={true}
          // loop
          breakpoints={{
            300: {
              slidesPerView: 2,
              spaceBetween: 10,
              slidesPerGroup: 2,
            },
            560: {
              slidesPerView: 2,
            },
            640: {
              slidesPerView: 2,
              spaceBetween: 50,
              slidesPerGroup: 2,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 60,
              slidesPerGroup: 2,
            },
            1024: {
              slidesPerView: 4,
              spaceBetween: 60,
              slidesPerGroup: 4,
            },
            1200: {
              slidesPerView: 4,
              spaceBetween: 60,
              slidesPerGroup: 4,
            },
            1400: {
              slidesPerView: 4,
              spaceBetween: 60,

              slidesPerGroup: 4,
            },
          }}
        >
          {props.SupportedGradesMap &&
            props.SupportedGradesMap?.grades?.map(
              (supportedCardItem, supportedCardIndex) => {
                let supCardImg = "./img/grade-image@2x.png";
                if (supportedCardItem.image) {
                  supCardImg = supportedCardItem.image;
                }
                const gradeTitle = supportedCardItem.title;
                return (
                  <SwiperSlide
                    style={{
                      width: "fit-content",
                      marginRight: "0px !important",
                    }}
                    key={supportedCardIndex}
                  >
                    <Link
                      to={{
                        pathname: "/watch-course",
                        search: `id=${supportedCardItem.id}`,
                        state: { categData, gradeTitle },
                      }}
                    >
                      <SupportedGradesCard1
                        suppCardBgImg={supCardImg}
                        grade9_2={supportedCardItem.title}
                        language={supGradeLang}
                        supportedGradesCardImageProps={supCardImg}
                        supMaterials={supportedCardItem.materials}
                        supMaterialGradeIndex={supportedCardIndex}
                      />
                    </Link>
                  </SwiperSlide>
                );
              }
            )}
        </Swiper>
      </div>
    </div>
  );
}

export default SupportedGradesSwiperComponent22;
