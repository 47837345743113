import React, { useState, useEffect } from "react";
import { Col } from "react-bootstrap";
import "./verification.css";
import "./loginmodal.css";
import "./loginmodal2.css";
import "./loginmodal3.css";
import axios from "axios";
import Swal from "sweetalert2";
import OtpInput from "react-otp-input";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { login, setTableName } from "../../store/CheckLogin";
import { useTranslation } from "../translationUtils";
import NewUserModal from "./NewUserModal";

function VerificationModal(props) {
  const { getTranslatedWord, setLanguage } = useTranslation();

  const history = useHistory();
  // const [show, setShow] = useState(false);
  const [verifiactionCode, setVerificationCode] = useState();
  //new
  const [newUsershow, setNewUserShow] = useState(false);

  /**api post request to verify the accont */
  const [verificationData, setVerificationData] = useState();
  const [verState, setVerState] = useState(false);
  const [hasReloaded, setHasReloaded] = useState(false);
  //new
  const [verifyDisabled, setVerifyDisabled] = useState(false);

  async function handleVerifySubmit(event) {
    setVerifyDisabled(true);
    event?.preventDefault();
    let formData = new FormData();
    formData.append("country_code", props.countryCode);
    formData.append("phone_number", props.phoneNumber);
    // formData.append("password", props.password);
    formData.append("device_data", JSON.stringify(props.device_data));
    formData.append("code", verifiactionCode);

    // const response =
    await axios
      .post(`${props.url}users/activate-user`, formData)
      .then((res) => {
        //alert("Success");
        if (res.data.succeeded) {
          // new
          if (res.data.user.is_activated === 1) {
            Swal.fire({
              icon: "success",
              title: "Verified",
            });
            history.push("./");
            dispatch(login(res.data.user));
            dispatch(setTableName(res.data.table_name));
            setVerificationData(res.data);
            sessionStorage.setItem("loginAccess", true);
            localStorage.setItem("login-token", res.data.token);
            localStorage.setItem("loginData", JSON.stringify(res.data));
          } else if (res.data.user.is_activated === 0) {
            localStorage.setItem("login-token", res.data.token);
            setNewUserShow(true);
            dispatch(setTableName(res.data.table_name));
          }
          disableResend();
        } else {
          Swal.fire({
            icon: "error",
            title: getTranslatedWord(res.data.title),
            text: getTranslatedWord(res.data.message),
          });
        }
        setVerifyDisabled(false);
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: getTranslatedWord(err.message),
        });
        setVerifyDisabled(false);
      });
  }

  /**to send the code for unverified users*/
  async function sendVerification() {
    let formData = new FormData();
    formData.append("country_code", props.countryCode);
    formData.append("phone_number", props.phoneNumber);

    const response = await axios
      .post(`${props.url}users/send-activation-code`, formData)
      .then((res) => {
        //alert("Success");
        setVerState(true);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    sendVerification();
  }, []);

  /*mapping the response of the login api */
  const dispatch = useDispatch();
  useEffect(() => {
    if (
      verificationData &&
      verificationData.succeeded
      //   localStorage.getItem("login-token") !== null) ||
      // localStorage.getItem("login-token") > 0
    ) {
      dispatch(login(verificationData.user));

      if (!hasReloaded) {
        setHasReloaded(true);
        window.location.reload();
      }
    }
  }, [verificationData]);

  /**for resending the v-code */
  const [resendDisabled, setResendDisabled] = useState(false);

  const [countdown, setCountdown] = useState(60);

  function disableResend() {
    setResendDisabled(true);
    const intervalId = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1);
    }, 1000);

    // Clear the interval when the countdown reaches zero
    setTimeout(() => {
      clearInterval(intervalId);
      setResendDisabled(false);
      setCountdown(60); // Reset countdown after completion
    }, 60000); // 60 seconds for 1 minute
  }

  /**resend activation code */
  async function handleResendCode() {
    let formData = new FormData();
    formData.append("country_code", props.countryCode);
    formData.append("phone_number", props.phoneNumber);

    await axios
      .post(`${props.url}users/send-activation-code`, formData)
      .then((res) => {
        //alert("Success");
        if (res.data.succeeded) {
          disableResend();
        } else {
          disableResend();
          Swal.fire({
            icon: "error",
            title: getTranslatedWord(res.data.title),
            text: getTranslatedWord(res.data.message),
          });
        }
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: getTranslatedWord(err.message),
        });
      });
  }

  // Disable the button for 5 minutes after a successful function call
  const handleResendClick = () => {
    // Disable the button
    handleResendCode();
  };

  const [otp, setOtp] = useState("");

  useEffect(() => {
    if (otp.length > 5) {
      setVerState(true);
      setVerificationCode(otp);
    } else {
      setVerState(false);
    }
  }, [otp]);

  return newUsershow ? (
    <NewUserModal url={props.url} />
  ) : (
    <Col xs={12} md={6}>
      <div className="flex-col-login" style={{ marginTop: "15%" }}>
        <div className="verify-your-account valign-text-middle poppins-semi-bold-blue-24px">
          Verify your Account
        </div>
        <p className="please-enter-the-verification-code poppins-normal-black-13px">
          Please enter the verification code
        </p>
        <p className="code-is-sent-to-961-70-123-456">
          Code is sent to {props?.phoneNumber}
        </p>
        <div className="code-container">
          <OtpInput
            value={otp}
            onChange={setOtp}
            numInputs={6}
            renderSeparator={<span>&nbsp;&nbsp;</span>}
            renderInput={(props) => <input {...props} />}
            style={{ width: "2em", textAlign: "center", height: "2rem" }}
          />
          <div className="d-flex ver-flex-row mt-3"></div>
          <div className="text-center mt-5">
            <span className="d-block mobile-text">
              Didn't receive the code?
            </span>
            <span
              className={`font-weight-bold text-danger cursor ${
                resendDisabled
                  ? "resend-disabled !pointer-events-none"
                  : "hover:!opacity-80 active:!opacity-80"
              }`}
              onClick={handleResendClick}
            >
              {resendDisabled ? `Resend in  ${countdown}s` : `Resend`}
            </span>
          </div>
          {/* <div className='d-flex justify-content-center align-items-center ver-container'>
            <div className='ver-card py-5 px-3'>
              <div className='d-flex ver-flex-row mt-5'></div>
              <div className='text-center mt-5'>
               
              </div>
            </div>
          </div> */}
        </div>
        <div
          className={`${
            verifyDisabled ? "!bg-slate-400 !pointer-events-none" : ""
          } btn-component hover:!bg-green-900 active:!scale-110`}
        >
          {verState ? (
            <div
              className={`${
                verifyDisabled ? "!pointer-events-none" : ""
              } !w-full read-more valign-text-middle poppins-medium-white-16px`}
              onClick={handleVerifySubmit}
              style={{ cursor: "pointer" }}
            >
              {verifyDisabled ? "Verifying" : "Verify"}
            </div>
          ) : (
            <div
              className="read-more valign-text-middle poppins-medium-white-16px"
              //onClick={handleVerifySubmit}
              style={{ color: "white" }}
            >
              Loading...
              {/* <span className='spinner-grow spinner-grow-sm'></span> */}
            </div>
          )}
        </div>
      </div>
    </Col>
  );
}

export default VerificationModal;
