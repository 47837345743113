import React from "react";
import UnitsDropDown from "../UnitsDropDown";
import UnlockPowerOfStudySection from "../UnlockPowerOfStudySection";
import GradeImage3 from "../GradeImage3";
import DetailsGroup3 from "../DetailsGroup3";
import BTNComponent3 from "../BTNComponent3";
import "./InsideGrade.css";
import Navbar from "../Navbar/Navbar";
import Button from "react-bootstrap/esm/Button";

function InsideGrade(props) {
  const {
    insideGradeTopBanner,
    grade9English,
    chemistry,
    math,
    physics,
    grade8EnglishTitle,
    unitsDropDownProps,
    detailsGroup3Props,
    bTNComponent3Props,
  } = props;

  return (
    <>
      <Navbar />
      <div className='container-center-horizontal'>
        <div className='inside-grade screen'>
          <div className='overlap-group1-2'>
            <div className='units-and-footer-1'>
              <UnitsDropDown
                videosDropdownForMyLearningProps={
                  unitsDropDownProps.videosDropdownForMyLearningProps
                }
              />
              <div className='footer-and-unlock-the-power-section-1'>
                <UnlockPowerOfStudySection />
              </div>
            </div>
            <div
              className='inside-grade-top-banner-1'
              style={{ backgroundImage: `url(${insideGradeTopBanner})` }}
            >
              <div className='grade-9-english-5 valign-text-middle poppins-semi-bold-white-45px'>
                {grade9English}
              </div>
              <div className='material-btn-container-2'>
                <div className='chemistry-2 active-lesson-tag roboto-medium-white-21px'>
                  <Button
                    id='chem-tag'
                    href='#'
                  >
                    {chemistry}
                  </Button>
                </div>

                <div className='math-2 roboto-medium-blue-21px'>
                  <Button
                    className='bg-white'
                    style={{ color: "#0d6efd" }}
                    id='math-tag'
                    href='#'
                  >
                    {math}
                  </Button>
                </div>
                <div className='math-2 roboto-medium-blue-21px'>
                  <Button
                    className='bg-white'
                    style={{ color: "#0d6efd" }}
                    id='phy-tag'
                    href='#'
                  >
                    {physics}
                  </Button>
                </div>
              </div>
              <div className='material-btn-container-3'>
                <div className='physics-2 roboto-medium-blue-21px'>
                  <Button
                    className='bg-white'
                    style={{ color: "#0d6efd" }}
                    id='bio-tag'
                    href='#'
                  >
                    Biology
                  </Button>
                </div>
                <div className='physics-2 roboto-medium-blue-21px'>
                  <Button
                    className='bg-white'
                    style={{ color: "#0d6efd" }}
                    id='eng-tag'
                    href='#'
                  >
                    English
                  </Button>
                </div>
              </div>
            </div>
            <div className='grade-details-1'>
              <GradeImage3 />
              <div className='grade-8-english-title-1 valign-text-middle poppins-semi-bold-blue-24px'>
                {grade8EnglishTitle}
              </div>
              <DetailsGroup3 group1={detailsGroup3Props.group1} />
              <BTNComponent3>{bTNComponent3Props.children}</BTNComponent3>
            </div>
          </div>
          =
        </div>
      </div>
    </>
  );
}

export default InsideGrade;
