import React from "react";
import "./edit-profile.css";
import { useState, useEffect } from "react";
import { TextField } from "@mui/material";
import { Button, Row, Col, Form, Dropdown, InputGroup } from "react-bootstrap";
import axios from "axios";
import InputAdornment from "@mui/material/InputAdornment";
import PhoneIcon from "@mui/icons-material/Phone";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import ApartmentOutlinedIcon from "@mui/icons-material/ApartmentOutlined";
import CakeOutlinedIcon from "@mui/icons-material/CakeOutlined";
import Swal from "sweetalert2";
import { useTranslation } from "../translationUtils";
//new
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../store/CheckLogin";

function EditProfile(props) {
  //new
  const dispatch = useDispatch();
  /**translation alerts */
  const { getTranslatedWord } = useTranslation();

  // /*getting the user-info */
  // const initialUserName = "Full Name";
  // const initialPhoneNumber = "Phone Number";
  // const initialUserEmail = "Email";
  // const initialUserBirthday = "birthday";
  // const initialCity = "City";
  // const initialRegion = "Region";
  // const initialSchool = "School";

  /*sending the post request to update the profile */
  const loginToken =
    localStorage.getItem("login-token") ??
    sessionStorage.getItem("login-token");
  /* State values */
  const [userName, setUserName] = useState(""); //useState(initialUserName);
  const [phoneNumber, setPhoneNumber] = useState(""); //useState(initialPhoneNumber);
  const [userEmail, setUserEmail] = useState(""); //useState(initialUserEmail);
  const [userBirthday, setUserBirthday] = useState(""); //useState(initialUserBirthday);
  const [userSchool, setUserSchool] = useState(""); //useState(initialSchool);

  //new
  const [phoneDisabled, setPhoneDisabled] = useState(false);
  const [countries, setCountries] = useState([]);
  const [countrySelected, setCountrySelected] = useState("country");
  const [countrySelectedId, setCountrySelectedId] = useState(null);
  const [countryCodes, setCountryCodes] = useState([]);
  const [countryCode, setCountryCode] = useState("country code");
  const [cities, setCities] = useState([]);
  const [citySelected, setCitySelected] = useState("City");
  const [citySelectedId, setCitySelectedId] = useState(null);

  const dCities = useSelector((state) => state.checkLogin.cities);
  const dCountries = useSelector((state) => state.checkLogin.countries);

  useEffect(() => {
    if (dCities) {
      setCities(dCities);
    }
  }, [dCities]);

  useEffect(() => {
    if (dCountries) {
      setCountries(dCountries);
    }
  }, [dCountries]);

  const countryChangeHandler = (countryName, countryId) => {
    setCountrySelected(countryName);
    setCountrySelectedId(countryId);
    if (countrySelectedId !== countryId) {
      setCitySelected("City");
    }
    const filteredCities = dCities.filter(
      (city) => city.country_id === countryId
    );
    setCities(filteredCities);
  };

  useEffect(() => {
    fetch("https://restcountries.com/v2/all")
      .then((response) => response.json())
      .then((data) =>
        setCountryCodes(
          data
            .filter((country) => country.alpha2Code !== "IL") // remove Israel
            .map((country) => ({
              code: country.alpha2Code,
              name: country.name,
              flag: country.flag,
              callingCode: `${country.callingCodes[0]}`,
            }))
        )
      );
  }, []);
  //phone code
  const [searchCc, setSerachCc] = useState("");
  const filteredCountryCode = countryCodes?.filter((code) =>
    code.name?.toLowerCase().includes(searchCc.toLowerCase())
  );
  //cities
  // const [searchCities, setSerachCities] = useState("");
  // var filteredCities = cities?.filter((city) =>
  //   city.cityName?.toLowerCase().includes(searchCities.toLowerCase())
  // );
  // useEffect(() => {
  //   if (cities) {
  //     filteredCities = cities?.filter((city) =>
  //       city.cityName?.toLowerCase().includes(searchCities.toLowerCase())
  //     );
  //   }
  // }, [cities]);
  //

  useEffect(() => {
    if (props?.accountData) {
      if (props.accountData?.name) {
        setUserName(props.accountData?.name);
      }
      if (props.accountData?.phone_number) {
        setPhoneNumber(props.accountData?.phone_number);
      }
      if (props.accountData?.country_code) {
        setCountryCode(props.accountData?.country_code);
      }
      if (props.accountData?.email) {
        setUserEmail(props.accountData?.email);
      }
      if (props.accountData?.birthday) {
        setUserBirthday(props.accountData?.birthday);
      }
      if (props.accountData?.city) {
        const c = dCities?.find((city) => city.id === props.accountData?.city);
        setCitySelected(c?.city_name);
        setCitySelectedId(c?.id);
      }
      if (props.accountData?.country) {
        const c = dCountries?.find(
          (country) => country.id === props.accountData?.country
        );
        setCountrySelected(c?.country_name);
        setCountrySelectedId(c?.id);
        const filteredCities = dCities?.filter(
          (city) => city.country_id === c.id
        );
        setCities(filteredCities);
      }
      if (props.accountData?.school) {
        setUserSchool(props.accountData?.school);
      }
      if (props.accountData?.is_normal === 1) {
        setPhoneDisabled(true);
      }
    }
  }, [props.accountData]);

  const [editProfileRes, setEditProfileRes] = useState();
  const [editProfileStatus, setEditProfileStatus] = useState(false);

  async function handleProfileSubmit(event) {
    event.preventDefault();

    let formData = new FormData();
    formData.append("token", loginToken);
    formData.append("name", userName);
    formData.append("email", userEmail);
    formData.append("birthday", userBirthday);
    formData.append("city", citySelectedId);
    formData.append("school", userSchool);
    formData.append("phone_number", phoneNumber);
    //new
    formData.append("country_code", countryCode);
    formData.append("country", countrySelectedId);

    //const response =
    await axios
      .post(`${props.url}users/update-user-profile`, formData)
      .then((res) => {
        if (res.data.succeeded) {
          setEditProfileRes(res);
          setEditProfileStatus(true);
          autoLogin();
          Swal.fire({
            icon: "success",
            title: getTranslatedWord(res.data.title),
            text: getTranslatedWord(res.data.message),
          });
        } else {
          Swal.fire({
            icon: "warning",
            title: getTranslatedWord(res.data.title),
            text: getTranslatedWord(res.data.message),
          });
        }
      })
      .catch((err) => {
        alert(err.message);
      });
  }

  /* take the data user from local storage and update it  */

  useEffect(() => {
    if (editProfileStatus) {
      autoLogin();
    }
  }, [editProfileStatus]);

  /**auto login starts here*/
  function autoLogin() {
    let formData = new FormData();
    formData.append(
      "token",
      localStorage.getItem("login-token") ??
        sessionStorage.getItem("login-token")
    );
    formData.append("is_web", true);
    const url1 = `${props.url}users/auto-login`;
    fetch(url1, {
      method: "POST",
      body: formData,
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(res.statusText);
        }
        return res.json();
      })
      .then((res) => {
        dispatch(login(res.user));
        localStorage.setItem("loginData", JSON.stringify(res));
        setEditProfileStatus(false);
      })
      .catch((err) => console.log(err));
  }

  return (
    <div className="editProfile">
      <Form onSubmit={handleProfileSubmit}>
        <div className="edit-profile-form-container">
          <TextField
            id="outlined-basic"
            value={userName}
            label={"UserName"}
            placeholder="UserName"
            variant="outlined"
            onChange={(event) => setUserName(event.target.value)}
            style={{ width: "100%" }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountCircleOutlinedIcon />
                </InputAdornment>
              ),
            }}
          />
          <Row className="!w-full">
            <Col className="!p-0">
              <TextField
                id="outlined-basic"
                className="!w-full"
                value={phoneNumber}
                label={"Phone Number"}
                placeholder="Phone Number"
                disabled={phoneDisabled}
                variant="outlined"
                onChange={(event) => setPhoneNumber(event.target.value)}
                style={{ width: "100%" }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PhoneIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Col>
            <Col className="!px-1">
              <InputGroup>
                <Dropdown>
                  <Dropdown.Toggle
                    className="!h-[55px] !border-[#babec0] hover:!bg-transparent hover:!border-black
                    !font-semibold !text-[#000000DB] !w-full active:!border-black active:!text-[#000000DB]
                  focus:!border-black focus:!text-[#000000DB] focus:!outline-1 focus:!border-2 focus:!bg-transparent active:!bg-transparent"
                    id="dropdown-custom-components"
                  >
                    {countryCode}
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="!h-[40vh] !overflow-x-hidden !overflow-y-scroll">
                    <div className="!bg-white !sticky !top-0 !z-30">
                      <InputGroup>
                        <Form.Control
                          autoFocus
                          className="mx-3 w-auto"
                          placeholder="Filter by country name"
                          onChange={(e) => setSerachCc(e.target.value)}
                          value={searchCc}
                        />
                      </InputGroup>
                    </div>
                    {filteredCountryCode &&
                      filteredCountryCode.map((code, index) => (
                        <Dropdown.Item
                          key={index}
                          eventKey={index}
                          onClick={() => {
                            setCountryCode(
                              code.callingCode.includes("+")
                                ? code.callingCode
                                : `+${code.callingCode}`
                            );
                          }}
                          value={code.callingCode}
                          className="!flex"
                        >
                          <img
                            src={code.flag}
                            alt={code.code}
                            className="!w-7 !h-7 !object-contain !mr-2"
                          />
                          {code.callingCode.includes("+")
                            ? code.callingCode
                            : `+${code.callingCode}`}
                        </Dropdown.Item>
                      ))}
                    {!filteredCountryCode && (
                      <p className="!text-center !mt-[5%]">Loading...</p>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              </InputGroup>
            </Col>
          </Row>
          <TextField
            id="outlined-basic"
            label={"Email"}
            placeholder="Email"
            value={userEmail}
            type="email"
            variant="outlined"
            onChange={(event) => setUserEmail(event.target.value)}
            style={{ width: "100%" }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <EmailOutlinedIcon />
                </InputAdornment>
              ),
            }}
            required={false}
          />
          <TextField
            id="outlined-basic"
            label={"Birthday"}
            value={userBirthday}
            type="date"
            variant="outlined"
            onChange={(event) => setUserBirthday(event.target.value)}
            style={{ width: "100%" }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <CakeOutlinedIcon />
                </InputAdornment>
              ),
            }}
          />
          <div className="input-row">
            <div className="input-col countryDrop" sx="12">
              <Dropdown className="!w-full">
                <Dropdown.Toggle
                  className="!h-[55px] !border-[#babec0] hover:!bg-transparent hover:!border-black
              !font-semibold !text-[#000000DB] !w-full active:!border-black active:!text-[#000000DB]
              focus:!border-black focus:!text-[#000000DB] focus:!outline-1 focus:!border-2 focus:!bg-transparent active:!bg-transparent"
                  id="formGridShippengAddress"
                >
                  {countrySelected}
                </Dropdown.Toggle>
                <Dropdown.Menu
                  className="!pt-0 !h-[40vh] !w-full !overflow-x-hidden
                !overflow-y-scroll"
                >
                  {countries &&
                    countries.map((code) => (
                      <Dropdown.Item
                        key={code.id}
                        eventKey={code.id}
                        onClick={() =>
                          countryChangeHandler(code.country_name, code.id)
                        }
                        value={code.id}
                        className="!flex"
                      >
                        {code.country_name}
                      </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="input-col">
              {" "}
              <InputGroup>
                <Dropdown>
                  <Dropdown.Toggle
                    className="!h-[55px] !border-[#babec0] hover:!bg-transparent hover:!border-black
                    !font-semibold !text-[#000000DB] !w-full active:!border-black active:!text-[#000000DB]
                  focus:!border-black focus:!text-[#000000DB] focus:!outline-1 focus:!border-2 focus:!bg-transparent active:!bg-transparent"
                    id="dropdown-custom-components"
                  >
                    {citySelected}
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="!h-[40vh] !overflow-x-hidden !overflow-y-scroll">
                    {/* <div className="!bg-white !sticky !top-0 !z-30">
                      <InputGroup>
                        <Form.Control
                          autoFocus
                          className="mx-3 w-auto"
                          placeholder="Filter by country name"
                          onChange={(e) => setSerachCities(e.target.value)}
                          value={searchCities}
                        />
                      </InputGroup>
                    </div> */}
                    {cities &&
                      cities.map((city, index) => (
                        <Dropdown.Item
                          key={index}
                          eventKey={index}
                          onClick={() => {
                            setCitySelected(city.city_name);
                            setCitySelectedId(city.id);
                          }}
                          value={city.id}
                          className="!flex"
                        >
                          {city.city_name}
                        </Dropdown.Item>
                      ))}
                    {/* {!filteredCities && (
                      <p className="!text-center !mt-[5%]">Loading...</p>
                    )} */}
                  </Dropdown.Menu>
                </Dropdown>
              </InputGroup>
            </div>
          </div>

          <TextField
            id="outlined-basic"
            label={"School"}
            placeholder="School"
            value={userSchool}
            variant="outlined"
            onChange={(event) => setUserSchool(event.target.value)}
            style={{ width: "100%" }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <ApartmentOutlinedIcon />
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className="edit-profile--form-btn">
          <Button
            className="!bg-[#0d6efd] !text-white hover:!opacity-80"
            type="submit"
          >
            Save
          </Button>
        </div>
      </Form>
    </div>
  );
}

export default EditProfile;
