import React from "react";
import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import Navbar from "../Navbar/Navbar";
import UnlockPowerOfStudySection from "../UnlockPowerOfStudySection";
import axios from "axios";
import { useLocation, useParams } from "react-router-dom";
import "./Quizes.css";
import { Button } from "react-bootstrap";
import QuizBGImage from "../BGImage/QuizBgImage";
import TeacherTitleLines from "../TitleLines/TeacherTitleLines";
import Swal from "sweetalert2";
import TextTR from "../HomePage/TextTR";
import { useMediaQuery } from "react-responsive";
import { URL } from "../../static/data/config";

function Quizes(props) {
  const { unlockPowerOfStudySectionProps } = props;

  const [getQuizData, setQuizData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  /**getting the id of the quiz */
  const { id: quiz_id } = useParams();

  function getData() {
    let formData = new FormData();
    formData.append("token", localStorage.getItem("login-token"));
    formData.append("quiz_id", quiz_id);
    const url1 = `${URL}material/quiz`;
    fetch(url1, {
      method: "POST",
      body: formData,
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(res.statusText);
        }
        return res.json();
      })
      .then((res) => {
        setQuizData(res);
        setIsLoading(false);
      })
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    getData();
  }, []);

  /**end fetch here */

  /**handling the input */
  let userAnswers = null;
  const [checkedValues, setCheckedValues] = useState([]);
  const [radioValues, setRadioValues] = useState([]);
  const [checkedSubmittedValues, setCheckedSubmittedValues] = useState([]);
  const [radioSubmittedValues, setRadioSubmittedValues] = useState([]);
  const [quizSubmittedValues, setQuizSubmittedValues] = useState([]);

  const handlecheck = (e) => {
    e.currentTarget.querySelector("input[type='checkbox']").checked =
      !e.currentTarget.querySelector("input[type='checkbox']").checked;
    /**getting the value and store it in the useState to use it later  */
    const values = [...checkedValues];
    const submittedValues = [...checkedSubmittedValues];
    const value = e.currentTarget.querySelector("input[type='checkbox']").value;
    const id = value.split(":")[0];
    const index = values.indexOf(id);
    if (index > -1) {
      values.splice(index, 1);
      submittedValues.splice(index, 1);
    } else {
      values.push(id);
      submittedValues.push(value);
    }
    setCheckedValues(values);
    setCheckedSubmittedValues(submittedValues);
  };

  const handleRadio = (e) => {
    e.currentTarget.querySelector("input[type='radio']").checked =
      !e.currentTarget.querySelector("input[type='radio']").checked;

    const values = [...radioValues];
    const submittedValues = [...radioSubmittedValues];
    const value = e.currentTarget.querySelector("input[type='radio']").value;
    const id = value.split(":")[0];
    const index = values.indexOf(id);
    if (index > -1) {
      values.splice(index, 1);
      submittedValues.splice(index, 1);
    } else {
      values.push(id);
      submittedValues.push(value);
    }
    setRadioValues(values);
    setRadioSubmittedValues(submittedValues);
  };

  /**handling the submit btn */
  const [quizSubmitted, setQuizSubmitted] = useState(false);
  const [quizAnswerData, setQuizAnswerData] = useState([]);

  async function handleSubmitBtn(e) {
    e.preventDefault();

    if (
      checkedSubmittedValues?.length === 0 &&
      radioSubmittedValues?.length === 0
    ) {
      Swal.fire({
        icon: "error",
        title: "Submition",
        text: "Please Answer the Questions First!!",
      });
    } else {
      {
        const values = checkedValues.slice();
        const submittedValues = checkedSubmittedValues.slice();

        if (radioValues?.length > 0) {
          radioValues.forEach((element) => {
            values.push(element);
          });
        }
        if (radioSubmittedValues?.length > 0) {
          radioSubmittedValues.forEach((element) => {
            submittedValues?.push(element);
          });
        }
        let formData = new FormData();
        formData.append("token", localStorage.getItem("login-token"));
        formData.append("quiz_id", quiz_id);
        formData.append("options", JSON.stringify(values));

        await axios
          .post(`${URL}material/submit-options`, formData)
          .then((res) => {
            Swal.fire({
              icon: "success",
              title: "Submition",
              text: "Your Quiz Have Been Submitted Successfully",
            });
            // localStorage.setItem(
            //   "quiz-submitted-answers",
            //   JSON.stringify(submittedValues)
            // );
            setQuizSubmittedValues(submittedValues);

            // localStorage.setItem("quiz-submit-action", true);
            setQuizAnswerData(res.data);
            setQuizSubmitted(true);
          })
          .catch((err) => {
            alert(err.message);
          });
      }
    }
  }

  // useEffect(() => {
  //   if (quizSubmitted) {
  //     window.scrollTo(0, 0);
  //   }
  // }, [quizSubmitted]);
  useEffect(() => {
    if (quizAnswerData && quizAnswerData.succeeded) {
      setQuizSubmitted(true);
    }
  }, [quizAnswerData]);

  /**handle retry btn */
  function handleRetryBtn() {
    setQuizSubmitted(false);
    let radioInputs = document.querySelectorAll("input[type='radio']");
    let checkboxInputs = document.querySelectorAll("input[type='checkbox']");
    radioInputs.forEach((input) => (input.checked = false));
    checkboxInputs.forEach((input) => (input.checked = false));
    setQuizSubmittedValues([]);
    setCheckedSubmittedValues([]);
    setRadioSubmittedValues([]);
    setRadioValues([]);
    setCheckedValues([]);

    window.scrollTo(0, 0);
  }

  if (quizSubmittedValues && quizSubmittedValues.length > 0) {
    userAnswers =
      quizSubmittedValues &&
      quizSubmittedValues
        .filter((value) => value?.endsWith(":0")) // filter values that end with ":0"
        .map((value) => value?.split(":")[0]); // extract the ID part before the colon
  }

  let getcorrectAnswers = 0;

  const count = quizSubmittedValues?.reduce((acc, val) => {
    const [, value] = val?.split(":");
    if (parseInt(value) === 1) {
      return acc + 1;
    }
    return acc;
  }, 0);

  const [user_correct_answers, setUserCorrectAnswers] = useState();
  const [correctAnswers, setCorrectAnswers] = useState();

  useEffect(() => {
    setCorrectAnswers(getcorrectAnswers);
    setUserCorrectAnswers(count);
  }, [quizSubmittedValues]);

  const isMobile = useMediaQuery({ query: "(max-width:650px)" });

  let runningIndex = 0;
  const renderQuestionIndex = (questionItem) => {
    if (questionItem.is_given === 0) {
      runningIndex++;
    } else {
      runningIndex = 0;
    }
    return runningIndex;
  };

  return (
    <div className="my-learning-container">
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>
      <Navbar />
      <div className="container-center-horizontal">
        <div className="my-learning screen">
          {isLoading ? (
            <div
              style={{
                width: "80%",
                margin: "auto",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src="./img/all-grade-loading.gif"
                alt=""
                style={{ width: "80%", height: "80%" }}
              />
            </div>
          ) : (
            <div className={`overlap-group1-2 ${isMobile && "py-5"}`}>
              <QuizBGImage />
              <div
                className="all-grade-title-section"
                style={
                  isMobile
                    ? { margin: "20% auto 0 auto", padding: "5px" }
                    : { margin: "7% auto 0 auto", padding: "5px" }
                }
              >
                <div className="all-grade-title-content">
                  <div
                    className="all-grades-1 valign-text-middle poppins-semi-bold-black-25px"
                    style={{ width: "fit-content" }}
                  >
                    {getQuizData && getQuizData.quiz?.title}
                  </div>
                </div>
                <TeacherTitleLines />
              </div>
              <div className="quiz-form-container">
                <div className="quiz-container">
                  <div className="quiz-questions-container">
                    <div
                      className="quiz-result-container"
                      style={
                        quizSubmitted
                          ? { display: "flex" }
                          : { display: "none" }
                      }
                    >
                      {(user_correct_answers * 100) / correctAnswers > 50 ? (
                        <img src="img/quiz-imgs/emoji_star-struck.png" alt="" />
                      ) : (
                        <img
                          src="img/quiz-imgs/Sad-Face-Emoji.png"
                          alt=""
                          style={{ width: "10%", height: "100%" }}
                        />
                      )}

                      <div className="quiz-progress-bar-container">
                        <span
                          className="progress-title"
                          style={{ display: "flex" }}
                        >
                          <TextTR
                            text={"lbl_your_grade"}
                            style={{ width: "fit-content" }}
                          />
                        </span>
                        <span className="quiz-mark">
                          {user_correct_answers}/{correctAnswers}
                        </span>
                        <div className="quiz-progress-bar">
                          <div
                            className="quiz-progress-bar-active"
                            style={{
                              width: `${
                                (user_correct_answers * 100) / correctAnswers
                              }%`,
                            }}
                          ></div>
                        </div>
                        <span className="quiz-mark-details">
                          {user_correct_answers} of {correctAnswers}
                          <span className="quiz-mark-details-status">
                            correct
                          </span>{" "}
                        </span>
                      </div>
                    </div>
                    {getQuizData &&
                      getQuizData.quiz?.questions?.map(
                        (questionItem, questionIndex) => {
                          const indexToShow = renderQuestionIndex(questionItem);
                          if (questionItem.is_given === 0) {
                            getcorrectAnswers += parseInt(
                              questionItem.correct_answers_count
                            );
                          }

                          if (
                            questionItem.is_multiple == 0 ||
                            questionItem.is_multiple == "0"
                          ) {
                            return (
                              <div className="quiz-section-container" key={questionIndex}>
                                <div className="quiz-section-header">
                                  <div className="quiz-section-number">
                                    {indexToShow !== 0 ? indexToShow : ""}
                                  </div>
                                  <div
                                    className="quiz-section-title"
                                    dangerouslySetInnerHTML={{
                                      __html: questionItem.question,
                                    }}
                                  >
                                    {/* {questionItem.question}, */}
                                    {/* <span className="!text-slate-500">
                                        <TextTR text={"lbl_one_option"} />
                                      </span> */}
                                  </div>
                                </div>
                                <div className="quiz-section-content">
                                  {quizSubmitted
                                    ? questionItem?.options?.map(
                                        (optionItem) => {
                                          const isCorrect =
                                            optionItem.is_correct === 1;

                                          return (
                                            <>
                                              <div
                                                key={optionItem.id}
                                                className="answer"
                                              >
                                                <input
                                                  disabled={true}
                                                  checked={true}
                                                  type="radio"
                                                  value={
                                                    isCorrect
                                                      ? "success"
                                                      : "failed"
                                                  }
                                                />{" "}
                                                <span className="answer-radio"></span>
                                                <div
                                                  className="answer-text"
                                                  dangerouslySetInnerHTML={{
                                                    __html: optionItem.option,
                                                  }}
                                                >
                                                  {/* {optionItem.option} */}
                                                </div>
                                              </div>
                                            </>
                                          );
                                        }
                                      )
                                    : questionItem?.options?.map(
                                        (optionItem) => {
                                          return (
                                            <>
                                              <div
                                                key={optionItem.id}
                                                className="answer"
                                                onClick={handleRadio}
                                              >
                                                <input
                                                  type="radio"
                                                  name={questionItem?.id}
                                                  value={`${optionItem.id}:${optionItem.is_correct}`}
                                                />{" "}
                                                <span className="answer-radio"></span>
                                                <div
                                                  className="answer-text"
                                                  dangerouslySetInnerHTML={{
                                                    __html: optionItem.option,
                                                  }}
                                                >
                                                  {/* {optionItem.option} */}
                                                </div>
                                              </div>
                                            </>
                                          );
                                        }
                                      )}
                                </div>
                              </div>
                            );
                          } else {
                            const indexToShow =
                              renderQuestionIndex(questionItem);
                            return (
                              <>
                                <div className="quiz-section-container">
                                  <div className="quiz-section-header">
                                    <div className="quiz-section-number">
                                      {indexToShow !== 0 ? indexToShow : ""}
                                    </div>
                                    <div
                                      className="quiz-section-title"
                                      dangerouslySetInnerHTML={{
                                        __html: questionItem.question,
                                      }}
                                    >
                                      {/* Question {questionIndex + 1}, */}
                                      {/* {questionItem.question}, */}
                                      {/* <span className="!text-slate-500">
                                      <TextTR
                                        text={"msg_question_1_multiple"}
                                      />
                                      </span> */}
                                    </div>
                                  </div>
                                  <div className="quiz-section-content">
                                    {quizSubmitted
                                      ? questionItem.options?.map(
                                          (optionItem) => {
                                            const isSelected =
                                              userAnswers.includes(
                                                optionItem.id
                                              );
                                            const isCorrect =
                                              optionItem.is_correct === 1;

                                            const InputChecked =
                                              isSelected || isCorrect;
                                            const AnswerValue = isSelected
                                              ? "failed"
                                              : isCorrect
                                              ? "success"
                                              : "";

                                            return (
                                              <>
                                                <div
                                                  key={optionItem.id}
                                                  className="answer"
                                                  onClick={handlecheck}
                                                >
                                                  <input
                                                    disabled={true}
                                                    checked={InputChecked}
                                                    type="checkbox"
                                                    name="question1"
                                                    value={AnswerValue}
                                                  />{" "}
                                                  <span className="answer-check"></span>
                                                  <div
                                                    className="answer-text"
                                                    dangerouslySetInnerHTML={{
                                                      __html:
                                                        optionItem.question,
                                                    }}
                                                  >
                                                    {/* {optionItem.option} */}
                                                  </div>
                                                </div>
                                              </>
                                            );
                                          }
                                        )
                                      : questionItem.options?.map(
                                          (optionItem) => {
                                            return (
                                              <>
                                                <div
                                                  key={optionItem.id}
                                                  className="answer"
                                                  onClick={handlecheck}
                                                >
                                                  <input
                                                    type="checkbox"
                                                    name="question1"
                                                    value={`${optionItem.id}:${optionItem.is_correct}`}
                                                  />{" "}
                                                  <span className="answer-check"></span>
                                                  <div
                                                    className="answer-text"
                                                    dangerouslySetInnerHTML={{
                                                      __html: optionItem.option,
                                                    }}
                                                  >
                                                    {/* {optionItem.option} */}
                                                  </div>
                                                </div>
                                              </>
                                            );
                                          }
                                        )}
                                  </div>
                                </div>
                              </>
                            );
                          }
                        }
                      )}
                  </div>
                </div>
                <div className="quiz-submit-section">
                  {quizSubmitted ? (
                    <Button
                      className="quiz-submit-btn"
                      style={
                        ({ width: "30%" },
                        { height: "50px" },
                        { borderRadius: "2rem" },
                        { background: "red" })
                      }
                      onClick={handleRetryBtn}
                    >
                      Retry This Quiz
                    </Button>
                  ) : (
                    <button
                      className="quiz-submit-btn hover:!text-white !bg-blue-700 !text-white"
                      style={
                        ({ width: "30%" },
                        { height: "50px" },
                        { borderRadius: "2rem" })
                      }
                      onClick={handleSubmitBtn}
                    >
                      <TextTR text="Submit Your Answers" />
                    </button>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <UnlockPowerOfStudySection
        group148={unlockPowerOfStudySectionProps.group148}
        group147={unlockPowerOfStudySectionProps.group147}
      />
    </div>
  );
}

export default Quizes;
