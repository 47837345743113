import { createSlice } from "@reduxjs/toolkit";

const dataFetchedSlice = createSlice({
  name: "dataFetched",
  initialState: {
    isDataFetched: false,
    homeData: null,
    isMaterialDataFetched: false,
    materialData: null,
    aboutUsLang: localStorage.getItem("lang-id") || 1,
    materialSelectedId: false,
  },
  reducers: {
    setDataFetched(state, action) {
      const v = action.payload;
      state.isDataFetched = true;
    },
    setHomeDataGlobal(state, action) {
      const v = action.payload;
      state.homeData = v;
    },
    setMaterialFetched(state, action) {
      const v = action.payload;
      state.isMaterialDataFetched = true;
    },
    setMaterialDataGlobal(state, action) {
      const d = action.payload;
      state.materialData = d;
    },
    setAboutUsLang(state, action) {
      state.aboutUsLang = action.payload;
    },
  },
});

export const {
  setDataFetched,
  setHomeDataGlobal,
  setMaterialFetched,
  setMaterialDataGlobal,
  setAboutUsLang,
  setMaterialSelectedId,
} = dataFetchedSlice.actions;
export default dataFetchedSlice;
