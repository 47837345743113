import "./Highlights.css";
import { useState } from "react";
import Stories from "stories-react";
import "stories-react/dist/index.css";
import CancelIcon from "@mui/icons-material/Cancel";
import TextTR from "../HomePage/TextTR";
import { useMediaQuery } from "react-responsive";
//
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode } from "swiper";
import "swiper/css";
import "swiper/css/free-mode";

const Highlights = (props) => {
  const [currentStory, setCurrentStory] = useState(null);
  const [watchedStories, setWatchedStories] = useState([]);

  const isMobile = useMediaQuery({ query: "(max-width:650px)" });

  const markStoryAsWatched = (story) => {
    if (!watchedStories.includes(story.id)) {
      setWatchedStories((prevWatchedStories) => [
        ...prevWatchedStories,
        story.id,
      ]);
    }
  };

  const storiesHandler = (highlight) => {
    markStoryAsWatched(highlight);

    const mediaItems = [];

    const childHighlights = props.hightlights?.filter(
      (h) => h.parent === highlight.id
    );

    if (highlight.video) {
      mediaItems.push({
        url: highlight.video,
        type: "video",
        duration: 30000,
      });
    }

    if (childHighlights && childHighlights.length > 0) {
      childHighlights.forEach((child) => {
        if (child.main_image) {
          mediaItems.push({
            url: child.main_image,
            type: "image",
            duration: 10000,
          });
        }

        if (child.video) {
          mediaItems.push({
            url: child.video,
            type: "video",
            duration: 30000,
          });
        }
      });
    }

    setCurrentStory(mediaItems);
    document.body.style.overflowY = "hidden";
  };

  const handleCloseStory = () => {
    setCurrentStory(null);
    document.body.style.overflowY = "auto";
  };

  return (
    <>
      <div className="mt-[0.5rem] sm:mt-10 !w-full md:!w-[75%] !flex-col !items-start !justify-start">
        <div className="!pl-4 md:!pl-0">
          <h1 className="!text-black !font-bold !text-[20px] md:!text-4xl flex items-start justify-start">
            <TextTR text="msg_featured_courses" />
          </h1>
        </div>
        {!isMobile && (
          <div className="!mt-4 !flex !items-start !justify-start">
            {props.hightlights?.map(
              (h, i) =>
                h.parent === null && (
                  <div key={i} className="!flex !flex-col !items-center">
                    <div
                      className="!shadow-xl !cursor-pointer !ml-6 !rounded-full !h-[100px] !w-[100px]"
                      style={{
                        backgroundImage: `url(${
                          h.main_image ||
                          h.thumbnail ||
                          `../schooltube-logo.png`
                        })`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: `${
                          h.main_image?.length > 0 || h.thumbnail?.length > 0
                            ? "cover"
                            : "contain"
                        }`,
                        backgroundPosition: "center",
                        border: watchedStories.includes(h.id)
                          ? "2px solid red"
                          : "2px solid red",
                      }}
                      onClick={() => storiesHandler(h)}
                    ></div>
                    <p className="!ml-6 !my-2 !text-black !font-semibold">
                      {h.title}
                    </p>
                  </div>
                )
            )}
          </div>
        )}
        {isMobile && (
          <div className="!mt-4 !flex !items-start !justify-start">
            <Swiper
              spaceBetween={0}
              slidesPerView={4}
              modules={[FreeMode]}
              freeMode={true}
              className="!w-full !flex !items-center !justify-center"
            >
              {props.hightlights?.map(
                (h, i) =>
                  h.parent === null && (
                    <SwiperSlide
                      key={i}
                      className="!flex !flex-col !items-center"
                    >
                      <div
                        className="!shadow-xl !cursor-pointer !ml-6 !rounded-full !h-[80px] !w-[80px]"
                        style={{
                          backgroundImage: `url(${
                            h.main_image ||
                            h.thumbnail ||
                            `../schooltube-logo.png`
                          })`,
                          backgroundRepeat: "no-repeat",
                          backgroundSize: `${
                            h.main_image?.length > 0 || h.thumbnail?.length > 0
                              ? "cover"
                              : "contain"
                          }`,
                          backgroundPosition: "center",
                          border: watchedStories.includes(h.id)
                            ? "2px solid red"
                            : "2px solid red",
                        }}
                        onClick={() => storiesHandler(h)}
                      ></div>
                      <p className="!text-[12px] !whitespace-nowrap !ml-6 !my-2 !text-black !font-semibold">
                        {h.title}
                      </p>
                    </SwiperSlide>
                  )
              )}
            </Swiper>
          </div>
        )}
      </div>
      {currentStory && (
        <div className="!flex !items-center !justify-center !z-[200] !fixed !left-0 !top-0 !w-full !h-[100vh] !bg-black">
          <div
            className="!cursor-pointer !absolute !top-0 !right-3 !z-[210] !text-white !font-bold !text-[25px]"
            onClick={handleCloseStory}
          >
            <CancelIcon />
          </div>
          <div className="stories !h-[90%] !w-full md:!w-[50%] !flex !items-center !justify-center">
            <Stories
              stories={currentStory}
              width="100%"
              height="90vh"
              onAllStoriesEnd={handleCloseStory}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default Highlights;
