import localforage from "localforage";
import React, { useEffect, useState } from "react";
import { Dropdown, Form, InputGroup } from "react-bootstrap";
import countries from "countries-list";
import { useMediaQuery } from "react-responsive";

const CountryDropDown = (props) => {
  /*get the countries from the local forage */
  const countriesData = localforage.getItem("countries").then((value) => {
    //console.log("Retrieved value:", value);
  });

  const [filter, setFilter] = useState("");
  const [options, setOptions] = useState([]);

  useEffect(() => {
    localforage.getItem("countries").then((value) => {
      //console.log("Retrieved value:", value);
      setOptions(
        value.map((country) => ({
          value: country.name,
          label: country.name,
        }))
      );
    });
  }, []);

  // useEffect(() => {
  //   console.log(countries);
  // }, []);

  const filteredOptions = options.filter((option) =>
    option.label?.toLowerCase().includes(filter.toLowerCase())
  );
  const [selected, setSelected] = useState(null);
  const [selectedDisplay, setSelectedDisplay] = useState(
    <div>
      <img
        src="https://flagcdn.com/lb.svg"
        alt="Lebanon flag"
        width="20"
        height="14"
        className=" float-left mt-[5px]"
      />
      &nbsp;961
    </div>
  );
  const optionClickHandler = (label, name, flag) => {
    setSelected(label);
    // setSelectedDisplay(flag + label);
    setSelectedDisplay(
      <div>
        <img
          className=" float-left mt-[5px] mr-[5px]"
          src={flag}
          alt={`${name} flag`}
          width="20"
          height="14"
        />{" "}
        {label}
      </div>
    );
    props.setValue(label);
  };

  /**fetch through country api  */
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");

  useEffect(() => {
    fetch("https://restcountries.com/v2/all")
      .then((response) => response.json())
      .then((data) =>
        setCountries(
          data
            .filter((country) => country.alpha2Code !== "IL") // remove Israel
            .map((country) => ({
              code: country.alpha2Code,
              name: country.name,
              flag: country.flag,
              callingCode: `${country.callingCodes[0]}`,
            }))
        )
      );
  }, []);

  const handleCountrySelect = (event) => {
    setSelectedCountry(event.target.value);
  };

  const filteredCountries = countries.filter((country) =>
    country.name.toLowerCase().includes(filter.toLowerCase())
  );

  const isMobile = useMediaQuery({ query: "(max-width:650px)" });

  return (
    <Dropdown>
      <Dropdown.Toggle
        variant="secondary"
        id="dropdown-basic"
        style={
          isMobile
            ? {
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                paddingLeft: "3px",
                background: "white",
                color: "#000",
                borderColor: "transparent",
              }
            : {
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                padding: "5px 1px",
                background: "white",
                color: "#000",
                borderColor: "transparent",
              }
        }
      >
        {selectedDisplay}
      </Dropdown.Toggle>

      <Dropdown.Menu
        style={{ width: "80%", height: "200px", overflowX: "hidden" }}
      >
        <div style={{ width: "90%", margin: "auto" }}>
          <InputGroup className="mb-3">
            <Form.Control
              type="text"
              placeholder="Filter Country"
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
            />
          </InputGroup>
        </div>
        {filteredCountries.map((country) => (
          <Dropdown.Item
            key={country.code}
            value={country.code}
            onClick={() =>
              optionClickHandler(
                country.callingCode,
                country.name,
                country.flag
              )
            }
          >
            <img
              src={country.flag}
              alt={`${country.name} flag`}
              width="20"
              className=" float-left mt-[5px] mr-[5px]"
              height="14"
            />{" "}
            {country.name} ({country.callingCode})
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default CountryDropDown;
