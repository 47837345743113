import React, { useEffect } from "react";
import "./like-heart.css";
import { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";

const LikeHeart = (props) => {
  const [isLiked, setIsLiked] = useState(props.is_Liked);
  function handleLike() {
    if (!loginToken) {
      Swal.fire({
        icon: "warning",
        title: "Login",
        text: "Please Login!",
      });
    } else {
      if (!props.video_id) {
        Swal.fire({
          icon: "warning",
          title: "Undefined",
          text: "Please Select a Video!",
        });
      } else {
        setIsLiked(!isLiked);
        handleLikeRequest(!isLiked);
      }
    }
  }
  const loginToken =
    localStorage.getItem("login-token") ??
    sessionStorage.getItem("login-token");
  async function handleLikeRequest(like) {
    let formData = new FormData();
    formData.append("token", loginToken);
    formData.append("video_id", props.video_id);
    formData.append("is_liked", like);

    try {
      const response = await axios
        .post(`${props.url}material/set-video-completed`, formData)
        .then((res) => {});
    } catch (err) {
      alert(err.message);
    }
  }
  // useEffect(() => {
  //   console.log(props.is_liked);
  //   setIsLiked(props.is_liked);
  // }, [props.is_liked]);

  if (!props.video_id || loginToken == null || loginToken == "null") {
    return;
  }
  return (
    <div
      className={`heart`}
      style={
        isLiked
          ? {
              backgroundPosition: " -2800px 0",
              transition: "background 1s steps(28)",
            }
          : null
      }
      // style={isLiked? (backg) : null}
      onClick={handleLike}
    ></div>
  );
};

export default LikeHeart;
