import React from "react";
import "./BaseCheckbox.css";

function BaseCheckbox(props) {
  const { className } = props;

  return <div className={`base-checkbox-9 ${className || ""}`}></div>;
}

export default BaseCheckbox;
