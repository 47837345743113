import React from "react";
import Frame152 from "../Frame152";
import "./VideosDropdownForMyLearning.css";
import Accordion from "react-bootstrap/Accordion";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from "react-router-dom";

function VideosDropdownForMyLearning(props) {
  const { frame1521Props, frame1522Props, frame1523Props } = props;

  return (
    <div className='videos-dropdown-for-my-learning'>
      <Accordion defaultActiveKey='0'>
        <Accordion.Item eventKey='0'>
          <Accordion.Header>
            <div className='accord-title-container'>
              <div className='accord-title'>Unit #1</div>
              <div className='accord-sub-title'>3 Chapters</div>
            </div>
          </Accordion.Header>
          <Accordion.Body>
            <Link to={"/introduction-video-my-learning-more-section"}>
              <h5>Introduction Video</h5>
            </Link>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey='1'>
          <Accordion.Header>
            <div className='accord-title-container'>
              <div className='accord-title'>Unit #1</div>
              <div className='accord-sub-title'>3 Chapters</div>
            </div>
          </Accordion.Header>
          <Accordion.Body>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey='2'>
          <Accordion.Header>
            <div className='accord-title-container'>
              <div className='accord-title'>Unit #1</div>
              <div className='accord-sub-title'>3 Chapters</div>
            </div>
          </Accordion.Header>
          <Accordion.Body>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      {/* <div className='flex-col-1'>
        <Frame152
          unit1={frame1521Props.unit1}
          address={frame1521Props.address}
        />
        <img
          className='line-9-stroke'
          src='/img/line-9--stroke-@1x.svg'
          alt='Line 9 (Stroke)'
        />
        <Frame152
          unit1={frame1522Props.unit1}
          address={frame1522Props.address}
          className={frame1522Props.className}
        />
        <img
          className='line-9-stroke'
          src='/img/line-9--stroke--1@1x.svg'
          alt='Line 9 (Stroke)'
        />
        <Frame152
          unit1={frame1523Props.unit1}
          address={frame1523Props.address}
          className={frame1523Props.className}
        />
        <img
          className='line-9-stroke'
          src='/img/line-9--stroke--2@1x.svg'
          alt='Line 9 (Stroke)'
        />
      </div> */}
    </div>
  );
}

export default VideosDropdownForMyLearning;
