import { createSlice } from "@reduxjs/toolkit";

const MaterialSlice = createSlice({
  name: "materialSlice",
  initialState: {
    materialSelectedId: null,
  },
  reducers: {
    setMaterialSelectedId(state, action) {
      state.materialSelectedId = action.payload;
    },
  },
});

export const { setMaterialSelectedId } = MaterialSlice.actions;
export default MaterialSlice;
