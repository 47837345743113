import React, { useEffect } from "react";
import SupportedGradesCardImage from "../SupportedGradesCardImage";
import MaterialsSlider from "../MaterialsSlider";
import "./SupportedGradesCard1.css";
import { Card } from "react-bootstrap";
import LazyLoad from "react-lazy-load";
import ProgressiveImage from "react-progressive-image";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";

function SupportedGradesCard1(props) {
  const { grade9_2, language, suppCardBgImg, supMaterialGradeIndex } = props;

  return (
    <div className="supported-grades-cards-wrapper">
      <div className="supported-grades-card-1 bg-white p-1 ">
        <SupportedGradesCardImage
          supBgImg={suppCardBgImg}
          spanText2={grade9_2}
        />
        <div className="card-content-container">
          <div className="grade-1 poppins-semi-bold-black-18px">{grade9_2}</div>
          <div className="language-for-grade">
            <img className="group-5" src="/img/group-1@2x.png" alt="Group" />
            <div className="language-6 poppins-medium-black-18px">
              {language}
            </div>
          </div>
          <div className="sup-material-slider">
            <div className="sup-materials-container">
              <Swiper
                modules={[Navigation, Pagination, Scrollbar, A11y]}
                spaceBetween={10}
                slidesPerView={4}
              >
                {props.supMaterials.length > 0
                  ? props.supMaterials?.map(
                      (supMaterialItem, supMaterialIndex) => {
                        return (
                          <SwiperSlide
                            className="sup-material-swiper-slider"
                            key={supMaterialIndex}
                          >
                            <div className="sup-material-item">
                              {supMaterialItem.title}
                            </div>
                          </SwiperSlide>
                        );
                      }
                    )
                  : null}
              </Swiper>
              {/* <div className='sup-material-item'>{materialName}</div> */}
            </div>
          </div>
        </div>
      </div>
    </div>

    // <>
    //   <Card
    //     style={{ width: "20rem" }}
    //     border='0'
    //     className='cardContainer mx-auto !bg-transparent xsm:!my-3'
    //     // key={index}
    //   >
    //     <LazyLoad style={{ height: "60%" }}>
    //       <ProgressiveImage
    //         src={newUrl}
    //         placeholder={newUrl}
    //       >
    //         {(src) => (
    //           <Card.Img
    //             src={src}
    //             variant='top'
    //             style={{ height: "100%" }}
    //             className='ImgClass !rounded-t-[16px] !rounded-b-[16px] !h-[296px] !w-[296px] !object-cover'
    //           />
    //         )}
    //       </ProgressiveImage>
    //     </LazyLoad>
    //     <Card.Body className='text-left'>
    //       <Card.Title className='!text-[#000000] !font-bold'>
    //         {grade9_2}
    //       </Card.Title>
    //       <Card.Text className='!text-[#000000] !font-medium'>
    //         <div className='language-for-grade'>
    //           <img
    //             className='group-5'
    //             src='/img/group-1@2x.png'
    //             alt='Group'
    //           />
    //           <div className='language-6 poppins-medium-black-18px'>
    //             {language}
    //           </div>
    //         </div>
    //         <div className='sup-material-slider'>
    //           <div className='sup-materials-container'>
    //             <Swiper
    //               modules={[Navigation, Pagination, Scrollbar, A11y]}
    //               spaceBetween={10}
    //               slidesPerView={4}
    //             >
    //               {props.supMaterials &&
    //               props.supMaterials.grades &&
    //               supMaterialGradeIndex &&
    //               props.supMaterials.grades[supMaterialGradeIndex].materials
    //                 .length > 0
    //                 ? props.supMaterials?.grades[
    //                     supMaterialGradeIndex
    //                   ]?.materials?.map((supMaterialItem, supMaterialIndex) => {
    //                     return (
    //                       <SwiperSlide className='sup-material-swiper-slider'>
    //                         <div
    //                           key={supMaterialIndex}
    //                           className='sup-material-item'
    //                         >
    //                           {supMaterialItem.title}
    //                         </div>
    //                       </SwiperSlide>
    //                     );
    //                   })
    //                 : null}
    //             </Swiper>
    //             {/* <div className='sup-material-item'>{materialName}</div> */}
    //           </div>
    //         </div>
    //       </Card.Text>
    //     </Card.Body>
    //   </Card>
    // </>
  );
}

export default SupportedGradesCard1;
