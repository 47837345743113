import React from "react";
import "./Footer.css";
import ScrollUpButton from "../scroll-up-btn/ScrollUpButton";
import Swal from "sweetalert2";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import YoutubeIcon from "@mui/icons-material/YouTube";
import br from "../../Images/brainkets logo.svg";
import { Link } from "react-router-dom";

function Footer(props) {

  if (props.getHomeData && props.getHomeData?.owner_data) {
    localStorage.setItem(
      "owner-wp-number",
      props.getHomeData?.owner_data?.whatsapp
    );
  }

  /**handle phonesnumbers & whatsapp number */
  /**phones here */
  const handlePhoneNumberClick = (e, phoneNumber) => {
    e.preventDefault();
    const link = document.createElement("a");
    link.setAttribute("href", `tel:${phoneNumber}`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setTimeout(() => {
      if (!window.navigator.phone) {
        Swal.fire({
          icon: "success",
          title: "Phone Call",
          text: "Failed to initiate phone call.",
        });
      }
    }, 2000);
  };

  /**whatsapp here */
  function handleWpPhoneClick() {
    const wp_number = props.getHomeData?.owner_data?.whatsapp;
    const formattedPhoneNumber = wp_number.replace(/[\s+]/g, "");

    window.open(
      `https://api.whatsapp.com/send?phone=${formattedPhoneNumber}`,
      "_blank"
    );
  }

  if (props.isLoading) {
    return null;
  }
  return (
    <div className="footer max-[900px]:hidden">
      <div className="footer-flex-row">
        <div className="about-schooltube-in">
          <Link to="/">
            <img
              className="footer-logo-image"
              src="/img/YbYzDG5BBo.png"
              alt="schooltube-logo"
            />
          </Link>
          <div className="lorem-ipsum-dolor-si-5 poppins-normal-white-15px">
            <div
              dangerouslySetInnerHTML={{
                __html: props.getHomeData?.owner_data?.about_us_body,
              }}
            ></div>
          </div>
        </div>

        <div className="contact-us-section ">
          <div className="contact-us poppins-extra-bold-white-20px">
            CONTACT US
          </div>
          <div className="phone-number">
            <div className="phone-number-item">
              <img
                className="phone-1"
                src="/img/phone-1@2x.svg"
                alt="phone 1"
              />
              <p
                className="address valign-text-middle poppins-normal-white-18px"
                onClick={(e) =>
                  handlePhoneNumberClick(
                    e,
                    props.getHomeData?.owner_data?.phone_number_2
                  )
                }
                style={{ cursor: "pointer" }}
              >
                {props.getHomeData &&
                  props.getHomeData?.owner_data?.phone_number_1}
              </p>
            </div>
            <div className="phone-number-item">
              {/* <img
                className="phone-1"
                src="/img/phone-1@2x.svg"
                alt="phone 1"
              />
              <p
                className="address valign-text-middle poppins-normal-white-18px"
                onClick={(e) =>
                  handlePhoneNumberClick(
                    e,
                    props.getHomeData?.owner_data?.phone_number_2
                  )
                }
                style={{ cursor: "pointer" }}
              >
                {props.getHomeData &&
                  props.getHomeData?.owner_data?.phone_number_2}
              </p> */}
            </div>
            <div className="phone-number-item">
              <img className="phone-1" src="./img/wp-img.png" alt="phone 1" />
              <p
                className="address valign-text-middle poppins-normal-white-18px"
                onClick={handleWpPhoneClick}
                style={{ cursor: "pointer" }}
              >
                {props.getHomeData && props.getHomeData?.owner_data?.whatsapp}
              </p>
            </div>
          </div>
          <div className="email">
            <img
              className="letter-1"
              src="/img/letter-1-2@2x.svg"
              alt="letter 1"
            />
            <div className="schooltubeonlinehotmailcom valign-text-middle poppins-normal-white-18px">
              <a
                href={`mailto:${
                  props.getHomeData && props.getHomeData?.owner_data?.email
                }`}
                style={{ textDecoration: "none", color: "#000000" }}
              >
                {" "}
                {props.getHomeData && props.getHomeData?.owner_data?.email}
              </a>
            </div>
          </div>
        </div>
        <div className="follow-us-section">
          <div className="follow-us poppins-extra-bold-white-20px">
            FOLLOW US
          </div>
          <div className="follow-us-links">
            <a
              target={"_blank"}
              className=" text-white"
              href="https://instagram.com/schooltube.lb?igshid=YmMyMTA2M2Y="
            >
              <InstagramIcon className=" text-[black]" />
            </a>
            <a
              className=" text-white"
              target={"_blank"}
              href="https://www.facebook.com/SchoolTube.online?mibextid=ZbWKwL"
            >
              <FacebookIcon className=" text-[black]" />
            </a>
            <a
              className=" text-white"
              target={"_blank"}
              href="https://www.youtube.com/@SchoolTube.online"
            >
              <YoutubeIcon className=" text-[black]" />
            </a>
            <a
              className=" text-white !w-7"
              target={"_blank"}
              href="https://www.tiktok.com/@schooltube.lb"
            >
              <img src="./img/tiktok.png" className="!w-7" />
            </a>
          </div>
        </div>
      </div>
      <div className="align-self-flex-end" style={{ cursor: "pointer" }}>
        <ScrollUpButton />
      </div>

      <hr className="footer-last-line" />
      <div className="footer-flex-row-1 roboto-light-white-14px">
        <p className="copyright-2022-schoo valign-text-middle">
          COPYRIGHT {/*{footer.footer_copy_right}*/}2023 SCHOOLTUBE. ALL RIGHTS
          RESERVED.
        </p>
        <div className="powered-by-brainkets ">
          <a
            href="https://web.brainkets.com/"
            target="_blank"
            className=" text-black text-[16px] !flex !items-center !justify-center"
          >
            Powered By
            <img src={br} alt="brainkets" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default Footer;
