import React, { useState, useEffect } from "react";

function SiteMapResponse() {
  const [result, setResult] = useState("");

  useEffect(() => {
    fetch("https://schooltube.app/schooltube/web/master/generate-sitemap")
      .then((response) => response.text())
      .then((data) => {
        setResult(data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <div>
      <h3>Site Map Result:</h3>
      <p>{result}</p>
    </div>
  );
}

export default SiteMapResponse;
