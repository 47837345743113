import React from "react";
import { Helmet } from "react-helmet";
import UnlockPowerOfStudySection from "../UnlockPowerOfStudySection";
import Navbar from "../Navbar/Navbar";
import TeacherTitleLines from "../TitleLines/TeacherTitleLines";
import "./TeachersDetails.css";
import { useLocation } from "react-router-dom";
import TextTR from "../HomePage/TextTR";

function TeachersDetails(props) {
  const { unlockPowerOfStudySectionProps } = props;

  /*fetching the teachers data */
  //online
  //const url = "https://brainkets.net/schooltube/web/v1_1_0-";
  //const image_url = "http://d2lb7gsppnsll7.cloudfront.net/schooltube/uploads/";
  // const [getTeachersDetailedData, setTeachersDetailedData] = useState([]);

  // function getTeachersDetailsData() {
  //   let formData = new FormData();
  //   formData.append("token", localStorage.getItem("token"));
  //   const url1 = `${url}side/teachers`;
  //   fetch(url1, {
  //     method: "POST",
  //     body: formData,
  //   })
  //     .then((res) => {
  //       if (!res.ok) {
  //         throw new Error(res.statusText);
  //       }
  //       return res.json();
  //     })
  //     .then((res) => {
  //       setTeachersDetailedData(res);
  //     })
  //     .catch((err) => console.log(err));
  // }

  // useEffect(() => {
  //   getTeachersDetailsData();
  // }, []);

  /**getting the value from the state of the link */
  const location = useLocation();
  const { TeacherName, TeacherDescription, youtubeLink, teacherImg } =
    location.state;

  return (
    <>
      <div className="my-learning-container">
        <Helmet>
          <meta name="viewport" content="width=device-width, initial-scale=1" />
        </Helmet>
        <Navbar />
        <div className="teachers-main-container">
          <div className="title-line-container">
            <div
              className="all-grades-1 valign-text-middle poppins-semi-bold-black-25px"
              style={{ width: "100%" }}
            >
              <TextTR text={"lbl_teachers"} />
            </div>
            <TeacherTitleLines />
          </div>
          <div className="teachers-details-cards-container">
            <div
              className="teacher-details-img"
              style={{ backgroundImage: `url(${teacherImg})` }}
            >
              {/* <img
                src={teacherImg}
                alt=''
              /> */}
            </div>
            <div className="teacher-details-content">
              <div className="teacher-info">
                <h2>{TeacherName}</h2>
                {/* <span>{TeacherMajor}</span> */}
                <span>{youtubeLink}</span>
              </div>
              <div className="teacher-description">{TeacherDescription}</div>
            </div>
          </div>
        </div>

        <UnlockPowerOfStudySection
          group148={unlockPowerOfStudySectionProps.group148}
          group147={unlockPowerOfStudySectionProps.group147}
        />
      </div>
      {/* ------------------------------------- */}
      {/* <div className="container-center-horizontal">
      <div className="teachers-details screen">
        <div className="overlap-group-2">
          <BGImage />
          <div className="footer-4">
            <div className="flex-row-9">
              <div className="about-schooltube-in-4">
                <img className="logo-8" src={logo1} alt="logo" />
                <p className="lorem-ipsum-dolor-si-4 poppins-normal-white-15px">{loremIpsumDolorSi1}</p>
              </div>
              <div className="contact-us-section-4">
                <div className="contact-us-4 poppins-extra-bold-white-20px">{contactUs}</div>
                <div className="phone-number-4">
                  <img className="phone-1-4" src="/img/phone-1@2x.svg" alt="phone 1" />
                  <p className="address-9 valign-text-middle poppins-normal-white-18px">{address}</p>
                </div>
                <div className="email-4">
                  <img className="letter-1-4" src="/img/letter-1-5@2x.svg" alt="letter 1" />
                  <div className="schooltubeonlinehotmailcom-4 valign-text-middle poppins-normal-white-18px">
                    {schooltubeOnlineHotmailCom}
                  </div>
                </div>
              </div>
              <div className="follow-us-section-4">
                <div className="follow-us-4 poppins-extra-bold-white-20px">{followUs}</div>
                <img className="social-media-icons-4" src={socialMediaIcons} alt="social media icons" />
              </div>
            </div>
            <a href="#nav-bar" className="align-self-flex-end">
              <img className="scroll-top-btn-4" src="/img/scroll-top-btn-5@2x.svg" alt="scroll top BTN" />
            </a>
            <img className="line-1-4" src="/img/line-1@1x.svg" alt="Line 1" />
            <div className="flex-row-10 roboto-light-white-14px">
              <p className="copyright-2022-schoo-4 valign-text-middle">{copyright2022Schoo}</p>
              <div className="powered-by-brainkets-4 valign-text-middle">{poweredByBrainkets}</div>
            </div>
          </div>
          <UnlockPowerOfStudySection
            group148={unlockPowerOfStudySectionProps.group148}
            group147={unlockPowerOfStudySectionProps.group147}
          />
          <div className="teacher-details valign-text-middle poppins-semi-bold-black-25px">{teacherDetails}</div>
          <div className="title-lines-3">
            <img className="line-33-3" src="/img/line-33-4@2x.svg" alt="Line 33" />
          </div>
          <div className="frame-145" style={{ backgroundImage: `url(${frame145})` }}></div>
          <div className="mr-john-smith-1 valign-text-middle poppins-semi-bold-blue-30px">{mrJohnSmith}</div>
          <div className="surname-2">{surname}</div>
          <p className="lorem-ipsum-dolor-si-5">{loremIpsumDolorSi2}</p>
          <a href="https://www.youtube.com/@schooltube7881" target="_blank">
            <div className="wwwyoutubecom-1">{wwwYoutubeCom}</div>
          </a>
        </div>
        <div className="nav-bar-4" id="nav-bar">
          <img className="logo-9" src={logo2} alt="logo" />
          <NavBarTitles
            navBarText31Props={navBarTitlesProps.navBarText31Props}
            navBarText32Props={navBarTitlesProps.navBarText32Props}
            navBarText33Props={navBarTitlesProps.navBarText33Props}
            navBarText34Props={navBarTitlesProps.navBarText34Props}
            navBarText35Props={navBarTitlesProps.navBarText35Props}
          />
          <SearchField />
          <NavBarElements5 group_37={navBarElements5Props.group_37} className={navBarElements5Props.className} />
          <NavBarElements22 />
        </div>
      </div>
    </div> */}
    </>
  );
}

export default TeachersDetails;
