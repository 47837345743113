import { useEffect, useRef } from "react";
import { useState } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import "./navbar-style.css";
import NavBarElements from "../NavBarElements";
import NavBarElements2 from "../NavBarElements2";
import { useHistory } from "react-router-dom";
import Notification from "./Notification";
import TextTR from "../HomePage/TextTR";
import { useMediaQuery } from "react-responsive";
import { useSelector, useDispatch } from "react-redux";
import AccountProfileDropDown from "./AccountProfileDropDown";
import React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import LoginModal from "../Modalbtsrp/LoginModal";
import { setDrawerLogin } from "../../store/CheckLogin";
import { logout } from "../../store/CheckLogin";
import MenuItem from "@mui/material/MenuItem";
import LanguageIcon from "@mui/icons-material/Language";
//new
import { setNotificationDrawer } from "../../store/CheckLogin";
import { URL } from "../../static/data/config";

function Navbar(props) {
  /**getting the info */
  const [userName, setUserName] = useState("User");
  const userData = useSelector((state) => state.checkLogin.user);
  useEffect(() => {
    if (userData) {
      setUserName(userData?.name);
    }
  }, [userData]);
  /*format date*/
  function formatDate(date) {
    const options = {
      day: "numeric",
      month: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    };

    return date.toLocaleString("en-US", options);
  }

  /**handle the logout */
  /**send request for the time spent  */
  const [hasReloaded, setHasReloaded] = useState(false);
  function getData() {
    let formData = new FormData();
    formData.append("token", loginToken);
    formData.append("active_time", localStorage.getItem("enterTime"));
    formData.append("inActive_time", formatDate(new Date()));
    const url1 = `${URL}users/set-time-spent`;
    // alert(localStorage.getItem("enterTime"));
    fetch(url1, {
      method: "POST",
      body: formData,
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(res.statusText);
        }
        setShouldRender(!shouldRender);
        dispatch(logout());
        setHasReloaded(true);
        return res.json();
      })
      .catch((err) => console.log(err));
  }

  /*handle the logout */
  const [shouldRender, setShouldRender] = useState(false);
  const handleLogOut = () => {
    getData();
  };

  const { user, isLoggedIn } = useSelector((state) => state.checkLogin);

  const navRef = useRef();

  const showNavbar = () => {
    navRef.current.classList.toggle("responsive_nav");
    dispatch(setNotificationDrawer(false));
  };

  const [searchTerm, setSearchTerm] = useState("");
  const history = useHistory();

  const handleSearch = (e) => {
    e.preventDefault();
    history.push({
      pathname: "/search",
      search: `?query=${searchTerm}`,
    });
    setSearchTerm("");
    showNavbar();
  };

  const [showLogIn, setShowLogin] = useState(false);
  const loginToken =
    localStorage.getItem("login-token") ??
    sessionStorage.getItem("login-token");

  useEffect(() => {
    setTimeout(() => {
      if (loginToken && loginToken !== "null") {
        setShowLogin(true);
        // console.log(true);
      } else {
        setShowLogin(false);
        //console.log(false);
      }
    }, 0);
  }, [loginToken]);

  /**for responsivity */
  // const isMobile = useMediaQuery({ query: "(max-width:650px)" });
  const isMobile = useMediaQuery({ query: "(max-width:1024px)" });

  /**for the drawer */
  const dispatch = useDispatch();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ [anchor]: open });
  };

  useEffect(() => {}, [userData]);

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      // onClick={toggleDrawer("right", false)}
      // onKeyDown={toggleDrawer("right", false)}
    >
      <span
        className="text-white"
        style={{
          position: "absolute",
          right: "20px",
          top: "20px",
          width: "30px",
          height: "30px",
          // background: "red",
        }}
        onClick={toggleDrawer("left", false)}
      >
        <CloseIcon />
      </span>
      {/* <NavLink
        to='/home-page'
        href='#'
        style={{
          position: "absolute",
          left: "20px",
          top: "20px",
        }}
      >
        
      </NavLink> */}
      <div className="bg-blue-700 h-[150px] w-100 rounded-b-2xl pt-3">
        {userData && userData.image ? (
          <img
            src={userData.image}
            className="!object-cover mx-auto w-20 h-20 rounded-full"
          />
        ) : (
          <img src={"./img/user image.png"} className="mx-auto w-20 h-20" />
        )}
        {isLoggedIn ? (
          <>
            <p className=" text-center text-white  text-lg font-bold">
              {" "}
              {userData.name}
            </p>
            <p className=" text-center text-white  text-sm  ">
              {userData.phone_number}
            </p>
          </>
        ) : (
          <p className=" text-center text-white  text-lg font-bold">Guest</p>
        )}
      </div>

      <List
      //style={{ marginTop: "100px" }}
      >
        {/* <hr className=" text-slate-400 !mx-auto w-5/6"></hr>
        <ListItem className=" h-12" onClick={toggleDrawer("left", false)}>
          <NavLink
            to="/"
            className="nav-item leading-8 text-gray-500 font-semibold flex"
            style={{ width: "100%", textAlign: "left" }}
          >
            <TextTR text={"lbl_home"} />
          </NavLink>
        </ListItem> */}
        <ListItem className=" h-12" onClick={toggleDrawer("left", false)}>
          <NavLink
            to="/teachers"
            className="nav-item leading-8 text-gray-500 font-semibold flex "
            style={{ width: "100%", textAlign: "left" }}
          >
            <img src="./img/Teachers.svg" />
            <TextTR
              text={"lbl_teachers"}
              className="absolute  left-12 !top-2  "
            />
          </NavLink>
        </ListItem>
        <hr className=" text-slate-400 !mx-auto w-5/6"></hr>
        <ListItem className=" h-12" onClick={toggleDrawer("left", false)}>
          <NavLink
            to="/about-us"
            className="nav-item leading-8 text-gray-500 font-semibold flex "
            style={{ width: "100%", textAlign: "left" }}
          >
            <img src="./img/about us.svg" />
            <TextTR
              className="absolute  left-12 !top-2  "
              text={"lbl_about_us"}
            />
          </NavLink>
        </ListItem>
        <hr className=" text-slate-400 !mx-auto w-5/6"></hr>
        <ListItem className=" h-12" onClick={toggleDrawer("left", false)}>
          <NavLink
            to="/contact-us"
            className="nav-item leading-8 text-gray-500 font-semibold flex "
            style={{ width: "100%", textAlign: "left" }}
          >
            <img src="./img/contact us.svg" />
            <TextTR
              className="absolute  left-12 !top-2 "
              text={"lbl_contact_us"}
            />
          </NavLink>
        </ListItem>
        <hr className=" text-slate-400 !mx-auto w-5/6"></hr>
        <ListItem className=" h-12" onClick={toggleDrawer("left", false)}>
          <NavLink
              to={{ pathname: "https://schooltube.online/sc_policy.html" }}
            target="_blank"
            className="nav-item leading-8 text-gray-500 font-semibold flex "
            style={{ width: "100%", textAlign: "left" }}
          >
               <img src="./img/about us.svg" />
            <TextTR
              className="absolute  left-12 !top-2 "
              text={"Privacy Policy"}
            />
          </NavLink>
        </ListItem>
        <hr className=" text-slate-400 !mx-auto w-5/6"></hr>
        <ListItem className=" h-12" onClick={toggleDrawer("left", false)}>
          <NavLink
            to="/language"
            className="nav-item leading-8 text-gray-500 font-semibold flex "
            style={{ width: "100%", textAlign: "left" }}
          >
            <LanguageIcon className="!text-blue-700" />
            <TextTR
              className="absolute  left-12 !top-2 "
              text={"lbl_language"}
            />
          </NavLink>
        </ListItem>
        <hr className=" text-slate-400 !mx-auto w-5/6"></hr>
        {isLoggedIn ? (
          <ListItem className=" h-12" onClick={toggleDrawer("left", false)}>
            <NavLink
              onClick={handleLogOut}
              to={"/home-page"}
              className="nav-item leading-8 text-gray-500 font-semibold flex"
              style={{ width: "100%", textAlign: "left" }}
            >
              <img src="./img/logout.svg" />
              <MenuItem>Sign out</MenuItem>
            </NavLink>
          </ListItem>
        ) : (
          <ListItem className=" h-12" onClick={toggleDrawer("left", false)}>
            <NavLink
              to={"/home-page"}
              className="nav-item leading-8 text-gray-500 font-semibold flex"
              style={{ width: "100%", textAlign: "left" }}
            >
              <img src="./img/logout.svg" />
              <div
                className="absolute  left-12 !top-2  .text-gray-500 font-500"
                onClick={() => dispatch(setDrawerLogin(true))}
              >
                <LoginModal
                  style={{
                    background: "transparent",
                    color: "#000",
                    border: "none",
                    opacity: "0.7",
                    fontWeight: "500",
                    paddingLeft: "0px",
                  }}
                  btnText={"lbl_login"}
                />
              </div>
            </NavLink>
          </ListItem>
        )}

        <hr className=" text-slate-400 !mx-auto w-5/6"></hr>
        {/* <ListItem className=" h-12" onClick={toggleDrawer("left", false)}>
          {" "}
          <a
            className="go-to-old-version  leading-8  text-red-600 font-semibold flex"
            href="https://schooltube.online/index.php?persist"
            target={"_blank"}
            style={{ width: "100%", textAlign: "left", textAlign: "left" }}
          >
            <img src="./img/old application.svg" className="ml-[-3px]" />
            <TextTR
              className="absolute  left-12 !top-2"
              text={"lbl_old_version"}
            />
          </a>
        </ListItem> */}
        <ListItem
          style={{
            display: "flex",
            justifyContent: "left",
            alignItems: "left",
          }}
        ></ListItem>
      </List>

      <Divider />
      <div className="flex mt-[20px] pl-4">
        <a
          href="https://web.brainkets.com/"
          target="_blank"
          className="text-black text-[16px] !flex !items-center !justify-center"
        >
          <p className="text-xs font-bold">Powered By</p>
          <img className="w-1/3" src="./img/brainkets logo.svg" />
        </a>
      </div>
    </Box>
  );

  // new
  const wp_number = localStorage.getItem("owner-wp-number");
  const formattedPhoneNumber = wp_number?.replace(/[\s+]/g, "");

  return (
    <header>
      {isMobile && (
        <span
          to={{
            pathname: "./",
          }}
          className="mobile-nav-drawer-nav-item !text-black absolute"
          onClick={toggleDrawer("left", true)}
        >
          <i data-feather="menu"></i>
        </span>
      )}
      <Drawer
        anchor={"left"}
        open={state["left"]}
        onClose={toggleDrawer("left", false)}
      >
        {list("left")}
      </Drawer>
      <NavLink
        to="/home-page"
        className="navbar-logo max-[1204px]:mx-auto"
        href="#"
      >
        <img src="/img/new-logo-image.png" alt="Logo" height="16" />
      </NavLink>
      <div className="nav-list">
        <nav ref={navRef}>
          {/* <a
            className="go-to-old-version  text-red-600"
            href="https://schooltube.online/index.php?persist"
            target={"_blank"}
          >
            <TextTR text={"lbl_old_version"} />
          </a> */}
          <NavLink to="/" className="nav-item" onClick={showNavbar}>
            <TextTR text={"lbl_home"} />
          </NavLink>
          <NavLink to="/teachers" className="nav-item" onClick={showNavbar}>
            <TextTR text={"lbl_teachers"} />
          </NavLink>
          <NavLink to="/my-learning" className="nav-item" onClick={showNavbar}>
            <TextTR text="lbl_my_learning2" />
          </NavLink>
          <NavLink to="/all-grades" className="nav-item" onClick={showNavbar}>
            <TextTR text={"lbl_all_grades2"} />
          </NavLink>

          <NavLink to="/about-us" className="nav-item" onClick={showNavbar}>
            <TextTR text={"lbl_about_us"} />
          </NavLink>
          <NavLink to="/privacy-policy"  className="nav-item" onClick={showNavbar}>
            <TextTR text={"Privacy Policy"} />
          </NavLink>
          <NavLink to="/contact-us" className="nav-item" onClick={showNavbar}>
            <TextTR text={"lbl_contact_us"} />
          </NavLink>

          <form onSubmit={handleSearch}>
            <div className="search">
              <input
                type="text"
                name=""
                placeholder="Search Grades"
                className="search-text"
                style={{ width: "150px" }}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <a href="#" onClick={handleSearch} className="search-btn">
                <i className="fa fa-search"></i>
              </a>
            </div>
          </form>

          <NavBarElements
            getHomeData={props.getHomeData}
            url={URL}
            onClick={showNavbar}
          />

          {isLoggedIn ? (
            <>
              <AccountProfileDropDown url={URL} />
              {!isMobile && <Notification url={URL} />}
            </>
          ) : (
            <NavBarElements2 url={URL} onClick={showNavbar} />
          )}

          <button className="nav-btn nav-close-btn" onClick={showNavbar}>
            <FaTimes />
          </button>
        </nav>
        {isMobile && showLogIn && <Notification url={URL} />}
        {isMobile && (
          <div
            className="mobile-login-nav"
            style={isLoggedIn ? { right: "10%" } : { right: "5%" }}
          >
            {isLoggedIn ? (
              <>
                <a
                  href={`https://api.whatsapp.com/send?phone=${formattedPhoneNumber}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fab fa-whatsapp !text-[30px] !mr-[5px]"></i>
                </a>
                <AccountProfileDropDown url={URL} />
                {!isMobile && <Notification url={URL} />}
              </>
            ) : (
              <>
                <a
                  href={`https://api.whatsapp.com/send?phone=${formattedPhoneNumber}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fab fa-whatsapp !text-[30px] !mr-[5px]"></i>
                </a>
                <NavBarElements2 url={URL} onClick={showNavbar} />
              </>
            )}
          </div>
        )}
        <button className="nav-btn" onClick={showNavbar}>
          <FaBars />
        </button>
      </div>
    </header>
  );
}

export default Navbar;
