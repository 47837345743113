import React from "react";
import "./BTNComponent2.css";

function BTNComponent2(props) {
  const { className } = props;

  return (
    <div className={`read-more-btn-2 ${className || ""}`}>
      <div className="read-more-1 poppins-semi-bold-white-20px">Register Today</div>
    </div>
  );
}

export default BTNComponent2;
