import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import { Form, FormGroup } from "react-bootstrap";
import { Col } from "react-bootstrap";
import "./verification.css";
import "./loginmodal.css";
import "./loginmodal2.css";
import "./loginmodal3.css";
import "./resetPwd.css";
import axios from "axios";
import Swal from "sweetalert2";
import GoogleRecaptcha from "../GoogleRecaptcha";
import { useTranslation } from "../translationUtils";
import TextTR from "../HomePage/TextTR";

function ResetChangePwd(props) {
  const { getTranslatedWord } = useTranslation();

  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [recaptchaResponse, setRecaptchaResponse] = useState();

  async function handleResetVerifySubmit(event) {
    event.preventDefault();

    if (newPassword.trim().length > 0 && confirmPassword.trim().length > 0) {
      let formData = new FormData();
      formData.append("country_code", props.countryCode);
      formData.append("phone_number", props.phoneNumber);
      formData.append("password", newPassword);
      formData.append("code", props.code);
      formData.append("recaptcha_response", recaptchaResponse);
      formData.append("is_web", true);

      const response = await axios
        .post(`${props.url}users/reset-password`, formData)
        .then((res) => {
          //alert("Success");
          if (res.data.succeeded == true) {
            Swal.fire({
              icon: "success",
              title: getTranslatedWord(res.data.title),
              text: getTranslatedWord(res.data.messgae),
            });
            window.location.reload();
          } else {
            Swal.fire({
              icon: "error",
              title: getTranslatedWord(res.data.title),
              text: getTranslatedWord(res.data.messgae),
            });
          }
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: getTranslatedWord(err.messgae),
          });
        });
    } else {
      Swal.fire({
        icon: "warning",
        title: "Empty Fields",
        text: "Please Fill All the Fields!",
      });
    }
  }

  return (
    <Col
      xs={12}
      md={6}
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div className="reset-form-container">
        <div className="reset-form-container-title">
          <h3>
            <TextTR text="lbl_reset_pwd" />
          </h3>
        </div>

        <Form.Group style={{ width: "100%" }}>
          <Form.Label className="bold-text">New Password</Form.Label>
          <Form.Control
            type="password"
            placeholder="Enter New Password"
            value={newPassword}
            onChange={(event) => setNewPassword(event.target.value)}
            defaultValue={"961"}
          />
        </Form.Group>

        <Form.Group style={{ width: "100%" }}>
          <Form.Label className="bold-text">Password</Form.Label>
          <Form.Control
            type="password"
            placeholder="Confirm Password"
            value={confirmPassword}
            onChange={(event) => setConfirmPassword(event.target.value)}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicPassword">
          <GoogleRecaptcha setRecaptchaResponse={setRecaptchaResponse} id={5} />
        </Form.Group>
        <FormGroup style={{ width: "80%" }}>
          <Button
            className="!bg-[#0d6efd] !text-white !w-full"
            onClick={handleResetVerifySubmit}
          >
            <TextTR text="lbl_reset_pwd" />
          </Button>
        </FormGroup>
      </div>
    </Col>
  );
}

export default ResetChangePwd;
