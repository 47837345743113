import { Helmet } from "react-helmet";
import { useState, useEffect } from "react";
import "./about-us.css";
import TeacherTitleLines from "../TitleLines/TeacherTitleLines";
import UnlockPowerOfStudySection from "../UnlockPowerOfStudySection";
import TextTR from "../HomePage/TextTR";
import { useMediaQuery } from "react-responsive";
import { useDispatch, useSelector } from "react-redux";
import { setNotificationDrawer } from "../../store/CheckLogin";

function AboutUs(props) {
  const dispatch = useDispatch();
  
  const langReduxID = useSelector((state) => state.dataFetched.aboutUsLang);
  const [langID, setLangID] = useState(localStorage.getItem("lang-id") ?? 1);
  
  useEffect(() => {
    if (langReduxID) {
      setLangID(langReduxID);
    } else {
      setLangID(localStorage.getItem("lang-id"));
    }
  }, [langReduxID]);

  const isMobile = useMediaQuery({ query: "(max-width:650px)" });

  return (
    <>
      <div
        className="my-learning-container"
        onClick={() => {
          dispatch(setNotificationDrawer(false));
        }}
      >
        <Helmet>
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <title>About Us</title>
          <meta
            name="description"
            content={`Schooltube offers a user-friendly platform that allows all grades students to access high-quality educational resources quickly and efficiently. Our curated library of materials covers a wide range of subjects and topics, providing students with accurate and reliable information. `}
          />
        </Helmet>
        {/* <Navbar /> */}
        <div className="teachers-main-container">
          <div className="my-learning-title-section">
            <div className="title-redeam-section">
              <TextTR
                className="valign-text-middle poppins-semi-bold-black-25px"
                text={"lbl_about_us"}
              />
            </div>
            <TeacherTitleLines className="myLearning-title-lines" />
          </div>
          {/* <div className='title-line-container'>
            <div
              className='all-grades-1 valign-text-middle poppins-semi-bold-black-25px'
              style={{ width: "100%" }}
            >
              About US
            </div>
            <TitleLines />
          </div> */}
          {props.isLoading ? (
            <div className="home-loading">
              <img src="./img/all-grade-loading.gif" alt="loading" />
            </div>
          ) : (
            <div className="about-us-container">
              <div className="about-us-content">
                <h1 className="about-us-header-center">
                  <TextTR text={"msg_what_is_schooltube"} />
                </h1>
                <div
                  className="about-us-body"
                  dangerouslySetInnerHTML={{
                    __html: props.getAboutUsData?.languages
                      ?.map((langItem) => {
                        if (langItem.id == langID) {
                          const aboutItem = props.getAboutUsData.about_body.find(
                            (aboutItem) => aboutItem.r_languages_id == langID
                          );
                          return aboutItem?.body;
                        }
                      })
                      .join(""),
                  }}
                />
              </div>
              <img src="./img/about-us-img.png" alt="about-schooltube-img" />
              <div className="about-us-content">
                <div className="about-us-flex-row">
                  <h1 className="about-us-together">Together we are Strong</h1>
                  <p>
                    Schooltube is always growing, but our primary goal is to
                    help students reach their full potential, and achieve
                    success with the best grades, all while having fun!
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
        {isMobile ? null : (
          <UnlockPowerOfStudySection
            group148={"/img/group-148-1@2x.png"}
            group147={"/img/group-147-2@2x.png"}
          />
        )}
      </div>
    </>
  );
}
export default AboutUs;
