import React, { useEffect, useState, useRef } from "react";
import Button from "react-bootstrap/Button";
import { Form } from "react-bootstrap";
import { Container, Row, Col } from "react-bootstrap";
import "./loginmodal.css";
import "./loginmodal2.css";
import "./loginmodal3.css";
import "./register.css";
import CountryDropDown from "./CountryDropDown";
import axios from "axios";
import Swal from "sweetalert2";
import VerificationModal from "./VerificationMoadl";
import { useDispatch } from "react-redux";
import {
  setLoginChecked,
  setVerified,
  login,
  setTableName,
} from "../../store/CheckLogin";
import { useTranslation } from "../translationUtils";
import { useMediaQuery } from "react-responsive";
import { ClockLoader } from "react-spinners";

function RegisterModal(props) {

  const { getTranslatedWord } = useTranslation();

  const [show, setShow] = useState(false);


  const [input, setInput] = useState("");
  const [submitted, setSubmitted] = useState(false);

  /*Register Var */
  const [registerData, setRegisterData] = useState();
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [countryCode, setCountryCode] = useState("961");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [checkPass, setCheckPass] = useState();
  const [recaptchaResponse, setRecaptchaResponse] = useState();

  /**for verifiaction */
  const [showVerificationModal, setShowVerificationModal] = useState(false);

  const dispatch = useDispatch();
  const [loaderBtn, setLoaderBtn] = useState(false);

  async function handleRegisterSubmit(event) {
    event.preventDefault();
    setSubmitted(true);
    setLoaderBtn(true);
    // Verify the user's input against the captcha solution

    //check if pass and con pass is equal
    if (password === confirmPassword) {
      //fetching the login with post request
      let formData = new FormData();
      formData.append("name", userName);
      formData.append("email", userEmail);
      formData.append("country_code", countryCode);
      formData.append("phone_number", phoneNumber);
      formData.append("password", password);
      formData.append("is_web", true);
      formData.append("device_data", JSON.stringify(props.device_data));
      // formData.append("recaptcha_response", recaptchaResponse);

      const response = await axios
        .post(`${props.url}users/sign-up`, formData)
        .then((res) => {
          setLoaderBtn(false);
          //alert("Success");
          if (res.data.succeeded) {
            setRegisterData(res);
            setShow(false);
            setShowVerificationModal(true);
            dispatch(setTableName(res.data.table_name));
          } else {
            Swal.fire({
              icon: "warning",
              title: getTranslatedWord(res.data.title),
              text: getTranslatedWord(res.data.message),
            });
          }
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: getTranslatedWord(err.message),
          });
        });
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `${password} + " /n and " + ${confirmPassword}`,
      });
    }
  }

  const countryCodeHandler = (v) => {
    setCountryCode(v);
  };

  /**for verification  */
  const inputRefs = [
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
  ];

  function handleInput(event, index) {
    const input = event.target;
    const nextInput = inputRefs[index + 1] && inputRefs[index + 1].current;
    if (input.value.length === 1 && nextInput) {
      nextInput.focus();
    }
  }

  const [code1, setCode1] = useState("");
  const [code2, setCode2] = useState("");
  const [code3, setCode3] = useState("");
  const [code4, setCode4] = useState("");
  const [code5, setCode5] = useState("");
  const [code6, setCode6] = useState("");

  const verifiaction_code = code1 + code2 + code3 + code4 + code5 + code6;

  const [verificationData, setVerificationData] = useState();
  const [hasReloaded, setHasReloaded] = useState(false);

  useEffect(() => {
    if (
      verificationData &&
      verificationData.data &&
      verificationData.data.succeeded
    ) {
      dispatch(login(verificationData.data.user));
      sessionStorage.setItem("login-token", verificationData.data.token);
      localStorage.removeItem("login-token");
      localStorage.setItem("loginData", JSON.stringify(verificationData));
      if (!hasReloaded) {
        setHasReloaded(true);
        window.location.reload();
      }
    }
  }, [verificationData]);

  /**for responsivity */
  const isMobile = useMediaQuery({ query: "(max-width:650px)" });

  return (
    <>
      <Container>
        <Row>
          <Col xs={12} md={6}>
            <div
              className="register-image"
              style={{
                backgroundImage: "url(./img/login-image.png)",
              }}
            ></div>
          </Col>
          {showVerificationModal ? (
            <VerificationModal
              modalstat={show}
              url={props.url}
              countryCode={countryCode}
              phoneNumber={phoneNumber}
              password={password}
              device_data={JSON.stringify(props.device_data)}
            />
          ) : (
            <Col xs={12} md={6}>
              <div className="flex-col-login">
                <div className="welcome-back valign-text-middle poppins-semi-bold-blue-24px">
                  Welcome
                </div>
                <p className="welcome-back-please-enter-you-details poppins-normal-black-13px">
                  Please enter your details to get started
                </p>
                <Form
                  className="form-container"
                  onSubmit={handleRegisterSubmit}
                >
                  <Form.Group
                    className="mb-3"
                  >
                    <Form.Label className="bold-text">Full Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter your full name"
                      onChange={(event) => setUserName(event.target.value)}
                    />
                  </Form.Group>

                  <Form.Group>
                    <Form.Label className="bold-text">Phone Number</Form.Label>
                    <Row>
                      <Col
                        xs={isMobile ? 4 : 3}
                        style={{ paddingRight: "0px" }}
                      >
                        <CountryDropDown setValue={countryCodeHandler} />
                      </Col>
                      <Col
                        xs={isMobile ? 8 : 9}
                        style={isMobile ? { paddingLeft: "5px" } : null}
                      >
                        <Form.Control
                          type="number"
                          pattern="[0-9]{3}-[0-8]{2}-[0-9]{3}-[0-9]{3}|[0-9]{3}-[0-9]{7}"
                          placeholder="Phone Number"
                          onChange={(event) =>
                            setPhoneNumber(event.target.value)
                          }
                        />
                      </Col>
                    </Row>
                  </Form.Group>

                  <Form.Group
                    className="mb-3"
                  >
                    <Form.Label className="bold-text">Password</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Enter Password"
                      onChange={(event) => {
                        const value = event.target.value;
                        if (value.length > 5) {
                          setPassword(value);
                        }
                      }}
                    />
                  </Form.Group>
                  <Form.Group
                    className="mb-3"
                  >
                    <Form.Label className="bold-text">
                      Confirm Password
                    </Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Confirm password"
                      onChange={(event) =>
                        setConfirmPassword(event.target.value)
                      }
                    />
                  </Form.Group>
                  <Button
                    variant="primary"
                    type="submit"
                    style={{ backgroundColor: "#0d6efd" }}
                    disabled={loaderBtn}
                  >
                    {loaderBtn ? (
                      <ClockLoader color="#fff" size={"20px"} />
                    ) : (
                      "Register"
                    )}
                  </Button>
                  <p className="dontt-have-an-account-register-now">
                    <span className="span0"> Already have an account? </span>
                    <span
                      className="poppins-medium-blue-13px"
                      onClick={() => {
                        dispatch(setLoginChecked());
                      }}
                    >
                      {" "}
                      Login now{" "}
                    </span>
                  </p>
                </Form>
              </div>
            </Col>
          )}
        </Row>
      </Container>
    </>
  );
}

export default RegisterModal;
