import React from "react";
import "./BGImage.css";

function QuizBGImage() {
  return (
    <div className='quiz-main-bg-image'>
      <img
        className='quiz-bg-image'
        src='/img/rm347-porpla-02-a-01--converted--01-01-1@1x.png'
        alt='rm347-porpla-02-a-01 [Converted]-01-01 1'
      />
      <img
        className='quiz-bg-image'
        src='/img/rm347-porpla-02-a-01--converted--01-01-2@1x.png'
        alt='rm347-porpla-02-a-01 [Converted]-01-01 2'
      />
      <img
        className='quiz-bg-image'
        src='/img/rm347-porpla-02-a-01--converted--01-01-1@1x.png'
        alt='rm347-porpla-02-a-01 [Converted]-01-01 3'
      />
      <img
        className='quiz-bg-image'
        src='/img/rm347-porpla-02-a-01--converted--01-01-1@1x.png'
        alt='rm347-porpla-02-a-01 [Converted]-01-01 1'
      />
    </div>
  );
}

export default QuizBGImage;
