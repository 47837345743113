import React, { useState, useEffect, useRef } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Form } from "react-bootstrap";
import { Container, Row, Col } from "react-bootstrap";
import "./verification.css";
import "./loginmodal.css";
import "./loginmodal2.css";
import "./loginmodal3.css";
import axios from "axios";
import Swal from "sweetalert2";
import ResetChangePwd from "./ResetChangePwd";
import OtpInput from "react-otp-input";
import { useTranslation } from "../translationUtils";

function ResetVerificationCode(props) {
  const { classNameName, btnText } = props;

  const { getTranslatedWord, setLanguage } = useTranslation();

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  /**to move to the next input when the user enter a charc */
  /**for resending the v-code */
  const [resendDisabled, setResendDisabled] = useState(false);

  /**resend activation code */
  async function handleResendCode() {
    let formData = new FormData();
    formData.append("country_code", props.countryCode);
    formData.append("phone_number", props.phoneNumber);

    const response = await axios
      .post(`${props.url}users/send-activation-code`, formData)
      .then((res) => {
        //alert("Success");
        if (res.data.succeeded) {
          // After 5 minutes, enable the button again
          setTimeout(() => {
            setResendDisabled(false);
          }, 5 * 60 * 1000); // 5 minutes in milliseconds
        } else {
          Swal.fire({
            icon: "error",
            title: getTranslatedWord(res.data.title),
            text: getTranslatedWord(res.data.message),
          });
        }
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: getTranslatedWord(err.title),
        });
      });
  }

  // Disable the button for 5 minutes after a successful function call
  const handleResendClick = () => {
    // Disable the button
    setResendDisabled(true);
    handleResendCode();
  };

  /**api post request to verify the accont */
  const [verificationData, setVerificationData] = useState();
  const [hasReloaded, setHasReloaded] = useState(false);
  const [verificationSuccess, setVerificationSuccess] = useState(false);

  const [otp, setOtp] = useState("");

  async function handleVerifySubmit(event) {
    event.preventDefault();

    let formData = new FormData();
    formData.append("country_code", props.countryCode);
    formData.append("phone_number", props.phoneNumber);
    formData.append("code", otp);

    const response = await axios
      .post(`${props.url}users/check-reset-code`, formData)
      .then((res) => {
        setVerificationData(res.data);
        if (res.data.succeeded) {
          setVerificationSuccess(true);
        }
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: getTranslatedWord(err.message),
        });
      });
  }

  return (
    <>
      {verificationSuccess ? (
        <ResetChangePwd
          url={props.url}
          countryCode={props.countryCode}
          phoneNumber={props.phoneNumber}
          code={otp}
        />
      ) : (
        <Col
          xs={12}
          md={6}
        >
          <div
            className='flex-col-login'
            style={{ "margin-top": "15%;" }}
          >
            <div className='verify-your-account valign-text-middle poppins-semi-bold-blue-24px'>
              Verify your Account
            </div>
            <p className='please-enter-the-verification-code poppins-normal-black-13px'>
              Please enter the verification code
            </p>
            <p className='code-is-sent-to-961-70-123-456'>
              Code is sent to {props.phoneNumber}
            </p>
            <div className='code-container'>
              <OtpInput
                value={otp}
                onChange={setOtp}
                numInputs={6}
                renderSeparator={<span>&nbsp;&nbsp;</span>}
                renderInput={(props) => <input {...props} />}
                style={{
                  width: "2em",
                  textAlign: "center",
                  height: "2rem",
                }}
              />
              <div className='d-flex ver-flex-row mt-3'></div>
              <div className='text-center mt-5'>
                <span className='d-block mobile-text'>
                  Didn't receive the code?
                </span>
                <span
                  className={`font-weight-bold text-danger cursor ${
                    resendDisabled ? "resend-disabled" : ""
                  }`}
                  onClick={resendDisabled ? null : handleResendClick}
                >
                  Resend
                </span>
              </div>
            </div>
            <div className='btn-component'>
              <div
                className='!w-full read-more valign-text-middle poppins-medium-white-16px'
                onClick={handleVerifySubmit}
                style={{ cursor: "pointer" }}
              >
                Verify
              </div>
            </div>
          </div>
        </Col>
      )}
    </>
  );
}

export default ResetVerificationCode;
