import React from "react";

function SocialMediaIcons() {
  return (
    <div className="flex justify-center items-center mt-14 md:mt-20 w-[80%] md:w-[50%]">
      <a
        className=" inline-block"
        href="https://www.youtube.com/@SchoolTube.online"
      >
        <img
          src="./img/youtube_icon.svg"
          className=" md:w-[65px] lg:w-[90px]  w-[45px]  ml-auto mr-auto"
          alt="YouTube"
        />
      </a>
      <a className=" inline-block " href="https://mail.google.com">
        <img
          src="./img/gmail_icon - Copy.svg"
          className=" md:w-[65px] lg:w-[90px] w-[45px]  ml-auto mr-auto"
          alt="Gmail"
        />
      </a>
      <a
        className=" inline-block   "
        href="https://instagram.com/schooltube.lb?igshid=YmMyMTA2M2Y="
      >
        <img
          src="./img/insta_logo.svg"
          className=" md:w-[65px] lg:w-[90px]  w-[45px]   ml-auto mr-auto"
          alt="Instagram"
        />
      </a>
      <a
        className=" inline-block   "
        href="https://www.facebook.com/SchoolTube.online?mibextid=ZbWKwL"
      >
        <img
          src="./img/fb_icon.svg"
          className=" md:w-[65px] lg:w-[90px]  w-[45px]  ml-auto mr-auto"
          alt="Facebook"
        />
      </a>
      <a
        className=" inline-block  ml-auto mr-auto "
        href="https://www.tiktok.com/@schooltube.lb"
      >
        <img
          src="./img/tiktok_icon.svg"
          className=" md:w-[65px] lg:w-[90px] w-[45px]  ml-auto mr-auto"
          alt="TikTok"
        />
      </a>
    </div>
  );
}

export default SocialMediaIcons;
