import React from "react";
import "./GradeImage3.css";

function GradeImage3() {
  return (
    <div className="grade-image-2">
      <div className="grade-9-english-2 poppins-semi-bold-white-40px">
        Grade 9<br />
        English
      </div>
    </div>
  );
}

export default GradeImage3;
