import React from "react";
import "./GradeImage.css";
import { useState } from "react";

function GradeImage(props) {
  const { gradeLevelImg, bgimage } = props;

  /**for the blur */
  let newUrl = "";
  let encodedUrl = "";
  if (bgimage.startsWith("./img")) {
    newUrl = bgimage;
  } else {
    if (!bgimage.includes("cloudfront.net/") && !bgimage.includes("schooltube.s3.eu-west-3.amazonaws.com")) {
      //setEncodeState(true);
      const enc_parts = bgimage.split("/");
      const enc_lastPart = enc_parts[enc_parts.length - 1];
      const encodedLastPart = encodeURI(enc_lastPart);
      encodedUrl = bgimage.replace(enc_lastPart, encodedLastPart);
      /**add the blur */
      const parts = encodedUrl.split("/");
      const fileName = parts[parts.length - 1];
      const baseUrl = parts.slice(0, parts.length - 1).join("/");
      newUrl = baseUrl + "/blures/" + fileName;
    } else {
      /**add the blur */
      const parts = bgimage.split("/");
      const fileName = parts[parts.length - 1];
      const baseUrl = parts.slice(0, parts.length - 1).join("/");
      newUrl = baseUrl + "/blures/" + fileName;
    }
  }

  const [isLoaded, setIsLoaded] = useState(false);

  const handleImageLoad = () => {
    setTimeout(() => setIsLoaded(true), 1000); // set isLoaded to true after 1 second
  };

  return (
    <>
      {isLoaded ? (
        <img
          src={bgimage}
          alt=""
          onLoad={handleImageLoad}
          className="!min-h-[83px] rounded-md w-full max-h-[134px]  max-[712px]:max-h-[96px] max-[514px]:max-h-[83px]"
          // style={{ display: "none" }}
        />
      ) : (
        // <div
        //   className={`grade-image md:h-32 sm:h-48 xs:h-30 max-[450px]:h-28 rounded-md ${
        //     isLoaded ? " loaded" : ""
        //   } supported-grades-card-1-blent`}
        //   style={{
        //     backgroundImage: `url(${newUrl})`,
        //     transition: "background-image 1s",
        //   }}
        // ></div>
        <img
          src={newUrl}
          alt=""
          onLoad={handleImageLoad}
          className={`!min-h-[83px] rounded-md w-full max-h-[134px] max-[712px]:max-h-[96px] max-[514px]:max-h-[83px]  ${
            isLoaded ? " loaded" : ""
          }`}
        />
      )}
      {/* <div
        className={`grade-image md:h-32 sm:h-48 xs:h-30 max-[450px]:h-28 rounded-md ${
          isLoaded ? " loaded" : ""
        } supported-grades-card-1-blent`}
        style={{
          backgroundImage: `${isLoaded ? `url(${bgimage})` : `url(${newUrl})`}`,
          transition: "background-image 1s",
        }}
      > */}
      {/* <div className='grade-5 valign-text-middle poppins-semi-bold-white-25px'>
          {gradeLevelImg}
        </div> */}
      {/* </div> */}
      <img
        src={bgimage}
        alt=""
        onLoad={handleImageLoad}
        style={{ display: "none" }}
      />
    </>
  );
}

export default GradeImage;
