import React from "react";
import "./mobile-bottom-nav.css";
import { useEffect } from "react";
import feather from "feather-icons";
import { Link } from "react-router-dom";

const MobileBottomNav = (props) => {
  useEffect(() => {
    feather.replace();
    document.querySelector(".nav-item").focus();
  }, []);

  return (
    <div className="mobile-nav-drawer">
      <Link
        to={{
          pathname: "./",
        }}
        className="mobile-nav-drawer-nav-item"
      >
        <img className="mx-auto" src="./img/home.svg" />
        <i className=" hidden " data-feather="home"></i>
        <p className=" text-blue-700 text-[10px]">Home</p>
      </Link>

      <Link
        to={{
          pathname: "./my-learning",
        }}
        className="mobile-nav-drawer-nav-item"
      >
        <img className="mx-auto" src="./img/Book.svg" />
        <p className=" text-blue-700 text-[10px]">My Courses</p>
      </Link>

      <Link
        to={{
          pathname: "./all-grades",
        }}
        className="mobile-nav-drawer-nav-item"
      >
        <img className="mx-auto" src="./img/graduation hat.svg" />
        <p className=" text-blue-700 text-[10px]">All Grades</p>
      </Link>
    </div>
  );
};

export default MobileBottomNav;
