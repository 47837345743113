import React, { useEffect } from "react";
import Frame15 from "../Frame15";
import "./MaterialsSlider.css";

function MaterialsSlider(props) {
  const { frame151Props, frame152Props } = props;

  return (
    <div className='materials-slider roboto-medium-blue-14-4px'>
      {props.SupGradesmaterialsSlider &&
        props.SupGradesmaterialsSlider?.grades?.materials?.map(
          (supMaterialItem, supMaterialIndex) => {
            //console.log(props.SupGradesmaterialsSlider);

            return (
              <>
                <div className='frame'>
                  <div className='math'>{supMaterialItem.title}</div>
                </div>
              </>
            );
          }
        )}
    </div>
  );
}

export default MaterialsSlider;
