import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { translationAction } from "../../store/tr-slice";

const Tr = (label) => {
  const dispatch = useDispatch();
  const list = useSelector((state) => state.translation.translation);
  const sc = useSelector((state) => state.translation.tr_shourtcut) ?? "en";

  useEffect(() => {
    const localTranslation = JSON.parse(localStorage.getItem("tr_list"));

    if (localTranslation) {
      dispatch(
        translationAction.setTranslation(localStorage.getItem("tr_list"))
      );
    }
  }, []);

  const translatedLabel = tr(label, sc, list);
  return translatedLabel;
};

function tr(label, sc, list) {
  if (list && sc in list) {
    for (let lbl in list[sc]) {
      if (lbl == label) {
        return list[sc][lbl];
      }
    }
  }

  if (list && sc != "en" && "en" in list) {
    for (let lbl in list["en"]) {
      if (lbl == label) {
        return list["en"][lbl];
      }
    }
  }

  return label;
}

export default Tr;
