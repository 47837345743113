import React from "react";
import "./WeSupportTheseCountries.css";

function WeSupportTheseCountries() {
  return (
    <div className="we-support-these-countries">
      <img className="line-31-stroke-2" src="/img/line-31--stroke--17@2x.svg" alt="Line 31 (Stroke)" />
      <p className="we-support-these-countries_2 valign-text-middle poppins-semi-bold-black-35px">
        We are proud to serve customers in
      </p>
      <img className="line-31-stroke-2" src="/img/line-31--stroke--2-1@2x.svg" alt="Line 31 (Stroke)_2" />
    </div>
  );
}

export default WeSupportTheseCountries;
