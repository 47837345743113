// translationUtils.js
import { useState, useEffect } from "react";

export function useTranslation() {
  const [translations, setTranslations] = useState(null);
  const [language, setLanguage] = useState(
    localStorage.getItem("lang-shortcut") ?? "en"
  );

  // Function to retrieve translations from local storage
  const getTranslationsFromLocalStorage = () => {
    const translationsFromLocalStorage = localStorage.getItem("tr_list");
    if (translationsFromLocalStorage) {
      setTranslations(JSON.parse(translationsFromLocalStorage));
    }
  };

  useEffect(() => {
    getTranslationsFromLocalStorage();
  }, []);

  const getTranslatedWord = (label) => {
    if (!translations || !translations[language]) {
      return label; // Translation not found, return the label itself
    }

    const translationObject = translations[language];
    if (!translationObject || !translationObject[label]) {
      return label; // Translation not found, return the label itself
    }

    return translationObject[label];
  };

  return { getTranslatedWord, setLanguage };
}
