import React from "react";
import "./SupportedGradesCardImage.css";
import { useState } from "react";
import Carousel from "react-carousel";

function SupportedGradesCardImage(props) {
  const { spanText2, supBgImg } = props;
  // const [encodeState, setEncodeState] = useState(false);
  /**for the blur */
  let newUrl = "";
  let encodedUrl = "";
  if (supBgImg.startsWith("./img")) {
    newUrl = supBgImg;
  } else {
    if (!supBgImg.includes("cloudfront.net/") && !supBgImg.includes("schooltube.s3.eu-west-3.amazonaws.com")) {
      //setEncodeState(true);
      const enc_parts = supBgImg.split("/");
      const enc_lastPart = enc_parts[enc_parts.length - 1];
      const encodedLastPart = encodeURI(enc_lastPart);
      encodedUrl = supBgImg.replace(enc_lastPart, encodedLastPart);
      /**add the blur */
      const parts = encodedUrl.split("/");
      const fileName = parts[parts.length - 1];
      const baseUrl = parts.slice(0, parts.length - 1).join("/");
      newUrl = baseUrl + "/blures/" + fileName;
    } else {
      /**add the blur */
      const parts = supBgImg.split("/");
      const fileName = parts[parts.length - 1];
      const baseUrl = parts.slice(0, parts.length - 1).join("/");
      newUrl = baseUrl + "/blures/" + fileName;
    }
  }

  const [isLoaded, setIsLoaded] = useState(false);

  const handleImageLoad = () => {
    setTimeout(() => setIsLoaded(true), 1000);
  };

  const [imageExists, setImageExists] = useState(true);

  const handleCheckImageExist = () => {
    setImageExists(false);
  };

  /**blur ends here */

  return (
    <div
      className="supported-grades-card-image supported-grades-card-1-blent"
      style={{
        backgroundImage: `${isLoaded ? `url(${supBgImg})` : `url(${newUrl})`}`,
        transition: "background-image 1s",
      }}
    >
      {/* <div className='grade-2 poppins-semi-bold-white-35px'>
        <span className='cardSpanText poppins-semi-bold-white'>
          {spanText2}
        </span>
      </div> */}
      <img
        src={newUrl}
        alt=""
        onLoad={handleImageLoad}
        style={{ display: "none" }}
      />
      <img
        src={supBgImg}
        alt=""
        onLoad={handleImageLoad}
        style={{ display: "none" }}
      />
    </div>
  );
}

export default SupportedGradesCardImage;
