import { configureStore } from "@reduxjs/toolkit";
import translationSlice from "./tr-slice";
import dataFetchedSlice from "./DataFetched";
import checkLoginSlice from "./CheckLogin";
import materialSlice from "./MaterilaSlice";

const store = configureStore({
  reducer: {
    translation: translationSlice.reducer,
    dataFetched: dataFetchedSlice.reducer,
    checkLogin: checkLoginSlice.reducer,
    materialSlice: materialSlice.reducer,
  },
});

export default store;
