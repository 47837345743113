import * as React from "react";
import "./AccountProfileDropDown.css";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Logout from "@mui/icons-material/Logout";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../store/CheckLogin";
import { Link } from "react-router-dom";
// new
import Tr from "../translation/Utils";
/*format date*/
function formatDate(date) {
  const options = {
    day: "numeric",
    month: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  };

  return date.toLocaleString("en-US", options);
}

export default function AccountProfileDropDown(props) {
  const dispatch = useDispatch();
  const loginToken =
    localStorage.getItem("login-token") ??
    sessionStorage.getItem("login-token");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  /**getting the info */
  const [userName, setUserName] = useState("User");
  const userData = useSelector((state) => state.checkLogin.user);
  useEffect(() => {
    if (userData) {
      setUserName(userData?.name);
    }
  }, [userData]);

  /**handle the logout */
  /**send request for the time spent  */
  const [hasReloaded, setHasReloaded] = useState(false);
  function getData() {
    let formData = new FormData();
    formData.append("token", loginToken);
    formData.append("active_time", localStorage.getItem("enterTime"));
    formData.append("inActive_time", formatDate(new Date()));
    const url1 = `${props.url}users/set-time-spent`;
    // alert(localStorage.getItem("enterTime"));
    fetch(url1, {
      method: "POST",
      body: formData,
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(res.statusText);
        }
        setShouldRender(!shouldRender);
        dispatch(logout());
        setHasReloaded(true);
        return res.json();
      })
      .catch((err) => console.log(err));
  }

  /*handle the logout */
  const [shouldRender, setShouldRender] = useState(false);
  const handleLogOut = () => {
    getData();
  };

  // useEffect(() => {
  //   if (hasReloaded) {
  //     setHasReloaded(false);
  //     // window.location.reload();
  //   }
  // }, [isLoggedOut]);

  //refresh the page after loggng out

  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          textAlign: "center",
        }}
      >
        <Tooltip title="Account settings">
          <IconButton
            onClick={handleClick}
            size="small"
            className="nav-profile-image"
            // sx={{ width: "40px", height: "40px" }}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            {userData && userData.image ? (
              <img
                src={userData.image}
                alt="user-pic"
                className="!object-cover user-login-profile"
              />
            ) : (
              <img
                //src={props.user_login_pic}
                src="./img/person-account-img.png"
                alt="user-pic"
                className="user-login-profile"
              />
            )}
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem
          style={{
            textAlign: "center",
            color: "black",
            width: "80%",
            margin: "auto",
            whiteSpace: "normal",
          }}
        >
          {userName}
        </MenuItem>
        <Divider />
        <MenuItem>
          <Link
            to={{
              pathname: "./account-setting",
              state: userData,
            }}
            style={{ textDecoration: "none" }}
          >
            {userData && userData.image ? (
              <img
                src={userData.image}
                alt="user-pic"
                style={{ width: "30px", height: "30px", borderRadius: "50%" }}
                className="float-left"
              />
            ) : (
              <img
                //src={props.user_login_pic}
                src="./img/person-account-img.png"
                alt="user-pic"
                style={{ width: "30px", height: "30px", borderRadius: "50%" }}
                className="float-left"
              />
            )}
            &nbsp; <span>My account</span>
          </Link>
        </MenuItem>

        <MenuItem>
          <Link
            onClick={handleLogOut}
            to={"/home-page"}
            style={{ textDecoration: "none", paddingLeft: "5px" }}
            className=" block w-full"
          >
            <ListItemIcon className="float-left">
              <Logout fontSize="small" />
            </ListItemIcon>
            {Tr("lbl_sign_out")}
          </Link>
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}
