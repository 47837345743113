import React from "react";
import "./CountersComponent.css";
import { useState, useEffect } from "react";
import { CSSTransition } from "react-transition-group";
import Tr from "../translation/Utils";

function CountersComponent(props) {
  const [StudentCount, setStudentCount] = useState(0);
  const [studentAnimationComplete, setStudentAnimationComplete] =
    useState(false);

  useEffect(() => {
    if (!studentAnimationComplete) {
      if (StudentCount < props.studentsCount) {
        setTimeout(() => {
          setStudentCount(StudentCount + 1);
        }, 0.5);
      } else {
        setStudentAnimationComplete(true);
      }
    }
  }, [StudentCount, studentAnimationComplete]);

  const [materialsCount, setMaterialsCount] = useState(0);
  const [materialsAnimationComplete, setMaterialsAnimationComplete] =
    useState(false);

  useEffect(() => {
    if (!materialsAnimationComplete) {
      if (materialsCount < props.materialsCount) {
        setTimeout(() => {
          setMaterialsCount(materialsCount + 1);
        }, 0.3);
      } else {
        setMaterialsAnimationComplete(true);
      }
    }
  }, [materialsCount, materialsAnimationComplete]);

  const [videosCount, setVideosCount] = useState(0);
  const [videosAnimationComplete, setVideosAnimationComplete] = useState(false);

  useEffect(() => {
    if (!videosAnimationComplete) {
      if (videosCount < props.videosCount) {
        setTimeout(() => {
          setVideosCount(videosCount + 1);
        }, 0.3);
      } else {
        setVideosAnimationComplete(true);
      }
    }
  }, [videosCount, videosAnimationComplete]);

  return (
    <div className="counters-component">
      <div className="frame-20">
        <img className="frame-18" src="/img/frame-18@2x.svg" alt="Frame 18" />
        <div className="frame-203">
          <div className="frame-202">
            <div className="text-1 poppins-semi-bold-black-25px">
              <p className="  max-[600px]:text-[10px]">{StudentCount}+</p>
            </div>
          </div>
          <div className="students poppins-semi-bold-black-18px">
            {Tr("lbl_students")}
          </div>
        </div>
      </div>
      <div className="frame-20">
        <img className="frame-18" src="/img/frame-18-2@2x.svg" alt="Frame 18" />
        <div className="frame-203">
          <div className="frame-202-1">
            <div className="text-1 poppins-semi-bold-black-25px">
              <p className=" max-[600px]:text-[10px]">{materialsCount}+</p>
            </div>
          </div>
          <div className="materials poppins-semi-bold-black-18px">
            {Tr("lbl_materials")}
          </div>
        </div>
      </div>
      <div className="frame-20">
        <img className="frame-18" src="/img/frame-18-5@2x.svg" alt="Frame 18" />
        <div className="frame-203">
          <div className="frame-202-2">
            <div className="text-3 poppins-semi-bold-black-25px">
              <p className="  max-[600px]:text-[10px]">{videosCount}+</p>
            </div>
          </div>
          <div className="videos poppins-semi-bold-black-18px">
            {Tr("lbl_videos")}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CountersComponent;
