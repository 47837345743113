import React, { useState, useEffect, useRef } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Form, FormGroup } from "react-bootstrap";
import { Container, Row, Col } from "react-bootstrap";
import "./verification.css";
import "./loginmodal.css";
import "./loginmodal2.css";
import "./loginmodal3.css";
import "./resetPwd.css";
import axios from "axios";
import Swal from "sweetalert2";
import ResetVerificationCode from "./ResetVerificationCode";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "../translationUtils";
import CountryDropDown from "./CountryDropDown";
import { useDispatch } from "react-redux";
import { setFadeLoginModal } from "../../store/CheckLogin";
//new
import TextTR from "../HomePage/TextTR";

function ResetPwd(props) {
  const { classNameName, btnText } = props;

  const { getTranslatedWord } = useTranslation();

  const [showReset, setShowReset] = useState(false);

  const handleResetClose = () => {
    setShowReset(false);
    props.setEndReset(false);
  };
  const handleResetShow = () => setShowReset(true);

  const [phoneNumber, setPhoneNumber] = useState();
  const [countryCode, setCountryCode] = useState("961");
  const [resetVerificationModal, setResetVerificationModal] = useState(false);

  async function handleResetSubmit(event) {
    event.preventDefault();

    if (countryCode.trim().length > 0 && phoneNumber.trim().length > 0) {
      let formData = new FormData();
      formData.append("country_code", countryCode);
      formData.append("phone_number", phoneNumber);

      const response = await axios
        .post(`${props.url}users/forgot-password`, formData)
        .then((res) => {
          //alert("Success");
          if (res.data.succeeded) {
            setResetVerificationModal(true);
          } else {
            Swal.fire({
              icon: "error",
              title: getTranslatedWord(res.data.title),
              text: getTranslatedWord(res.data.messgae),
            });
          }
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: getTranslatedWord(err.messgae),
          });
        });
    } else {
      Swal.fire({
        icon: "warning",
        title: "Empty Fields",
        text: "Please Fill All the Fields!",
      });
    }
  }

  /**for the the reset verification */
  /**to move to the next input when the user enter a charc */
  const inputRefs = [
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
  ];

  function handleInput(event, index) {
    const input = event.target;
    const nextInput = inputRefs[index + 1].current;
    if (input.value.length === 1 && nextInput) {
      nextInput.focus();
    }
  }

  const [code1, setCode1] = useState("");
  const [code2, setCode2] = useState("");
  const [code3, setCode3] = useState("");
  const [code4, setCode4] = useState("");
  const [code5, setCode5] = useState("");
  const [code6, setCode6] = useState("");

  const verifiaction_code = code1 + code2 + code3 + code4 + code5 + code6;
  /**api post request to verify the accont */
  const [verificationData, setVerificationData] = useState();
  const [verState, setVerState] = useState(false);
  const [hasReloaded, setHasReloaded] = useState(false);
  const [verificationSuccess, setVerificationSuccess] = useState(false);

  const countryCodeHandler = (v) => {
    setCountryCode(v);
  };
  async function handleVerifySubmit(event) {
    event.preventDefault();

    let formData = new FormData();
    formData.append("country_code", countryCode);
    formData.append("phone_number", phoneNumber);
    formData.append("code", verifiaction_code);

    const response = await axios
      .post(`${props.url}users/check-reset-code`, formData)
      .then((res) => {
        setVerificationData(res.data);
        if (res.data.succeeded) {
          setVerificationSuccess(true);
        } else {
          Swal.fire({
            icon: "error",
            title: getTranslatedWord(res.data.title),
            text: getTranslatedWord(res.data.messgae),
          });
        }
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: getTranslatedWord(err.messgae),
        });
      });
  }

  /**for responsivity */
  const isMobile = useMediaQuery({ query: "(max-width:650px)" });

  /**check for modal fading  */
  const dispatch = useDispatch();
  useEffect(() => {
    if (!showReset) {
      dispatch(setFadeLoginModal(false));
    }
  }, [showReset]);

  return (
    <>
      <Button
        variant="primary"
        onClick={handleResetShow}
        classNameName={classNameName}
      >
        {btnText}
      </Button>

      <Modal
        size={"lg"}
        show={showReset}
        dialogclassNameName="modal-90w"
        onHide={handleResetClose}
        keyboard={false}
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              {isMobile ? null : (
                <Col xs={12} md={6}>
                  <div className="ver-image"></div>
                </Col>
              )}

              {!resetVerificationModal ? (
                <Col
                  xs={12}
                  md={6}
                  style={{ display: "flex !important", alignItems: "center" }}
                >
                  <div className="reset-form-container">
                    <div className="reset-form-container-title">
                      <h3>
                        <TextTR text={"lbl_reset_pwd"} />
                      </h3>
                    </div>

                    <Form.Group controlId="formBasicEmail">
                      <Form.Label className="bold-text">
                        <TextTR text={"lbl_phone"} />
                      </Form.Label>
                      <Row>
                        <Col xs={3} style={{ paddingRight: "0px" }}>
                          <CountryDropDown setValue={countryCodeHandler} />
                        </Col>
                        <Col xs={9}>
                          <Form.Control
                            type="text"
                            placeholder={"Phone Number"}
                            value={phoneNumber}
                            onChange={(event) =>
                              setPhoneNumber(event.target.value)
                            }
                          />
                        </Col>
                      </Row>
                    </Form.Group>

                    <FormGroup style={{ width: "80%" }}>
                      {!!phoneNumber ? (
                        <Button
                          onClick={handleResetSubmit}
                          style={{ background: "#0b5ed7" }}
                        >
                          <TextTR text={"lbl_reset_pwd"} />
                        </Button>
                      ) : (
                        <Button
                          // onClick={handleResetSubmit}
                          style={{ background: "#ccc" }}
                        >
                          <TextTR text={"lbl_reset_pwd"} />
                        </Button>
                      )}
                    </FormGroup>
                  </div>
                </Col>
              ) : (
                <ResetVerificationCode
                  url={props.url}
                  phoneNumber={phoneNumber}
                  countryCode={countryCode}
                />
              )}
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ResetPwd;
