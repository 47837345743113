import React from "react";
import "./CardLocationTableSection.css";
import Table from "react-bootstrap/Table";
import { useState, useEffect } from "react";

function CardLocationTableSection(props) {
  const [librariesData, setLibrariesData] = useState([]);
  useEffect(() => {
    if (props.homeData && props.homeData.libraries) {
      setLibrariesData(props.homeData.libraries);
    }
  }, [props]);

  /*to specify the city so that we can add more than 1 library to the same city */
  const groupedLibraries = librariesData?.reduce((result, library) => {
    (result[library.city] = result[library.city] || []).push(library);
    return result;
  }, {});

  return (
    <>
      {librariesData != "" && (
        <Table striped bordered hover className="point-of-sales-table">
          <thead className="point-of-sale-head">
            <tr>
              <th>Phone Number</th>
              <th>Location</th>
              <th>Library</th>
              <th colSpan={2}>City</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(groupedLibraries).map((city) => {
              const cityLibraries = groupedLibraries[city];
              return cityLibraries.map((library, index) => (
                <tr key={index}>
                  <td>{library.phone_number}</td>
                  <td>{library.address}</td>
                  <td>{library.library}</td>
                  {index === 0 ? (
                    <td rowSpan={cityLibraries.length}>{index + 1}</td>
                  ) : null}
                  {index === 0 ? (
                    <td colSpan={2} rowSpan={cityLibraries.length}>
                      {city}
                    </td>
                  ) : null}
                </tr>
              ));
            })}
          </tbody>
        </Table>
      )}
      {/* <Table
        striped
        bordered
        hover
        className='point-of-sales-table'
      >
        <thead className='point-of-sale-head'>
          <tr>
            <th>Phone Number</th>
            <th>Location</th>
            <th>Library</th>
            <th colSpan={2}>City</th>
          </tr>
        </thead>
        <tbody></tbody>
      </Table> */}

      {/* <tbody>
          <tr>
            <td></td>
            <td></td>
            <td>الإتحاد</td>
            <td>1</td>
            <td>صــور</td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td>كاعين</td>
            <td>2</td>
            <td>الشهابية</td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td>الزهراء</td>
            <td>3</td>
            <td>صيدا</td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td>الأجيال</td>
            <td>4</td>
            <td>عكـار</td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td>حسن</td>
            <td>5</td>
            <td>النبطية</td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td>عسيران</td>
            <td>6</td>
            <td>تبنين</td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td>زيـاد</td>
            <td>7</td>
            <td>جب جنين</td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td>عكار الحديثة</td>
            <td>8</td>
            <td>برالياس</td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td>فـرح</td>
            <td>9</td>
            <td>الشوف</td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td>حمود</td>
            <td>10</td>
            <td>شتورة</td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td>الدسوقي</td>
            <td>11</td>
            <td>عاليه</td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td>عصام</td>
            <td>12</td>
            <td>صــور</td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td>نـبـيـه</td>
            <td>13</td>
            <td>صيدا</td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td>سمير بري</td>
            <td>14</td>
            <td>الشهابية</td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td>Brains</td>
            <td>15</td>
            <td>عاليه</td>
          </tr>
        </tbody> */}
    </>

    // <div className="card-location-table-section" id="card-location-table-section">
    //   <div className="where-to-find-our-card-title-and-lines">
    //     <img className="line-31-stroke-3" src="/img/line-31--stroke--13@2x.svg" alt="Line 31 (Stroke)" />
    //     <p className="where-to-find-our-cards valign-text-middle poppins-semi-bold-black-35px">
    //       Where to find our cards
    //     </p>
    //     <img className="line-31-stroke-3" src="/img/line-31--stroke--13@2x.svg" alt="Line 31 (Stroke)" />
    //   </div>
    //   <div className="card-location-table">
    //     <div className="overlap-group1-1">
    //       <div className="overlap-group-2">
    //         <div className="rectangle-2"></div>
    //         <div className="rectangle-3"></div>
    //         <div className="rectangle-4"></div>
    //         <div className="rectangle-5"></div>
    //         <div className="rectangle-6"></div>
    //         <div className="rectangle-7"></div>
    //         <div className="rectangle-9"></div>
    //         <div className="rectangle-10"></div>
    //         <div className="rectangle-11"></div>
    //         <div className="rectangle-8"></div>
    //         <div className="frame-363"></div>
    //         <img className="line-23-stroke" src="/img/line-23--stroke-@1x.svg" alt="Line 23 (Stroke)" />
    //         <div className="city poppins-semi-bold-white-14px">City</div>
    //         <div className="text-4-1 poppins-semi-bold-black-14px">صــور</div>
    //         <div className="group-146">
    //           <div className="text-5-1 poppins-semi-bold-black-14px">الإتحاد</div>
    //           <div className="text-2 poppins-semi-bold-black-14px">كاعين</div>
    //           <div className="text-7-1 poppins-semi-bold-black-14px">الزهراء</div>
    //           <div className="text-4 poppins-semi-bold-white-14px">الأجيال</div>
    //           <div className="text-5 poppins-semi-bold-black-14px">حسن</div>
    //           <div className="text-10 poppins-semi-bold-black-14px">عسيران</div>
    //           <div className="text-6 poppins-semi-bold-black-14px">زيـاد</div>
    //           <div className="text-12 poppins-semi-bold-white-14px">عكار الحديثة</div>
    //           <div className="text-6 poppins-semi-bold-black-14px">فـرح</div>
    //           <div className="text-7 poppins-semi-bold-white-14px">حمود</div>
    //           <div className="text-15 poppins-semi-bold-black-14px">الدسوقي</div>
    //           <div className="text-16 poppins-semi-bold-white-14px">عصام</div>
    //           <div className="text-2 poppins-semi-bold-black-14px">نـبـيـه</div>
    //           <div className="text-18 poppins-semi-bold-white-14px">سمير بري</div>
    //           <div className="place-2 poppins-semi-bold-black-14px">Brains</div>
    //           <div className="text-5 poppins-semi-bold-white-14px">الكوثر</div>
    //           <div className="text-7 poppins-semi-bold-black-14px">الربيع</div>
    //           <div className="text-21 poppins-semi-bold-white-14px">دار المستقبل</div>
    //           <div className="text-2-1 poppins-semi-bold-white-14px">دار الشمال</div>
    //           <div className="text-2-1 poppins-semi-bold-black-14px">الباردارجون</div>
    //           <div className="text-4 poppins-semi-bold-black-14px">شمص</div>
    //           <div className="text-7 poppins-semi-bold-black-14px">نادين</div>
    //           <div className="text-26 poppins-semi-bold-black-14px">جبران</div>
    //           <div className="text-27 poppins-semi-bold-black-14px">السلام</div>
    //           <div className="text-2 poppins-semi-bold-black-14px">الغدير</div>
    //           <div className="text-29 poppins-semi-bold-black-14px">ألان عون</div>
    //           <div className="text-3-1 poppins-semi-bold-black-14px">اسامة</div>
    //           <div className="text-3-1 poppins-semi-bold-black-14px">عاصي</div>
    //           <div className="text-6 poppins-semi-bold-black-14px">نبيل</div>
    //           <div className="text-4 poppins-semi-bold-black-14px">العلمية</div>
    //           <div className="text-2 poppins-semi-bold-black-14px">ضاوي</div>
    //           <div className="text-2 poppins-semi-bold-black-14px">الحرية</div>
    //           <div className="ua-acadam poppins-semi-bold-black-14px">UA Acadam</div>
    //           <div className="text-2 poppins-semi-bold-black-14px">الأيتام</div>
    //           <div className="text-37 poppins-semi-bold-black-14px">رأس النبع</div>
    //         </div>
    //         <div className="text-38 poppins-semi-bold-white-14px">الشهابية</div>
    //         <div className="text-39 poppins-semi-bold-black-14px">صيدا</div>
    //         <div className="text-40 poppins-semi-bold-white-14px">عكـار</div>
    //         <div className="text-41 poppins-semi-bold-black-14px">النبطية</div>
    //         <div className="text-42 poppins-semi-bold-white-14px">تبنين</div>
    //         <div className="text-43 poppins-semi-bold-black-14px">جب جنين</div>
    //         <div className="text-44 poppins-semi-bold-white-14px">برالياس</div>
    //         <div className="text-45 poppins-semi-bold-black-14px">الشوف</div>
    //         <div className="text-46 poppins-semi-bold-white-14px">شتورة</div>
    //         <div className="text-47 poppins-semi-bold-black-14px">عاليه</div>
    //         <div className="text-48 poppins-semi-bold-white-14px">الصرفند</div>
    //         <div className="text-49 poppins-semi-bold-white-14px">طرابلس</div>
    //         <div className="text-50 poppins-semi-bold-black-14px">عرمون</div>
    //         <div className="text-51 poppins-semi-bold-black-14px">بعلبك</div>
    //         <div className="text-53 poppins-semi-bold-black-14px">راشيا</div>
    //         <div className="text-54 poppins-semi-bold-black-14px">الهرمل</div>
    //         <div className="text-55 poppins-semi-bold-black-14px">بيروت</div>
    //         <div className="text-56 poppins-semi-bold-black-14px">إقليم الخروب</div>
    //         <div className="group-145 poppins-semi-bold-black-14px">
    //           <div className="number-6">1</div>
    //           <div className="number">2</div>
    //           <div className="number">3</div>
    //           <div className="number-1">4</div>
    //           <div className="number-1">5</div>
    //           <div className="number-1">6</div>
    //           <div className="number">7</div>
    //           <div className="number-1">8</div>
    //           <div className="number-1">9</div>
    //           <div className="number-2">10</div>
    //           <div className="number-7">11</div>
    //           <div className="number-3">12</div>
    //           <div className="number-3">13</div>
    //           <div className="number-2">14</div>
    //           <div className="number-2">15</div>
    //           <div className="number-2">16</div>
    //           <div className="number-3">17</div>
    //           <div className="number-2">18</div>
    //           <div className="number-3">19</div>
    //           <div className="number-4">20</div>
    //           <div className="number-3">21</div>
    //           <div className="number-5">22</div>
    //           <div className="number-5">23</div>
    //           <div className="number-4">24</div>
    //           <div className="number-4">25</div>
    //           <div className="number-4">26</div>
    //           <div className="number-5">27</div>
    //           <div className="number-4">28</div>
    //           <div className="number-4">29</div>
    //           <div className="number-4">30</div>
    //           <div className="number-3">31</div>
    //           <div className="number-5">32</div>
    //           <div className="number-5">33</div>
    //           <div className="number-4">34</div>
    //           <div className="number-4">35</div>
    //         </div>
    //         <div className="library poppins-semi-bold-white-14px">Library</div>
    //         <div className="location poppins-semi-bold-white-14px">Location</div>
    //         <div className="phone-number-1 poppins-semi-bold-white-14px">Phone Number</div>
    //         <img className="line-23-stroke-1" src="/img/line-23--stroke--1@1x.svg" alt="Line 23 (Stroke)" />
    //         <img className="line-23-stroke-2" src="/img/line-23--stroke--2@1x.svg" alt="Line 23 (Stroke)" />
    //         <img className="line-23-stroke-3" src="/img/line-23--stroke--3@1x.svg" alt="Line 23 (Stroke)" />
    //         <img className="line-23-stroke-4" src="/img/line-23--stroke--4@1x.svg" alt="Line 23 (Stroke)" />
    //         <img className="line-23-stroke-5" src="/img/line-23--stroke--5@1x.svg" alt="Line 23 (Stroke)" />
    //         <img className="line-23-stroke-6" src="/img/line-23--stroke--6@1x.svg" alt="Line 23 (Stroke)" />
    //         <img className="line-23-stroke-7" src="/img/line-23--stroke--7@1x.svg" alt="Line 23 (Stroke)" />
    //         <img className="line-23-stroke-8" src="/img/line-23--stroke--8@1x.svg" alt="Line 23 (Stroke)" />
    //         <img className="line-23-stroke-9" src="/img/line-23--stroke--10@1x.svg" alt="Line 23 (Stroke)" />
    //         <img className="line-23-stroke-10" src="/img/line-23--stroke--10@1x.svg" alt="Line 23 (Stroke)" />
    //         <img className="line-23-stroke-11" src="/img/line-23--stroke--11@1x.svg" alt="Line 23 (Stroke)" />
    //         <img className="line-23-stroke-12" src="/img/line-23--stroke--13@1x.svg" alt="Line 23 (Stroke)" />
    //         <img className="line-23-stroke-13" src="/img/line-23--stroke--13@1x.svg" alt="Line 23 (Stroke)" />
    //         <img className="line-23-stroke-14" src="/img/line-23--stroke--14@1x.svg" alt="Line 23 (Stroke)" />
    //         <img className="line-23-stroke-15" src="/img/line-23--stroke--16@1x.svg" alt="Line 23 (Stroke)" />
    //         <img className="line-23-stroke-16" src="/img/line-23--stroke--16@1x.svg" alt="Line 23 (Stroke)" />
    //         <img className="line-23-stroke-17" src="/img/line-23--stroke--17@1x.svg" alt="Line 23 (Stroke)" />
    //         <img className="line-23-stroke-18" src="/img/line-23--stroke--19@1x.svg" alt="Line 23 (Stroke)" />
    //         <img className="line-23-stroke-19" src="/img/line-23--stroke--19@1x.svg" alt="Line 23 (Stroke)" />
    //         <img className="line-23-stroke-20" src="/img/line-23--stroke--20@1x.svg" alt="Line 23 (Stroke)" />
    //         <img className="line-23-stroke-21" src="/img/line-23--stroke--21@1x.svg" alt="Line 23 (Stroke)" />
    //         <img className="line-23-stroke-22" src="/img/line-23--stroke--22@1x.svg" alt="Line 23 (Stroke)" />
    //         <img className="line-23-stroke-23" src="/img/line-23--stroke--23@1x.svg" alt="Line 23 (Stroke)" />
    //         <img className="line-23-stroke-24" src="/img/line-23--stroke--25@1x.svg" alt="Line 23 (Stroke)" />
    //         <img className="line-23-stroke-25" src="/img/line-23--stroke--25@1x.svg" alt="Line 23 (Stroke)" />
    //         <img className="line-23-stroke-26" src="/img/line-23--stroke--27@1x.svg" alt="Line 23 (Stroke)" />
    //         <img className="line-23-stroke-27" src="/img/line-23--stroke--27@1x.svg" alt="Line 23 (Stroke)" />
    //         <img className="line-23-stroke-28" src="/img/line-23--stroke--28@1x.svg" alt="Line 23 (Stroke)" />
    //         <img className="line-23-stroke-29" src="/img/line-23--stroke--30@1x.svg" alt="Line 23 (Stroke)" />
    //         <img className="line-23-stroke-30" src="/img/line-23--stroke--31@1x.svg" alt="Line 23 (Stroke)" />
    //         <img className="line-23-stroke-31" src="/img/line-23--stroke--31@1x.svg" alt="Line 23 (Stroke)" />
    //         <img className="line-23-stroke-32" src="/img/line-23--stroke--33@1x.svg" alt="Line 23 (Stroke)" />
    //         <img className="line-23-stroke-33" src="/img/line-23--stroke--33@1x.svg" alt="Line 23 (Stroke)" />
    //         <img className="line-23-stroke-34" src="/img/line-23--stroke--35@1x.svg" alt="Line 23 (Stroke)" />
    //         <img className="line-24" src="/img/line-24-1@1x.svg" alt="Line 24" />
    //         <img className="line-29" src="/img/line-29-1@1x.svg" alt="Line 29" />
    //         <img className="line-26" src="/img/line-26-1@1x.svg" alt="Line 26" />
    //         <img className="line-28" src="/img/line-28@1x.svg" alt="Line 28" />
    //       </div>
    //       <div className="text-52 poppins-semi-bold-black-14px">زحلة</div>
    //     </div>
    //   </div>
    // </div>
  );
}

export default CardLocationTableSection;
