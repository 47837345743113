import React, { useEffect } from "react";
import Step1 from "../Step1";
import "./HowItWorksSectionWithBG.css";
import { translationAction } from "../../store/tr-slice";
// import { useSelector, useDispatch } from "react-redux";
import Tr from "../translation/Utils.js";

function HowItWorksSectionWithBG(props) {
  // const dispatch = useDispatch();
  const {
    overlapGroup2,
    step11Props,
    step12Props,
    step13Props,
    step14Props,
    step15Props,
    step16Props,
  } = props;

  // useEffect(() => {
  //   dispatch(translationAction.tr("yaser"));
  // }, []);

  return (
    <div className="how-it-works-section-with-bg">
      <div
        className="overlap-group2"
        style={{ backgroundImage: `url(${overlapGroup2})` }}
      >
        <div className="how-it-works-title-and-lines">
          <img
            className="line-31-stroke-4"
            src="/img/line-31--stroke--7@2x.svg"
            alt="Line 31 (Stroke)"
          />
          <div className="how-it-works valign-text-middle poppins-semi-bold-white-35px max-[600px]:text-lg">
            {Tr("lbl_how_it_works")}
          </div>
          <img
            className="line-31-stroke-4"
            src="/img/line-31--stroke--7@2x.svg"
            alt="Line 31 (Stroke)"
          />
        </div>
        <p className="here-you-can-know-how-school-tube-works valign-text-middle max-[600px]:text-sm">
          {Tr("msg_here_you_can_know")}
        </p>
        <div className="step-container-1">
          {/* mapping starts here */}
          {props.how_it_works &&
            props.how_it_works.map((item, index) => {
              return (
                <Step1
                  key={index}
                  x1BuySchooltubeCards={Tr(item.title)}
                  buyOurCardsFromO={Tr(item.body)}
                />
              );
            })}
          {/* mapping ends here */}
        </div>
      </div>
    </div>
  );
}

export default HowItWorksSectionWithBG;
