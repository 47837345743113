import React from "react";
import "./TitleLines.css";

function TeacherTitleLines(props) {
  return (
    <div className={`teachers-title-lines ${props.className}`}>
      <div className='teacher-line-33' />
    </div>
  );
}

export default TeacherTitleLines;
