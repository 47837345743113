import React from "react";
import { Helmet } from "react-helmet";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import UnlockPowerOfStudySection from "../UnlockPowerOfStudySection";
import "./Teachers.css";
import TeacherTitleLines from "../TitleLines/TeacherTitleLines";
import TeacherCard from "../TeacherCard/TeacherCard";
import TextTR from "../HomePage/TextTR";
import { setNotificationDrawer } from "../../store/CheckLogin";
import { useDispatch } from "react-redux";
import { URL } from "../../static/data/config";

function Teachers(props) {
  const { unlockPowerOfStudySectionProps } = props;

  //const image_url = "http://d2lb7gsppnsll7.cloudfront.net/schooltube/uploads/";
  const [getTeachersData, setTeachersData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  function getData() {
    let formData = new FormData();
    formData.append("token", localStorage.getItem("token"));
    const url1 = `${URL}side/teachers`;
    fetch(url1, {
      method: "POST",
      body: formData,
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(res.statusText);
        }
        return res.json();
      })
      .then((res) => {
        setTeachersData(res);
        setIsLoading(false);
      })
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    getData();
  }, []);

  const dispatch = useDispatch();

  return (
    <>
      <div
        className="my-learning-container h-[100%]"
        // style={{ backgroundImage: "url(./img/bg-image-tr.png)" }}
        onClick={() => {
          dispatch(setNotificationDrawer(false));
        }}
      >
        <Helmet>
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <title>Teachers</title>
          <meta
            name="description"
            content={`Learn more about our experienced and knowledgeable teacher on Schooltube.`}
          />
        </Helmet>
        {/* <Navbar
          url={url}
          //image_url={image_url}
        /> */}
        <div
          className="teachers-main-container h-[100%]"
          // style={
          //   getTeachersData?.teachers?.length > 0
          //     ? { height: "60vh", position: "relative" }
          //     : null
          // }
          style={{ position: "relative" }}
        >
          {/* <QuizBgImage /> */}

          <div className="title-line-container">
            <div className="all-grades-1 valign-text-middle poppins-semi-bold-black-25px">
              <TextTR text={"lbl_teachers"} />
            </div>
            <TeacherTitleLines />
          </div>
          <div className="teachers-cards-container h-100">
            {isLoading ? (
              <div className="home-loading">
                <img src="./img/all-grade-loading.gif" alt="loading" />
              </div>
            ) : (
              getTeachersData &&
              getTeachersData?.teachers?.map((teacherItem, teacherIndex) => {
                let teacherImg = "./img/teachers-imgs/teacher_image.PNG";
                if (teacherItem.image) {
                  teacherImg = teacherItem.image;
                }
                const teacherName =
                  teacherItem.first_name + " " + teacherItem.last_name;
                const teacherDescription = teacherItem.description;
                const teacherLink = teacherItem.link;

                return (
                  <div
                    key={teacherIndex}
                   
                    className="w-[350px] h-[350px]"
                  >
                    <TeacherCard
                      //  TeacherID = {teacherItem.teacher_id}
                      TeacherName={`
                        ${teacherItem.first_name}  ${teacherItem.last_name}`}
                      TeacherMajor={teacherItem.description}
                      youtubeLink={teacherItem.link}
                      teacherImg={teacherImg}
                      teacherIndex={teacherIndex}
                    />
                  </div>
                );
              })
            )}
            {}
          </div>
        </div>

        {/* <UnlockPowerOfStudySection
          group148={unlockPowerOfStudySectionProps.group148}
          group147={unlockPowerOfStudySectionProps.group147}
          style={{ marginTop: "10px" }}
        /> */}
      </div>
    </>
  );
}

export default Teachers;
