import React from "react";
import GradeImage from "../GradeImage";
import DetailsGroup from "../DetailsGroup";
import "./MyLearninCourse6Card.css";
import { useEffect } from "react";
import Tr from "../translation/Utils";
import TextTR from "../HomePage/TextTR";
import { useMediaQuery } from "react-responsive";

function MyLearninCourse6Card(props) {
  const {
    gradeLevelImg,
    className,
    bgimage,
    gradeLevelText,
    materialsCount,
    videoCount,
    pdfCount,
    access_expiry_date,
    displayExp,
    quizesCount,
  } = props;

  const isMobile = useMediaQuery({ query: "(max-width:760px)" });

  /**...TO FORMAT THE DATE */

  const dateObject = new Date(access_expiry_date);
  const year = dateObject.getFullYear();
  const month = String(dateObject.getMonth() + 1).padStart(2, "0");
  const day = String(dateObject.getDate()).padStart(2, "0");
  const expiry_date = `${year}-${month}-${day}`;

  return (
    <div className="my-learnin-course-6-card-wrapper">
      <div
        className={` p-1 my-learnin-course-6-card mb-[10px] ${
          className || ""
        } `}
      >
        <GradeImage gradeLevelImg={gradeLevelImg} bgimage={bgimage} />
        <TextTR
          className={`grade-3 poppins-semi-bold-blue-14px lg:text-base  ${
            isMobile
              ? gradeLevelText.length > 15
                ? `text-[9px]`
                : `!text-base`
              : gradeLevelText.length > 15
              ? `text-[12px]`
              : `!text-base`
          }`}
          text={gradeLevelText}
        />

        {/* <div className='grade-3 poppins-semi-bold-blue-14px'>
        {gradeLevelText}
      </div> */}
        <DetailsGroup
          materialsCount={`${materialsCount} 	${Tr("lbl_materials")}`}
          videosCount={`${videoCount} ${Tr("lbl_videos")}`}
          pdfCount={`${pdfCount == 0 ? "" : pdfCount} ${Tr("lbl_pdf")}`}
          quizesCount={`${quizesCount == 0 ? "" : quizesCount} 	${Tr(
            "lbl_quizzes"
          )}`}
        />
        {access_expiry_date ? (
          <p
            className={`!text-[11px] sm:!text-[16px] text-red-700 ml-[9.29px] !mt-0 sm:!mt-2 font-semibold ${
              displayExp == 0 ? "datehide" : ""
            }`}
          >
            Expiry Date: {expiry_date}
          </p>
        ) : null}
      </div>
    </div>
  );
}

export default MyLearninCourse6Card;

{
  /* 
  <DetailsGroup
        materialsCount={`${materialsCount} materials`}
        videosCount={`${videoCount} videos`}
        pdfCount={`${pdfCount} pdfs`}
        quizesCount={`${quizesCount} quizes`}
/> */
}
