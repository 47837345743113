import React, { useState, useEffect, useRef } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Form } from "react-bootstrap";
import { Container, Row, Col } from "react-bootstrap";
import "./loginmodal.css";
import "./loginmodal2.css";
import "./loginmodal3.css";
import RegisterModal from "./RegisterModal";
import Bowser from "bowser";
import axios from "axios";
import Swal from "sweetalert2";
import { useSelector, useDispatch } from "react-redux";
import { login, setTableName } from "../../store/CheckLogin";
import VerificationModal from "./VerificationMoadl";
import { useMediaQuery } from "react-responsive";
import CountryDropDown from "./CountryDropDown";
import { useTranslation } from "../translationUtils";
import { ClockLoader } from "react-spinners";
//new
import g from "../../Images/google-g-logo-removebg-preview.png";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import LoginByUser from "./LoginByUser";
import NewUserModal from "./NewUserModal";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import loginImage from "../../Images/login-image.PNG";
import ReCAPTCHA from "react-google-recaptcha";
import TextTR from "../HomePage/TextTR";

function formatDate(date) {
  const options = {
    day: "numeric",
    month: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  };

  return date.toLocaleString("en-US", options);
}

function LoginModal(props) {
  const [rememberMe, setRememberMe] = useState(true);
  const { className, btnText } = props;
  const [Loginshow, setLoginShow] = useState(false);
  const { getTranslatedWord, setLanguage } = useTranslation();
  const dispatch = useDispatch();
  const { isLogIn, isVerified } = useSelector((state) => state.checkLogin);

  //for the login details
  const [parameter, setParameter] = useState("");
  const [countryCode, setCountryCode] = useState("961");
  const [recaptchaResponse, setRecaptchaResponse] = useState();

  //fetching the login with post request
  const [activationError, setActivationError] = useState(false);
  const recaptchaRef = useRef();

  //new
  const [openVerificationModal, setOpenVerificationModal] = useState(false);
  const [loginWithUser, setLoginWithUser] = useState(false);
  const [newUserModal, setNewUserModal] = useState(false);
  const [userName, setUserName] = useState(false);
  const [isGoogleWithPhone, setIsGoogleWithPhone] = useState(false);

  // const firebaseConfig = {
  //   apiKey: "AIzaSyC50_BUVyFZgCP3NfjlRlp7M3ZdvjelaTE",
  //   authDomain: "school-tube-f7c8e.firebaseapp.com",
  //   projectId: "school-tube-f7c8e",
  //   storageBucket: "school-tube-f7c8e.appspot.com",
  //   messagingSenderId: "607249327497",
  //   appId: "1:607249327497:web:9cd086c377444416616d58",
  //   measurementId: "G-QEY96NSFTB",
  // };
  
  const firebaseConfig = {
    apiKey: "AIzaSyC50_BUVyFZgCP3NfjlRlp7M3ZdvjelaTE",
    authDomain: "school-tube-f7c8e.firebaseapp.com",
    projectId: "school-tube-f7c8e",
    storageBucket: "school-tube-f7c8e.appspot.com",
    messagingSenderId: "607249327497",
    appId: "1:607249327497:web:1b6fa52fd66d8b20616d58",
    measurementId: "G-34BMMMBLN3"
  };
  
  // Initialize Firebase
  const provider = new GoogleAuthProvider();
  provider.addScope("https://www.googleapis.com/auth/contacts.readonly");

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);

  const googleSigninHandler = () => {
    const auth = getAuth();
    signInWithPopup(auth, provider)
      .then(async (result) => {
        if (result._tokenResponse) {
          var formData = new FormData();
          formData.append("response", JSON.stringify(result._tokenResponse));
          formData.append("email", result._tokenResponse.email);
          formData.append("id_token", result._tokenResponse.oauthIdToken);
          formData.append("is_web", true);
          formData.append("type", "google");
          formData.append("device_data", JSON.stringify(device_data));
          await axios
            .post(`${props.url}users/login-third-party`, formData)
            .then((res) => {
              if (res.data?.succeeded) {
                if (res.data.user.is_activated === 1) {
                  dispatch(login(res.data.user));
                  localStorage.setItem("login-token", res.data.token);
                  sessionStorage.setItem("login-token", res.data.token);
                  Swal.fire({
                    icon: "success",
                    title: "Login",
                    text: getTranslatedWord(res.data.message),
                  });
                  localStorage.setItem("enterTime", formatDate(new Date()));
                } else if (res.data.user.is_activated === 0) {
                  if (res.data.user.is_normal === 0) {
                    setIsGoogleWithPhone(true);
                    setUserName(res.data.user.name);
                    localStorage.setItem("login-token", res.data.token);
                    setNewUserModal(true);
                  }
                }
                dispatch(setTableName(res.data.table_name));
                localStorage.setItem("loginData", JSON.stringify(res.data));
              } else {
                setOpenVerificationModal(true);
              }
            });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // new, added useEffect
  useEffect(() => {
    if (isVerified) {
      setLoginShow(true);
    }
  }, [isVerified]);

  const handleLoginShow = () => {
    setLoginShow(true);
  };

  const handleLoginClose = () => {
    setLoginShow(false);
  };

  const browser = Bowser.getParser(window.navigator.userAgent);
  const browserString = String(browser.getBrowserName());
  const browserVersion = String(browser.getBrowserVersion());

  const platform = navigator.platform;
  const [platfromName, setPlatFormName] = useState("");

  // new, added useEffect
  useEffect(() => {
    if (platform.includes("Win")) {
      setPlatFormName("Windows");
    } else if (platform.includes("Linux")) {
      setPlatFormName("Linux");
    } else {
      setPlatFormName("Unknown Platform");
    }
  }, [platform]);

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
      setScreenHeight(window.innerHeight);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  //device data
  const device_data = {
    os: "web",
    brand: browserString,
    device: platfromName,
    deviceId: "N/A",
    manufacturer: "N/A",
    model: browserVersion,
    product: "N/A",
    heightPx: screenHeight,
    widthPx: screenWidth,
    isPhysical: 1,
  };

  const [loaderBtn, setLoaderBtn] = useState(false);

  async function handleSubmit(event) {
    event.preventDefault();
    setLoaderBtn(true);
    let formData = new FormData();
    formData.append("country_code", countryCode);
    formData.append("phone_number", parameter);
    formData.append("is_web", true);
    formData.append("recaptcha_response", recaptchaResponse);
    formData.append("device_data", JSON.stringify(device_data));

    await axios
      .post(`${props.url}users/register`, formData)
      .then((res) => {
        if (res.data?.succeeded) {
          // dispatch(login(res.data.user));
          if (rememberMe) {
            localStorage.setItem("login-token", res.data.token);
            sessionStorage.removeItem("login-token");
          } else {
            sessionStorage.setItem("login-token", res.data.token);
            localStorage.removeItem("login-token");
          }
          Swal.fire({
            icon: "success",
            title: "Login",
            text: getTranslatedWord(res.data.message),
          });

          setOpenVerificationModal(true);
          localStorage.setItem("enterTime", formatDate(new Date()));
          dispatch(setTableName(res.data.table_name));
        } else if (
          res.data?.activation_error ||
          res.data?.activation_error === true ||
          res.data?.activation_error == true
        ) {
          setActivationError(true);
          recaptchaRef.current?.reset();
        } else {
          Swal.fire({
            icon: "error",
            title: getTranslatedWord(res.data.title),
            text: getTranslatedWord(res.data.message),
          });
          recaptchaRef.current?.reset();
        }
        setLoaderBtn(false);
      })
      .catch((err) => {
        console.log(err.message);
        recaptchaRef.current?.reset();
      });
  }

  const countryCodeHandler = (v) => {
    setCountryCode(v);
  };

  const handleSwitchChange = () => {
    setRememberMe(!rememberMe);
  };

  const isMobile = useMediaQuery({ query: "(max-width:650px)" });

  const fadeLoginModal = useSelector(
    (state) => state.checkLogin.fadeLoginModal
  );

  const LoginDrawer = useSelector((state) => state.checkLogin.drawerLogin);

  useEffect(() => {
    if (LoginDrawer) {
      setLoginShow(true);
    }
  }, [LoginDrawer]);

  const recaptchaHandler = (response) => {
    setRecaptchaResponse(response);
  };

  return (
    <>
      <Button
        onClick={handleLoginShow}
        className={`${className}`}
        style={props.style}
      >
        <TextTR
          text={btnText}
          className="!w-[50px] md:!w-full !overflow-hidden !text-ellipsis !whitespace-nowrap"
        />
      </Button>

      <Modal
        size={"lg"}
        show={Loginshow}
        dialogClassName="modal-90w"
        onHide={handleLoginClose}
        backdrop="static"
        keyboard={false}
        centered
        style={fadeLoginModal ? { opacity: "0" } : { opacity: "1" }}
      >
        <Modal.Header className="!border-b-0" closeButton>
          {loginWithUser && (
            <div
              className="!absolute !left-0 sm:!left-5 !top-0 sm:!top-2 !text-blue-400 !cursor-pointer"
              onClick={() => setLoginWithUser(false)}
            >
              <ArrowBackIcon className="!text-[25px]" />
            </div>
          )}
          {
            isMobile ? (
              <Modal.Title>
                {" "}
                <div className="!whitespace-nowrap welcome-back valign-text-middle poppins-semi-bold-blue-24px">
                  {/* Welcome Back */}
                  <TextTR text={"lbl_welcome_back"} />
                  {/* <CloseIcon style={{ float: "left" }} /> */}
                </div>
              </Modal.Title>
            ) : null
            // <CloseIcon style={{ float: "left" }} />
          }
        </Modal.Header>
        <Modal.Body>
          {isLogIn ? (
            <>
              {" "}
              <Container>
                <Row className="!relative">
                  {isMobile ? null : (
                    <Col xs={12} md={6}>
                      <div
                        className="image"
                        style={{
                          backgroundImage: `url(${loginImage})`,
                        }}
                      ></div>
                    </Col>
                  )}

                  {newUserModal ? (
                    <NewUserModal
                      url={props.url}
                      userName={userName}
                      isPhone={isGoogleWithPhone}
                    />
                  ) : loginWithUser ? (
                    <LoginByUser url={props.url} device_data={device_data} />
                  ) : activationError || openVerificationModal ? (
                    <VerificationModal
                      url={props.url}
                      countryCode={countryCode}
                      phoneNumber={parameter}
                      device_data={device_data}
                    />
                  ) : (
                    <Col xs={12} md={6}>
                      <div className="flex-col-login">
                        {isMobile ? null : (
                          <div className="!whitespace-nowrap welcome-back valign-text-middle poppins-semi-bold-blue-24px">
                            <TextTR text={"lbl_welcome_back"} />
                          </div>
                        )}
                        <p className="welcome-back-please-enter-you-details poppins-normal-black-13px">
                          <TextTR text={"lbl_welcome_enter_detail"} />
                        </p>
                        <Form
                          className="form-container"
                          onSubmit={handleSubmit}
                        >
                          <Form.Group className="!w-full">
                            <Form.Label className="bold-text">
                              <TextTR text={"lbl_phone"} />
                            </Form.Label>
                            <Row>
                              <Col
                                xs={isMobile ? 4 : 3}
                                style={
                                  isMobile ? null : { paddingRight: "0px" }
                                }
                              >
                                <CountryDropDown
                                  setValue={countryCodeHandler}
                                />
                              </Col>
                              <Col
                                xs={isMobile ? 8 : 9}
                                style={isMobile ? { paddingLeft: "0px" } : null}
                              >
                                <Form.Control
                                  type="text"
                                  placeholder={"Phone number"}
                                  value={parameter}
                                  onChange={(event) =>
                                    setParameter(event.target.value)
                                  }
                                />
                              </Col>
                            </Row>
                          </Form.Group>

                          <div className="kepp-me-logged-in">
                            <Form.Check
                              type="switch"
                              id="custom-switch"
                              label={<TextTR text={"lbl_remember_me"} />}
                              checked={rememberMe}
                              onChange={handleSwitchChange}
                            />
                          </div>

                          <div className="md:!m-auto">
                            <ReCAPTCHA
                              onChange={recaptchaHandler}
                              sitekey="6Lc8QxclAAAAAEsSew-mIQuH8IZ2d_ZgkIJnUZ8b"
                              ref={recaptchaRef}
                              className="!w-full"
                            />
                          </div>

                          <Button
                            variant="primary"
                            type="submit"
                            disabled={loaderBtn}
                            className="!mt-2"
                            style={{ backgroundColor: "#0d6efd" }}
                          >
                            {loaderBtn ? (
                              <ClockLoader color="#fff" size={"20px"} />
                            ) : (
                              <TextTR text={"lbl_sign"} />
                            )}
                          </Button>

                          <Button
                            className="!mt-2 !bg-white !font-semibold !text-[#0d6efd]
                          hover:!text-white hover:!bg-[#0d6efd] !flex !items-center !justify-center"
                            onClick={googleSigninHandler}
                          >
                            <img
                              src={g}
                              className="!h-7 !w-7 !mr-2 !object-contain"
                              alt="sign in with google"
                            />
                            <TextTR text={"Sign in with google"} />
                          </Button>
                          <Button
                            className="!my-2 !bg-white !font-semibold !text-[#0d6efd]
                          hover:!text-white hover:!bg-[#0d6efd] !flex !items-center !justify-center"
                            onClick={() => {
                              setLoginWithUser(true);
                            }}
                          >
                            <AccountBoxIcon className="!text-[30px] !mr-2" />
                            <TextTR text={"Sign in with username"} />
                          </Button>
                          <br></br>
                        </Form>
                      </div>
                    </Col>
                  )}
                </Row>
              </Container>
            </>
          ) : (
            <RegisterModal url={props.url} device_data={device_data} />
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}

export default LoginModal;