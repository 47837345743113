import React from "react";
import BaseCheckbox from "../BaseCheckbox";
import "./Frame300.css";

function Frame300(props) {
  const { math, baseCheckboxProps } = props;

  return (
    <div className="frame-30">
      <BaseCheckbox className={baseCheckboxProps.className} />
      <div className="math-7 poppins-medium-black-18px">{math}</div>
    </div>
  );
}

export default Frame300;
