import React from "react";
import Frame299 from "../Frame299";
import Frame300 from "../Frame300";
import Group52 from "../Group52";
import "./BuyGradesDropdown.css";

function BuyGradesDropdown(props) {
  const {
    frame299Props,
    frame3001Props,
    frame3002Props,
    frame3003Props,
    frame3004Props,
    frame3005Props,
    group521Props,
    group522Props,
    group523Props,
    group524Props,
  } = props;

  return (
    <div className="buy-grades-dropdown">
      <div className="group-57">
        <div className="overlap-group-16">
          <div className="group-51-1">
            <Frame299 grade6={frame299Props.grade6} baseCheckboxProps={frame299Props.baseCheckboxProps} />
            <img className="arrow-down-2-2" src="/img/arrow---down-2-2@2x.svg" alt="Arrow - Down 2 2" />
          </div>
          <div className="frame-305">
            <Frame300 math={frame3001Props.math} baseCheckboxProps={frame3001Props.baseCheckboxProps} />
            <Frame300 math={frame3002Props.math} baseCheckboxProps={frame3002Props.baseCheckboxProps} />
            <Frame300 math={frame3003Props.math} baseCheckboxProps={frame3003Props.baseCheckboxProps} />
            <Frame300 math={frame3004Props.math} baseCheckboxProps={frame3004Props.baseCheckboxProps} />
            <Frame300 math={frame3005Props.math} baseCheckboxProps={frame3005Props.baseCheckboxProps} />
          </div>
        </div>
      </div>
      <img className="line-1-7" src="/img/line-15@1x.svg" alt="Line 15" />
      <Group52 frame299Props={group521Props.frame299Props} />
      <img className="line-1-7" src="/img/line-15@1x.svg" alt="Line 16" />
      <Group52 frame299Props={group522Props.frame299Props} />
      <img className="line-1-7" src="/img/line-15@1x.svg" alt="Line 17" />
      <Group52 frame299Props={group523Props.frame299Props} />
      <img className="line-1-7" src="/img/line-15@1x.svg" alt="Line 18" />
      <Group52 frame299Props={group524Props.frame299Props} />
      <img className="line-19" src="/img/line-15@1x.svg" alt="Line 19" />
    </div>
  );
}

export default BuyGradesDropdown;
