import React, { useEffect, useState } from "react";
import { useLayoutEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { useRef } from "react";
import MyLearninCourse6Card from "../MyLearninCourse6Card";
import UnlockPowerOfStudySection from "../UnlockPowerOfStudySection";
import CustomersDropDown from "../CustomersDropDown";
import "./AllGrades.css";
import TeacherTitleLines from "../TitleLines/TeacherTitleLines";
import TextTR from "../HomePage/TextTR";
import Tr from "../translation/Utils";
import { useDispatch, useSelector } from "react-redux";
import { setNotificationDrawer } from "../../store/CheckLogin";
//new
import Spinner from "react-bootstrap/Spinner";
import { URL } from "../../static/data/config";

function AllGrades(props) {
  const [isScrolling, setIsScrolling] = useState(false);
  const mainDivRef = useRef(null);

  useLayoutEffect(() => {
    if (mainDivRef.current.clientHeight >= 70) {
      setIsScrolling(true);
    }
  }, []);
  const [gradeCards, setGradeCards] = useState([]);
  const dispatch = useDispatch();
  const homeDataGlobal = useSelector((state) => state.dataFetched.homeData);

  function getData() {
    setGradesLoading(true);
    let formData = new FormData();
    formData.append(
      "token",
      localStorage.getItem("login-token") ??
        sessionStorage.getItem("login-token")
    );
    formData.append("is_web", true);
    const url1 = `${URL}side/home-data`;
    fetch(url1, {
      method: "POST",
      body: formData,
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(res.statusText);
        }
        return res.json();
      })
      .then((res) => {
        setGradeCards(res);
        setGrades(res.grades);
        setFilteredGrades(res.grades);
        setGradesLoading(false);
      })
      .catch((err) => console.log(err));
  }

  //new
  const localLoginToken =
    !!localStorage.getItem("login-token") ||
    !!sessionStorage.getItem("login-token");

  const reduxLogin = useSelector((state) => state.checkLogin.isLoggedIn);

  useEffect(() => {
    getData();
  }, [localLoginToken, reduxLogin]);

  useEffect(() => {
    if (homeDataGlobal) {
      setGradeCards(homeDataGlobal);
    } else {
      getData();
    }
  }, []);

  const [countrySelectedValue, setCountrySelectedValue] = useState(null);
  const [languageSelectedValue, setLanguageSelectedValue] = useState(null);
  const [gradeTypeValue, setGradeTypeValue] = useState(null);
  //new
  const [grades, setGrades] = useState([]);
  const [filteredGrades, setFilteredGrades] = useState([]);
  const [gradesLoading, setGradesLoading] = useState(true);

  function handleSelection(selectedValue, type) {
    if (type === "country") {
      setCountrySelectedValue(selectedValue);
    } else if (type === "language") {
      setLanguageSelectedValue(selectedValue);
    } else if (type === "gradeType") {
      setGradeTypeValue(selectedValue);
    }
  }

  /*getting the categ items */
  const categData = [];
  {
    gradeCards.categories &&
      gradeCards.categories.map((categItem) => {
        categData.push(categItem);
      });
  }

  //new
  useEffect(() => {
    setGradesLoading(true);
    if (filteredGrades) {
      //if only country
      if (countrySelectedValue && !languageSelectedValue && !gradeTypeValue) {
        const f = grades.filter((g) => g.r_country_id == countrySelectedValue);
        setFilteredGrades(f);
        setGradesLoading(false);
      } else if (
        // if country and language
        countrySelectedValue &&
        languageSelectedValue &&
        !gradeTypeValue
      ) {
        const f = grades.filter(
          (g) =>
            g.r_country_id == countrySelectedValue &&
            g.r_language_id == languageSelectedValue
        );
        setFilteredGrades(f);
        setGradesLoading(false);
      } else if (
        // if country and type
        countrySelectedValue &&
        !languageSelectedValue &&
        gradeTypeValue
      ) {
        const f = grades.filter(
          (g) =>
            g.r_country_id == countrySelectedValue &&
            g.r_grades_type_id == gradeTypeValue
        );
        setFilteredGrades(f);
        setGradesLoading(false);
      } else if (
        // if all
        countrySelectedValue &&
        languageSelectedValue &&
        gradeTypeValue
      ) {
        const f = grades.filter(
          (g) =>
            g.r_country_id == countrySelectedValue &&
            g.r_language_id == languageSelectedValue &&
            g.r_grades_type_id == gradeTypeValue
        );
        setFilteredGrades(f);
        setGradesLoading(false);
      } else if (
        // if only language
        !countrySelectedValue &&
        languageSelectedValue &&
        !gradeTypeValue
      ) {
        const f = grades.filter(
          (g) => g.r_language_id == languageSelectedValue
        );
        setFilteredGrades(f);
        setGradesLoading(false);
      } else if (
        // if language and type
        !countrySelectedValue &&
        languageSelectedValue &&
        gradeTypeValue
      ) {
        const f = grades.filter(
          (g) =>
            g.r_language_id == languageSelectedValue &&
            g.r_grades_type_id == gradeTypeValue
        );
        setFilteredGrades(f);
        setGradesLoading(false);
      } else if (
        //if only type
        !countrySelectedValue &&
        !languageSelectedValue &&
        gradeTypeValue
      ) {
        const f = grades.filter((g) => g.r_grades_type_id == gradeTypeValue);
        setFilteredGrades(f);
        setGradesLoading(false);
      } else if (
        // if not all
        !countrySelectedValue &&
        !languageSelectedValue &&
        !gradeTypeValue
      ) {
        setFilteredGrades(grades);
        setGradesLoading(false);
      }
      //
    }
  }, [countrySelectedValue, languageSelectedValue, gradeTypeValue]);

  const { unlockPowerOfStudySectionProps, customersDropDown2Props } = props;

  return (
    <>
      <div
        className="my-learning-container"
        onClick={() => {
          dispatch(setNotificationDrawer());
        }}
      >
        <Helmet>
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <title>All Grades</title>
          <meta
            name="description"
            content={`Schooltube offers a user-friendly platform that allows all grades students to access high-quality educational resources quickly and efficiently. Our curated library of materials covers a wide range of subjects and topics, providing students with accurate and reliable information. `}
          />
        </Helmet>
        <div className="container-center-horizontal">
          <div className="my-learning screen all-grade-page">
            <div
              className="overlap-group1-2 min-h-screen"
              style={{ backgroundImage: "url(./img/bg-image-tr.png)" }}
            >
              <div
                className="my-learning-title-section "
                style={{ width: "90%" }}
              >
                <div className="title-redeam-section">
                  <TextTR
                    className="valign-text-middle poppins-semi-bold-black-25px"
                    text={"lbl_all_grades2"}
                  />

                  <div className="drop-down-container">
                    <div className="custom-dropItem dropdown-item1">
                      <CustomersDropDown
                        selectedLabel={Tr("lbl_country")}
                        type="country"
                        countryDropMap={gradeCards.countries}
                        className={customersDropDown2Props.className}
                        onSelection={handleSelection}
                      />
                    </div>
                    <div className="custom-dropItem dropdown-item2">
                      <CustomersDropDown
                        selectedLabel={Tr("filter_language")}
                        type="language"
                        langDropMap={gradeCards.languages}
                        className={customersDropDown2Props.className}
                        onSelection={handleSelection}
                      />
                    </div>
                    <div className="custom-dropItem dropdown-item3">
                      <CustomersDropDown
                        selectedLabel={Tr("filter_course_type")}
                        type="gradeType"
                        gradeTypeDropMap={gradeCards.grade_types}
                        className={customersDropDown2Props.className}
                        onSelection={handleSelection}
                      />
                    </div>
                  </div>
                </div>
                <TeacherTitleLines className="myLearning-title-lines" />
              </div>
              {!gradesLoading && filteredGrades.length > 0 && (
                <div className="!mt-6 !grid !place-items-center !grid-cols-2 !gap-3 sm:!grid-cols-3 lg:!grid-cols-4 2xl:!grid-cols-5">
                  {filteredGrades?.map((gradeCard, index) => (
                    <div key={index}>
                      <Link
                        to={{
                          pathname: `/watch-course`,
                          search: `id=${gradeCard.id}`,
                          state: {
                            categData: categData,
                            gradeTitle: gradeCard.title,
                          },
                        }}
                      >
                        <MyLearninCourse6Card
                          gradeLevelImg={gradeCard.title}
                          gradeLevelText={gradeCard.title}
                          bgimage={gradeCard.image}
                          materialsCount={gradeCard?.materials.length}
                          videoCount={gradeCard.videos_count}
                          pdfCount={gradeCard.pdfs_count}
                          quizesCount={gradeCard.quizzes_count}
                          access_expiry_date={gradeCard.access_expiry_date}
                          displayExp={0}
                        />
                      </Link>
                    </div>
                  ))}
                </div>
              )}
              {gradesLoading && (
                <div className="!grid !place-items-center !m-auto !mt-20">
                  <Spinner animation="border" size="lg" />
                </div>
              )}
              {!gradesLoading && filteredGrades.length === 0 && (
                <div className="!grid !place-items-center !m-auto !mt-20">
                  <Spinner animation="border" size="lg" />
                </div>
              )}
              <div
                ref={mainDivRef}
                className={`grade-scroll ${
                  isScrolling ? "scroll" : ""
                } grade-cards-container`}
                // style={{ width: "90%" }}
              ></div>
            </div>
          </div>
        </div>
        <UnlockPowerOfStudySection
          group148={unlockPowerOfStudySectionProps.group148}
          group147={unlockPowerOfStudySectionProps.group147}
        />
      </div>
    </>
  );
}

export default AllGrades;
