import React from "react";
// import ReactDOM from "react-dom";
import "./globals.css";
import "./styleguide.css";
import App from "./App";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import store from "./store";
import "./index.css";

// ReactDOM.render(<App />, document.getElementById("app"));

createRoot(document.getElementById("app")).render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);
