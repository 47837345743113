import React from "react";
import { useState, useEffect } from "react";
import "./profile-progress.css";
import PropTypes from "prop-types";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Popover from "@mui/material/Popover";
import { BrowserRouter, Link } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setHomeDataGlobal } from "../../store/DataFetched";
import { URL } from "../../static/data/config";

/*=========Function for the progress bar============*/
function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: "20%",
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary">
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}
CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   * @default 0
   */
  value: PropTypes.number.isRequired,
};

function Progress(props) {

  //const image_url = "http://d2lb7gsppnsll7.cloudfront.net/schooltube/uploads/";
  const [getMaterialsData, setMaterialsData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const homeDataGlobal = useSelector((state) => state.dataFetched.homeData);

  function getData() {
    let formData = new FormData();
    formData.append(
      "token",
      localStorage.getItem("login-token") ??
        sessionStorage.getItem("login-token")
    );
    formData.append("is_web", true);
    const url1 = `${URL}side/home-data`;
    fetch(url1, {
      method: "POST",
      body: formData,
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(res.statusText);
        }
        return res.json();
      })
      .then((res) => {
        setMaterialsData(res);
        // console.log("result from getdata is : ", res);
        setIsLoading(false);
      })
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    if (homeDataGlobal) {
      setMaterialsData(homeDataGlobal);
      setIsLoading(false);
      // console.log("redux global");
    } else {
      getData();
      // console.log("not redux global");
    }
  }, []);

  /*Ending fetching data here */

  /*materialize progressbar*/

  const [progress, setProgress] = React.useState(10);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 100 ? 0 : prevProgress + 10
      );
    }, 800);
    return () => {
      clearInterval(timer);
    };
  }, []);

  // /**check if the same material present in more than one grade and prevent displaying it  */
  // const materials = getMaterialsData?.grades?.reduce((acc, grade) => {
  //   grade.materials?.forEach((material) => {
  //     if (!acc.find((m) => m.title === material.title)) {
  //       acc.push(material);
  //     }
  //   });
  //   return acc;
  // }, []);

  /**for the popup onClick */
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedMaterial, setSelectedMaterial] = useState(null);

  const handlePopUpClick = (event, material) => {
    setAnchorEl(event.currentTarget);
    setSelectedMaterial(material);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  /*getting the categ items for the grades in the popover*/
  const categData = [];
  {
    getMaterialsData &&
      getMaterialsData.categories &&
      getMaterialsData.categories.map((categItem) => {
        categData.push(categItem);
      });
    // console.log(categData);
  }

  /**end of the popup*/

  // useEffect(() => {
  //   console.log("materials are: ", getMaterialsData);
  // }, [getMaterialsData]);

  //check if there is access

  const hasAccess = getMaterialsData?.grades?.some(
    (grade) =>
      grade.grade_access ||
      grade.materials?.some((material) => material.material_access)
  );

  // console.log(hasAccess);

  if (isLoading) {
    return (
      <div className="home-loading">
        <img src="./img/all-grade-loading.gif" alt="loading" />
      </div>
    );
  } else {
    return (
      <div>
        <div className="progress-cards-container">
          <BrowserRouter>
            {getMaterialsData?.grades.map((gradeItem, gradeIndex) => {
              if (gradeItem.grade_access) {
                // return <h2>material {gradeItem.materials[2].title} exist</h2>;
                return gradeItem?.materials?.map((material, materialIndex) => {
                  if (material.material_access) {
                    return (
                      <>
                        <div
                          className="progress-card-item"
                          onClick={(event) => handlePopUpClick(event, material)}
                          style={{ cursor: "pointer" }}
                        >
                          <div className="progress-card-item-img-section">
                            {material.image ? (
                              <img
                                src={material.image}
                                alt={`schooltube-${material.title}-image`}
                              />
                            ) : (
                              <img
                                src="./img/grade-image@2x.png"
                                alt={`schooltube-${material.title}-image`}
                              />
                            )}
                          </div>
                          <div className="progress-card-item-content">
                            <span className="progress-card-subject">
                              {material.title}
                            </span>
                            <div className="progress-card-item-progress">
                              <div className="progress-card-item-progress-text">
                                <span>Completed</span>
                                <span className="progress-complete-level">
                                  {material.seen_videos_count}/
                                  {material.videos_count}
                                </span>
                              </div>
                              <div className="progress-card-item-progress-chart">
                                <CircularProgressWithLabel
                                  value={
                                    parseInt(material.videos_count) === 0
                                      ? 0
                                      : (parseInt(material.seen_videos_count) /
                                          parseInt(material.videos_count)) *
                                        100
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <Popover
                          id={id}
                          open={selectedMaterial === material && open}
                          anchorEl={anchorEl}
                          onClose={handleClose}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                          }}
                        >
                          <Typography sx={{ p: 2 }}>
                            <div className="popup-grade-container">
                              <h4>Grades</h4>
                              {material && material.grades_ids.length == 1 ? (
                                <>
                                  {material &&
                                    material.grades_ids?.map(
                                      (
                                        featuredGradeItem,
                                        feauredGradeIndex
                                      ) => {
                                        return (
                                          getMaterialsData &&
                                          getMaterialsData.grades?.map(
                                            (gradeItem, index) => {
                                              if (
                                                gradeItem.id == material ||
                                                gradeItem.id ==
                                                  `${featuredGradeItem}`
                                              ) {
                                                return (
                                                  <Redirect
                                                    to={{
                                                      pathname: "/watch-course",
                                                      search: `id=${gradeItem.id}`,
                                                      state: {
                                                        categData,
                                                        gradeTitle:
                                                          gradeItem.title,
                                                      },
                                                    }}
                                                  />
                                                );
                                              }
                                              return null; // Return null instead of <span>No Grades</span>
                                            }
                                          )
                                        );
                                      }
                                    )}
                                </>
                              ) : (
                                <>
                                  {material &&
                                    material.grades_ids?.map(
                                      (
                                        featuredGradeItem,
                                        feauredGradeIndex
                                      ) => {
                                        return (
                                          getMaterialsData &&
                                          getMaterialsData.grades?.map(
                                            (gradeItem, index) => {
                                              if (
                                                gradeItem.id == material ||
                                                gradeItem.id ==
                                                  `${featuredGradeItem}`
                                              ) {
                                                return (
                                                  <>
                                                    <div
                                                      className="grade-pop-title-container"
                                                      style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                      }}
                                                      key={index}
                                                    >
                                                      <Link
                                                        to={{
                                                          pathname: `/watch-course`,
                                                          search: `id=${gradeItem.id}`,
                                                          state: { categData },
                                                        }}
                                                        style={{
                                                          textDecoration:
                                                            "none",
                                                          background: "#ccc",
                                                          padding: "10px",
                                                          marginTop: "5px",
                                                          borderRadius: "10px",
                                                          textAlign: "center",
                                                        }}
                                                        onClick={() => {
                                                          setTimeout(
                                                            () =>
                                                              window.location.reload(),
                                                            1
                                                          );
                                                        }}
                                                      >
                                                        <span>
                                                          {gradeItem.title}
                                                        </span>
                                                      </Link>
                                                    </div>
                                                  </>
                                                );
                                              }
                                              return null; // Return null instead of <span>No Grades</span>
                                            }
                                          )
                                        );
                                      }
                                    )}
                                </>
                              )}
                              {material &&
                                material.grades_ids?.map(
                                  (featuredGradeItem, feauredGradeIndex) => {
                                    return (
                                      getMaterialsData &&
                                      getMaterialsData.grades?.map(
                                        (gradeItem, index) => {
                                          if (
                                            gradeItem.id == material ||
                                            gradeItem.id ==
                                              `${featuredGradeItem}`
                                          ) {
                                            return (
                                              <>
                                                <div
                                                  className="grade-pop-title-container"
                                                  style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                  }}
                                                  key={index}
                                                >
                                                  <Link
                                                    to={{
                                                      pathname: `/watch-course`,
                                                      search: `id=${gradeItem.id}`,
                                                      state: { categData },
                                                    }}
                                                    style={{
                                                      textDecoration: "none",
                                                      background: "#ccc",
                                                      padding: "10px",
                                                      marginTop: "5px",
                                                      borderRadius: "10px",
                                                      textAlign: "center",
                                                    }}
                                                    onClick={() => {
                                                      setTimeout(
                                                        () =>
                                                          window.location.reload(),
                                                        1
                                                      );
                                                    }}
                                                  >
                                                    <span>
                                                      {gradeItem.title}
                                                    </span>
                                                  </Link>
                                                </div>
                                              </>
                                            );
                                          }
                                          return null; // Return null instead of <span>No Grades</span>
                                        }
                                      )
                                    );
                                  }
                                )}
                            </div>
                          </Typography>
                        </Popover>
                      </>
                    );
                  }
                });
              } else {
                return gradeItem?.materials?.map((material, materialIndex) => {
                  if (material.material_access) {
                    return (
                      <>
                        <div
                          className="progress-card-item"
                          onClick={(event) => handlePopUpClick(event, material)}
                          style={{ cursor: "pointer" }}
                        >
                          <div className="progress-card-item-img-section">
                            {material.image ? (
                              <img
                                src={material.image}
                                alt={`schooltube-${material.title}-image`}
                              />
                            ) : (
                              <img
                                src="./img/grade-image@2x.png"
                                alt={`schooltube-${material.title}-image`}
                              />
                            )}
                          </div>
                          <div className="progress-card-item-content">
                            <span className="progress-card-subject">
                              {material.title}
                            </span>
                            <div className="progress-card-item-progress">
                              <div className="progress-card-item-progress-text">
                                <span>Completed</span>
                                <span className="progress-complete-level">
                                  {material.seen_videos_count}/
                                  {material.videos_count}
                                </span>
                              </div>
                              <div className="progress-card-item-progress-chart">
                                <CircularProgressWithLabel
                                  value={
                                    parseInt(material.videos_count) === 0
                                      ? 0
                                      : (parseInt(material.seen_videos_count) /
                                          parseInt(material.videos_count)) *
                                        100
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <Popover
                          id={id}
                          open={selectedMaterial === material && open}
                          anchorEl={anchorEl}
                          onClose={handleClose}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                          }}
                        >
                          <Typography sx={{ p: 2 }}>
                            <div className="popup-grade-container">
                              <h4>Grades</h4>
                              {material && material.grades_ids.length == 1 ? (
                                <>
                                  {material &&
                                    material.grades_ids?.map(
                                      (
                                        featuredGradeItem
                                      ) => {
                                        return (
                                          getMaterialsData &&
                                          getMaterialsData.grades?.map(
                                            (gradeItem, index) => {
                                              if (
                                                gradeItem.id == material ||
                                                gradeItem.id ==
                                                  `${featuredGradeItem}`
                                              ) {
                                                return (
                                                  <Redirect
                                                    to={{
                                                      pathname: "/watch-course",
                                                      search: `id=${gradeItem.id}`,
                                                      state: {
                                                        categData,
                                                        gradeTitle:
                                                          gradeItem.title,
                                                      },
                                                    }}
                                                  />
                                                );
                                              }
                                              return null; // Return null instead of <span>No Grades</span>
                                            }
                                          )
                                        );
                                      }
                                    )}
                                </>
                              ) : (
                                <>
                                  {material &&
                                    material.grades_ids?.map(
                                      (
                                        featuredGradeItem
                                      ) => {
                                        return (
                                          getMaterialsData &&
                                          getMaterialsData.grades?.map(
                                            (gradeItem, index) => {
                                              if (
                                                gradeItem.id == material ||
                                                gradeItem.id ==
                                                  `${featuredGradeItem}`
                                              ) {
                                                return (
                                                    <div
                                                      className="grade-pop-title-container"
                                                      style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                      }}
                                                      key={index}
                                                    >
                                                      <Link
                                                        to={{
                                                          pathname: `/watch-course`,
                                                          search: `id=${gradeItem.id}`,
                                                          state: { categData },
                                                        }}
                                                        style={{
                                                          textDecoration:
                                                            "none",
                                                          background: "#ccc",
                                                          padding: "10px",
                                                          marginTop: "5px",
                                                          borderRadius: "10px",
                                                          textAlign: "center",
                                                        }}
                                                        onClick={() => {
                                                          setTimeout(
                                                            () =>
                                                              window.location.reload(),
                                                            1
                                                          );
                                                        }}
                                                      >
                                                        <span>
                                                          {gradeItem.title}
                                                        </span>
                                                      </Link>
                                                    </div>
                                                );
                                              }
                                              return null; // Return null instead of <span>No Grades</span>
                                            }
                                          )
                                        );
                                      }
                                    )}
                                </>
                              )}
                              {material &&
                                material.grades_ids?.map(
                                  (featuredGradeItem) => {
                                    return (
                                      getMaterialsData &&
                                      getMaterialsData.grades?.map(
                                        (gradeItem, index) => {
                                          if (
                                            gradeItem.id == material ||
                                            gradeItem.id ==
                                              `${featuredGradeItem}`
                                          ) {
                                            return (
                                                <div
                                                  className="grade-pop-title-container"
                                                  style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                  }}
                                                  key={index}
                                                >
                                                  <Link
                                                    to={{
                                                      pathname: `/watch-course`,
                                                      search: `id=${gradeItem.id}`,
                                                      state: { categData },
                                                    }}
                                                    style={{
                                                      textDecoration: "none",
                                                      background: "#ccc",
                                                      padding: "10px",
                                                      marginTop: "5px",
                                                      borderRadius: "10px",
                                                      textAlign: "center",
                                                    }}
                                                    onClick={() => {
                                                      setTimeout(
                                                        () =>
                                                          window.location.reload(),
                                                        1
                                                      );
                                                    }}
                                                  >
                                                    <span>
                                                      {gradeItem.title}
                                                    </span>
                                                  </Link>
                                                </div>
                                            );
                                          }
                                          return null; // Return null instead of <span>No Grades</span>
                                        }
                                      )
                                    );
                                  }
                                )}
                            </div>
                          </Typography>
                        </Popover>
                      </>
                    );
                  }
                });
              }
            })}
            {!hasAccess && (
              <h1
                style={{
                  color: "#ccc",
                  textAlign: "center",
                  margin: "10% 20%",
                }}
              >
                No Grades or Materials Available
              </h1>
            )}
          </BrowserRouter>
        </div>
      </div>
    );
  }
}

export default Progress;
