import React from "react";
import "./Step1.css";

function Step1(props) {
  const { x1BuySchooltubeCards, buyOurCardsFromO, className } = props;

  return (
    <div className={`step-1 ${className || ""}`}>
      <img
        className="icon-check_mark"
        src="/img/vector-1738@2x.svg"
        alt="icon-check_mark"
      />
      <div className="text-8">
        <div className="x1-buy-school-tube-cards poppins-semi-bold-scooter-22px max-[600px]:text-[12px]">
          {x1BuySchooltubeCards}
        </div>
        <p className="buy-our-cards-from-o poppins-normal-white-14px  max-[600px]:text-[10px]">
          {buyOurCardsFromO}
        </p>
      </div>
    </div>
  );
}

export default Step1;
