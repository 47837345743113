import React from "react";
import { Link } from "react-router-dom";
import "./BTNComponent3.css";

function BTNComponent3(props) {
  const { children, link_to } = props;

  return (
    <Link
      to={link_to}
      className='btn-component'
    >
      <div className='get-code poppins-semi-bold-white-16px'>{children}</div>
    </Link>
  );
}

export default BTNComponent3;
