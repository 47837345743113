import { forwardRef, useImperativeHandle } from "react";
import ReCAPTCHA from "react-google-recaptcha";

const GoogleRecaptcha = forwardRef((props, ref) => {
  const handleRecaptchaChange = (response) => {
    props.setRecaptchaResponse(response);
  };

  // Expose the reset method
  useImperativeHandle(ref, () => ({
    reset() {
      window.grecaptcha.reset();
    },
  }));

  return (
    <ReCAPTCHA
      sitekey="6Lc8QxclAAAAAEsSew-mIQuH8IZ2d_ZgkIJnUZ8b"
      onChange={handleRecaptchaChange}
    />
  );
});

export default GoogleRecaptcha;
