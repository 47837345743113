import { createSlice } from "@reduxjs/toolkit";

const translationSlice = createSlice({
  name: "translation",
  initialState: {
    translation: [],
    tr_shourtcut: "",
    loading: true,
  },
  reducers: {
    setTranslation(state, action) {
      const v = action.payload;
      state.translation = JSON.parse(v);
      state.loading = false; // Set loading state to false
    },
    setShortcut(state, action) {
      var v = action.payload;
      state.tr_shourtcut = v;
    },
    tr(state, action) {
      const tr_value = action.payload;

      return "test";
    },
  },
});

export const translationAction = translationSlice.actions;
export default translationSlice;
