import { useState } from "react";
import { Button, InputAdornment, TextField } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CallIcon from "@mui/icons-material/Call";
import MenuItem from "@mui/material/MenuItem";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import RemoveRoadIcon from "@mui/icons-material/RemoveRoad";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import SpeakerNotesIcon from "@mui/icons-material/SpeakerNotes";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import AccountCircle from "@mui/icons-material/AccountCircle";
import axios from "axios";
import { URL } from "../../static/data/config";
import Swal from "sweetalert2";
import { useTranslation } from "../translationUtils";
import TextTR from "../HomePage/TextTR";

const DeliveryPaymentModal = ({ open, handleClose }) => {
  const [formData, setFormData] = useState({
    name: "",
    phoneNumber: "",
    region: "",
    city: "",
    street: "",
    building: "",
    notes: "",
    numberOfCards: "",
  });

  const [price, setPrice] = useState(0);

  const regions = [
    { label: "جبيل", value: "جبيل" },
    { label: "كسروان", value: "كسروان" },
    { label: "المتن", value: "المتن" },
    { label: "بيروت", value: "بيروت" },
    { label: "بعبدا", value: "بعبدا" },
    { label: "بنت جبيل", value: "بنت جبيل" },
    { label: "عاليه", value: "عاليه" },
    { label: "الشوف", value: "الشوف" },
    { label: "الهرمل", value: "الهرمل" },
    { label: "بعلبك", value: "بعلبك" },
    { label: "زحلة", value: "زحلة" },
    { label: "البقاع الغربي", value: "البقاع الغربي" },
    { label: "راشيا", value: "راشيا" },
    { label: "عكار", value: "عكار" },
    { label: "طرابلس", value: "طرابلس" },
    { label: "زغرتا", value: "زغرتا" },
    { label: "بشري", value: "بشري" },
    { label: "البترون", value: "البترون" },
    { label: "الكورة", value: "الكورة" },
    { label: "المنيه - الضنية", value: "المنيه - الضنية"},
    { label: "صيدا", value: "صيدا" },
    { label: "صور", value: "صور" },
    { label: "جزين", value: "جزين" },
    { label: "النبطية", value: "النبطية" },
    { label: "حاصبيا", value: "حاصبيا" },
    { label: "مرجعيون", value: "مرجعيون" },
   
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const { getTranslatedWord } = useTranslation();

  const offers = JSON.parse(localStorage.getItem("offers"));

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      var form = new FormData();
      form.append("name", formData.name);
      form.append("phoneNumber", formData.phoneNumber);
      form.append("region", formData.region);
      form.append("city", formData.city);
      form.append("street", formData.street);
      form.append("building", formData.building);
      form.append("notes", formData.notes);
      form.append("numberOfCards", formData.numberOfCards);
      form.append(
        "token",
        localStorage.getItem("login-token") ??
          sessionStorage.getItem("login-token")
      );

      const response = await axios.post(
        `${URL}material/make-delivery-request`,
        form
      );

      if (response.data.succeeded) {
        Swal.fire({
          icon: "success",
          title: getTranslatedWord(response.data.title),
          text: getTranslatedWord(response.data.message),
        });
      } else {
        Swal.fire({
          icon: "error",
          title: getTranslatedWord(response.data.title),
          text: getTranslatedWord(response.data.message),
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: getTranslatedWord("Error"),
        text: getTranslatedWord(error.message),
      });
    }
    handleClose();
  };

  const handleNbCardsChange = (event) => {
    const { name, value } = event.target;
    const numberOfCards = parseInt(value, 10);

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));

    if (!isNaN(numberOfCards) && numberOfCards > 0) {
      // Find the closest offer with the highest cards_count less than or equal to numberOfCards
      let closestOffer = null;
      for (let offer of offers) {
        if (offer.cards_count <= numberOfCards) {
          if (!closestOffer || offer.cards_count > closestOffer.cards_count) {
            closestOffer = offer;
          }
        }
      }

      if (closestOffer) {
        const calculatedPrice =
          (closestOffer.price / closestOffer.cards_count) * numberOfCards;
        setPrice(calculatedPrice);
      } else {
        setPrice(0);
      }
    } else {
      setPrice(0);
    }
  };
  function extractTextFromComponent(Component, props) {
    // Renders the component and extracts the text, assumes TextTR returns plain text in some way.
    const element = <Component {...props} />;
    return element.props.text || ""; // Adjust based on how TextTR handles text
  }
  const namePlaceholder = extractTextFromComponent(TextTR, { text: "Phone Number" });
  const phoneNumberPlaceholder = extractTextFromComponent(TextTR, { text: "Phone Number" });
  const regionPlaceholder = extractTextFromComponent(TextTR, { text: "Region" });
  const cityPlaceholder = extractTextFromComponent(TextTR, { text: "City" });
  const streetPlaceholder = extractTextFromComponent(TextTR, { text: "Street" });
  const buildinglaceholder = extractTextFromComponent(TextTR, { text: "Building" });
  const noteslaceholder = extractTextFromComponent(TextTR, { text: "Notes" });
  const NumberofCardslaceholder = extractTextFromComponent(TextTR, { text: "Number of Cards" });

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
    >
      <DialogTitle id="alert-dialog-title" sx={{ textAlign: "center" }}>
        Delivery Form
      </DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
          }}
        >
         
          <TextField
            label="الاسم"
            name="name"
            value={formData.name}
            onChange={handleChange}
            sx={{ m: 1 }}
            placeholder={namePlaceholder}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LocationCityIcon />
                </InputAdornment>
              ),
            }}
            required
          />
          <TextField
            label="رقم الهاتف"
            name="phoneNumber"
            value={formData.phoneNumber}
            onChange={handleChange}
            type="number"
            sx={{ m: 1 }}
            placeholder={phoneNumberPlaceholder}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <CallIcon />
                  {/* Add the text beside the icon */}
                </InputAdornment>
              ),
            }}
            required
          />
          <TextField
            id="filled-select-currency"
            select
            label="القضاء"
            name="region"
            value={formData.region}
            onChange={handleChange}
            sx={{ m: 1 }}
            placeholder={regionPlaceholder}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                
                </InputAdornment>
              ),
            }}
            required
          >
            {regions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            label="المدينة أو القرية"
            name="city"
            value={formData.city}
            onChange={handleChange}
            sx={{ m: 1 }}
            placeholder={cityPlaceholder}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LocationCityIcon />
                </InputAdornment>
              ),
            }}
            required
          />
          <TextField
            label="الشارع"
            name="street"
            value={formData.street}
            onChange={handleChange}
            sx={{ m: 1 }}
            placeholder={streetPlaceholder}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <RemoveRoadIcon />
                </InputAdornment>
              ),
            }}
            required
          />
          <TextField
            label="المبنى"
            name="building"
            value={formData.building}
            onChange={handleChange}
            sx={{ m: 1 }}
            placeholder={buildinglaceholder}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountBalanceIcon />
                </InputAdornment>
              ),
            }}
            required
          />
          <TextField
            label="ملاحظات"
            name="notes"
            value={formData.notes}
            onChange={handleChange}
            sx={{ m: 1 }}
            placeholder={noteslaceholder}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SpeakerNotesIcon />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            label="عدد البطاقات"
            name="numberOfCards"
            type="number"
            value={formData.numberOfCards}
            onChange={handleNbCardsChange}
            sx={{ m: 1 }}
            placeholder={NumberofCardslaceholder}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <FormatListNumberedIcon />
                </InputAdornment>
              ),
            }}
            required
          />
        </DialogContent>
        <div dir="rtl" className="px-2">
          <p className="font-bold mb-2">يضاف على السعر 3$ ثمن التوصيل</p>
          <p className="font-bold mb-2">Price: ${price}</p>
        </div>
        <DialogActions
          sx={{
            position: "sticky",
            bottom: 0,
            backgroundColor: "white",
            p: 3,
            z: 100,
          }}
        >
          <Button
            type="button"
            color="error"
            onClick={handleClose}
            variant="contained"
            sx={{ zIndex: 200, ml: 2 }}
          >
            Close
          </Button>
          <Button type="submit" variant="contained" sx={{ zIndex: 200, ml: 2 }}>
            Submit
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default DeliveryPaymentModal;
