import React, { useState, useEffect, useRef } from "react";
import { BrowserRouter as Router, Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";
import {
  BoxArrowDownRight,
  ClockHistory,
  Lock,
  PersonCircle,
  QuestionCircle,
} from "react-bootstrap-icons";
import UnlockPowerOfStudySection from "../UnlockPowerOfStudySection";
import TeacherTitleLines from "../TitleLines/TeacherTitleLines";
import "./account-setting.css";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Progress from "./Progress";
import EditProfile from "./EditProfile";
import ChangeProfilePwd from "./ChangeProfilePwd";
import MyQuestion from "./MyQuestion";
import Swal from "sweetalert2";
import axios from "axios";
import TextTR from "../HomePage/TextTR";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout, setNotificationDrawer } from "../../store/CheckLogin";
import { URL } from "../../static/data/config";

/**to format the date and the time */
function formatDate(date) {
  const options = {
    day: "numeric",
    month: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  };

  return date.toLocaleString("en-US", options);
}

function AccountSetting(props) {
  const dispatch = useDispatch();
  const loginToken =
    localStorage.getItem("login-token") ??
    sessionStorage.getItem("login-token");
  const { unlockPowerOfStudySectionProps } = props;

  const [activeComponent, setActiveComponent] = useState("myProgress");

  function navigateComponent(type) {
    setActiveComponent(type);
  }

  const location = useLocation();
  useEffect(() => {
    if (location.state) {
      navigateComponent("changePwd");
    }
  }, [location.state]);

  const [isLoggedOut, setIsLoggedOut] = useState(false);
  const [hasReloaded, setHasReloaded] = useState(false);

  function getData() {
    let formData = new FormData();
    formData.append("token", loginToken);
    formData.append("active_time", localStorage.getItem("enterTime"));
    formData.append("inActive_time", formatDate(new Date()));
    const url1 = `${URL}users/set-time-spent`;
    fetch(url1, {
      method: "POST",
      body: formData,
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(res.statusText);
        }
        dispatch(logout());
        setShouldRender(!shouldRender);
        setIsLoggedOut(true);
        setHasReloaded(true);
        return res.json();
      })
      .catch((err) => console.log(err));
  }

  const [homeData, setHomeData] = useState();
  function getHomeData() {
    let formData = new FormData();
    formData.append(
      "token",
      localStorage.getItem("login-token") ??
        sessionStorage.getItem("login-token")
    );
    formData.append("is_web", true);
    const url1 = `${URL}side/home-data`;
    fetch(url1, {
      method: "POST",
      body: formData,
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(res.statusText);
        }
        return res.json();
      })
      .then((res) => {
        setHomeData(res);
      })
      .catch((err) => console.log(err));
  }
  useEffect(() => {
    getHomeData();
    if (homeData?.logged_out) {
      dispatch(logout());
      setShouldRender(!shouldRender);
      setIsLoggedOut(true);
      setHasReloaded(true);
    }
  }, []);

  const [shouldRender, setShouldRender] = useState(false);
  const handleLogOut = () => {
    getData();
  };

  useEffect(() => {
    if (hasReloaded) {
      setHasReloaded(false);
    }
  }, [isLoggedOut]);

  const { isLoggedIn, user } = useSelector((state) => state.checkLogin);

  const [imageData, setImageData] = useState();
  const fileInputRef = useRef(null);

  const tableName = useSelector((state) => state.checkLogin.tableName);

  useEffect(() => {
    if (user) {
      setImageData(user.image);
    }
  }, [user]);

  async function handleAcountImage(event) {
    event.preventDefault();
    const file = event.target.files[0];
    //const user_data = JSON.parse(localStorage.getItem("loginData"));
    let formData = new FormData();
    formData.append("token", loginToken);
    formData.append("table_name", tableName);
    formData.append("row_id", user?.id);
    formData.append("file", file);

    await axios
      .post(`${URL}side/upload-images`, formData)
      .then((res) => {
        if (res.data.succeeded) {
          Swal.fire({
            icon: "success",
            title: "Update Image",
            text: "Image Updated Succesfully",
          });
          setImageData(res.data.path);
        } else {
          Swal.fire({
            icon: "error",
            title: "Error Occured",
          });
          setImageData(null);
        }
      })
      .catch((err) => {
        alert(err.message);
      });
  }

  /**redirect if the user isnt logged in */
  if (!isLoggedIn) {
    return <Redirect to={"./"} />;
  }
  return (
    <>
      <div
        className="my-learning-container"
        onClick={() => {
          dispatch(setNotificationDrawer(false));
        }}
      >
        <Helmet>
          <meta name="viewport" content="width=device-width, initial-scale=1" />
        </Helmet>
        {/* <Navbar /> */}
        <div className="teachers-main-container">
          <div className="title-line-container">
            <TextTR
              className="account-title valign-text-middle poppins-semi-bold-black-25px"
              text="msg_account_settings"
            />
            <TeacherTitleLines />
          </div>
          <Router>
            <div className="account-setting-container">
              <div className="account-setting-navigation-container">
                <div className="account-setting-profile-section">
                  {imageData?.length > 0 ? (
                    <div className="account-setting-profile">
                      <img
                        src={imageData}
                        alt="profile-pic"
                        className="!cursor-pointer"
                        onClick={() => fileInputRef.current.click()}
                      />
                    </div>
                  ) : (
                    <div
                      className="account-setting-profile"
                      style={{ border: "none" }}
                    >
                      <img
                        src="./img/person-account-img.png"
                        alt="profile-pic"
                        className="!cursor-pointer"
                        onClick={() => fileInputRef.current.click()}
                      />
                    </div>
                  )}

                  {user?.phone_number?.length > 0 && (
                    <div className="account-user-info">
                      <span className="account-user-name">{user?.name}</span>
                      <span className="account-user-phone-number">
                        {user?.country_code?.includes("+")
                          ? user.country_code
                          : `+${user.country_code}`}{" "}
                        {user?.phone_number}
                      </span>
                    </div>
                  )}

                  <div className="account-edit-profile-btn">
                    <Button
                      onClick={() => fileInputRef.current.click()}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      <span className="pencil-icon">
                        <img
                          src="./img/edit-pencil-icon.png"
                          alt="pencil-icon"
                        />{" "}
                      </span>
                      <TextTR text={"lbl_edit_image"} />
                    </Button>
                    <input
                      ref={fileInputRef}
                      type="file"
                      style={{ display: "none" }}
                      onChange={handleAcountImage}
                    />
                    {/* <Button onClick={handleAcountImage}>Edit Profile</Button> */}
                  </div>
                </div>
                <div className="account-setting-navigation-bg account-setting-nav-section">
                  <div className="profile-nav-list ">
                    <div
                      onClick={() => navigateComponent("myProgress")}
                      className={`comp-nav-btn ${
                        activeComponent === "myProgress"
                          ? "activeProfileBtn"
                          : ""
                      }`}
                    >
                      <div className="profile-nav-item">
                        <div className="profile-icons">
                          <ClockHistory />
                        </div>
                        <span className="profile-nav-item-text">
                          <TextTR text={"lbl_my_progress"} />
                        </span>
                      </div>
                    </div>
                    <div
                      onClick={() => navigateComponent("myProfile")}
                      className={`comp-nav-btn ${
                        activeComponent === "myProfile"
                          ? "activeProfileBtn"
                          : ""
                      }`}
                    >
                      <div className="profile-nav-item">
                        <div className="profile-icons">
                          <PersonCircle />
                        </div>
                        <span className="profile-nav-item-text">
                          <TextTR text={"lbl_my_profile"} />
                        </span>
                      </div>
                    </div>
                    <div
                      onClick={() => navigateComponent("myQuestions")}
                      className={`comp-nav-btn ${
                        activeComponent === "myQuestions"
                          ? "activeProfileBtn"
                          : ""
                      }`}
                    >
                      <div className="profile-nav-item">
                        <div className="profile-icons">
                          <QuestionCircle />
                        </div>
                        <span className="profile-nav-item-text">
                          <TextTR text={"lbl_my_questions"} />
                        </span>
                      </div>
                    </div>
                    <div
                      onClick={() => navigateComponent("changePwd")}
                      className={`comp-nav-btn ${
                        activeComponent === "changePwd"
                          ? "activeProfileBtn"
                          : ""
                      }`}
                    >
                      <div className="profile-nav-item">
                        <div className="profile-icons">
                          <Lock />
                        </div>
                        <span className="profile-nav-item-text">
                          <TextTR text={"lbl_change_password"} />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="account-setting-navigation-bg account-log-out-section">
                  <Link onClick={handleLogOut} to={"/home-page"}>
                    <div className="profile-logout-item">
                      <div className="profile-icons">
                        <BoxArrowDownRight />
                      </div>
                      <span className="profile-nav-item-text">
                        <TextTR text={"lbl_logout"} />
                      </span>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="account-setting-content mb-10">
                {/* <ChangeProfilePwd /> */}

                {activeComponent === "myProgress" && <Progress />}
                {activeComponent === "myProfile" && (
                  <EditProfile accountData={user} url={URL} />
                )}
                {activeComponent === "myQuestions" && (
                  <MyQuestion url={URL} user_image={user?.image} />
                )}
                {activeComponent === "changePwd" && (
                  <ChangeProfilePwd url={URL} />
                )}
              </div>
            </div>
          </Router>
        </div>

        <UnlockPowerOfStudySection
          group148={unlockPowerOfStudySectionProps.group148}
          group147={unlockPowerOfStudySectionProps.group147}
        />
      </div>
    </>
  );
}

export default AccountSetting;
