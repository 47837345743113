import React, { useLayoutEffect } from "react";
import "./my-question.css";
import { useState, useRef, useEffect } from "react";
import GoogleRecaptcha from "../GoogleRecaptcha";

function MyQuestion(props) {
  /*fetching my question data */
  const [myQuestion, setMyQuestion] = useState([]);
  const [offset, setOffset] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  function getMyQuestionData() {
    let formData = new FormData();
    formData.append(
      "token",
      localStorage.getItem("login-token") ??
        sessionStorage.getItem("login-token")
    );
    formData.append("limit", 5);
    formData.append("offset", offset);
    const url1 = `${props.url}users/user-questions`;
    fetch(url1, {
      method: "POST",
      body: formData,
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(res.statusText);
        }
        return res.json();
      })
      .then((res) => {
        setMyQuestion(res);
        setIsLoading(false);
      })
      .catch((err) => console.log(err));
  }

  // console.log(gradeCards);

  useEffect(() => {
    getMyQuestionData();
  }, []);

  // useEffect(() => {
  //   console.log(myQuestion);
  // }, [myQuestion]);
  /*Ending fetching data here */

  /*for scrolling function*/

  const [isScrolling, setIsScrolling] = useState(false);
  const mainDivRef = useRef(null);

  // useLayoutEffect(() => {
  //   if (mainDivRef.current.clientHeight >= 70) {
  //     setIsScrolling(true);
  //   }
  // }, []);

  let user_image = "./img/person-account-img.png";
  if (props.user_image) {
    user_image = props.user_image;
  }

  if (isLoading) {
    return (
      <div className='home-loading'>
        <img
          src='./img/all-grade-loading.gif'
          alt='loading'
        />
      </div>
    );
  } else {
    return (
      <>
        {myQuestion && myQuestion.user_questions?.length > 0 ? (
          <div
            // ref={mainDivRef}
            className={`main-div  scroll my-question-scrollable-container`}
          >
            <div className='profile-my-question'>
              <img
                className='my-question-user-img'
                src={user_image}
                alt='profile-pic'
              />

              <div className='my-question-container'>
                <div className='question-answer-container'>
                  <span className='question-section'>
                    <strong>Q:</strong> this is the question
                  </span>
                  <span className='answer-section'>
                    <strong>A:</strong>{" "}
                    {false == "Rejected" ? (
                      <span style={{ color: "red", fontWeight: "500" }}>
                        accepted
                      </span>
                    ) : true ? (
                      <span style={{ color: "green", fontWeight: "600" }}>
                        answer of the question
                      </span>
                    ) : (
                      <span style={{ color: "blue", fontWeight: "600" }}>
                        accepted
                      </span>
                    )}
                  </span>
                </div>
                <span
                  className='question-date'
                  style={{ width: "100px" }}
                >
                  questionItem.datetime
                </span>
              </div>
            </div>
            {myQuestion &&
              myQuestion.user_questions?.map((questionItem, questionIndex) => {
                return (
                  <div
                    className='profile-my-question'
                    key={questionIndex}
                  >
                    <img
                      className='my-question-user-img'
                      src={user_image}
                      alt='profile-pic'
                    />

                    <div className='my-question-container'>
                      <div className='question-answer-container'>
                        <span className='question-section'>
                          <strong>Q:</strong> {questionItem.body}
                        </span>
                        <span className='answer-section'>
                          <strong>A:</strong>{" "}
                          {questionItem.status == "Rejected" ? (
                            <span style={{ color: "red", fontWeight: "500" }}>
                              {questionItem.status}
                            </span>
                          ) : questionItem.answer ? (
                            <span style={{ color: "green", fontWeight: "600" }}>
                              {questionItem.answer}
                            </span>
                          ) : (
                            <span style={{ color: "blue", fontWeight: "600" }}>
                              {questionItem.status}
                            </span>
                          )}
                        </span>
                      </div>
                      <span
                        className='question-date'
                        style={{ width: "100px" }}
                      >
                        {questionItem.datetime}
                      </span>
                    </div>
                  </div>
                );
              })}
          </div>
        ) : (
          <div
            className='main-div  scroll my-question-scrollable-container'
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <h3 style={{ color: "#ccc" }}>No Questions Avialable</h3>
          </div>
        )}
      </>
    );
  }
}

export default MyQuestion;
