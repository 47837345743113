import React, { useState, useEffect } from "react";

function ScrollUpButton() {
  const [showButton, setShowButton] = useState(false);

  // useEffect(() => {
  //   const handleScroll = () => {
  //     if (window.pageYOffset > 0) {
  //       setShowButton(true);
  //     } else {
  //       setShowButton(false);
  //     }
  //   };

  //   window.addEventListener("scroll", handleScroll);

  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);

  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <img
      className='scroll-top-btn-3'
      src='/img/scroll-top-btn-2@2x.svg'
      alt='scroll top BTN'
      onClick={handleClick}
    />
  );
}

export default ScrollUpButton;
