import AskAQuestion from "../AskAQuestion";
import { useState, useEffect } from "react";
import axios from "axios";

function FaqElement(props) {
  const [faqData, setFaqData] = useState([]);
  const [videoId, setVideoId] = useState();
  const [offset, setOffset] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setVideoId(props.video_id);
  }, [props]);

  async function getFaqData() {
    const url1 = `${props.url}material/get-faqs?video_id=${videoId}&limit=5&offset=${offset}`;
    await axios
      .get(url1)
      .then((res) => {
        setFaqData(res.data);
        setIsLoading(false);
      })
      .catch((err) => console.log(err));
  }
  const [noFAQ, setNoFAQ] = useState("");
  useEffect(() => {
    if (videoId) {
      getFaqData();
    } else {
      setNoFAQ("No FAQ Avialable!");
      setIsLoading(false);
    }
  }, [videoId]);

  // useEffect(() => {
  //   console.log(faqData);
  // }, [faqData]);

  /*Ending fetching data here */
  let user_image = "./img/person-account-img.png";

  return (
    <>
      <div className="intro-faq-section">
        {isLoading ? (
          <div className="faqs-loading">
            <img src="./img/notification-loading.gif" alt="loading" />
          </div>
        ) : faqData && faqData.faqs?.length > 0 ? (
          faqData.faqs?.map((questionItem, questionIndex) => {
            return (
              <div className="profile-my-question" key={questionIndex}>
                <img
                  className="my-question-user-img"
                  src={user_image}
                  alt="profile-pic"
                />

                <div className="my-question-container">
                  <div className="question-answer-container">
                    <span className="question-section">
                      <strong>Q:</strong> {questionItem.body}
                    </span>
                    <span className="answer-section">
                      <strong>A:</strong> {questionItem.answer}
                    </span>
                  </div>
                  <span className="question-date" style={{ width: "100px" }}>
                    {questionItem.datetime}
                  </span>
                </div>
              </div>
            );
          })
        ) : (
          <span
            style={{
              marginTop: "20px",
              width: "100%",
              textAlign: "center",
              color: "#000",
            }}
          >
            No FAQ Available!
          </span>
        )}
        {isLoading ? null : (
          <div
            className="load-more"
            style={{ textAlign: "center", cursor: "pointer" }}
            onClick={() => {
              setOffset(offset + 5);
              getFaqData();
            }}
          >
            {faqData?.faqs?.length > 0 ? "Load more" : null}
          </div>
        )}
      </div>

      {props.selectedVideo &&
      (localStorage.getItem("login-token") ||
        sessionStorage.getItem("login-token")) ? (
        <div className="faq-btn-question">
          <AskAQuestion
            btnText={" Have a Question? We are here to help you"}
            url={props.url}
            video_id={videoId}
          />
        </div>
      ) : null}
    </>
  );
}
export default FaqElement;
