import React from "react";
import Tr from "../translation/Utils";
import { useEffect } from "react";

const TextTR = (props) => {
  // useEffect(() => {
  //   console.log(props.text);
  // }, [props.text]);
  return (
    <div
      className={props.className}
      onClick={props.onClick}
      style={props.style}
    >
      {Tr(props.text)}
    </div>
  );
};

export default TextTR;
