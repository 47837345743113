import React from "react";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import SwiperComponent from "../SwiperComponent";
// import FeaturedCoursesSection from "../FeaturedCoursesSection";
// import StudentsFeedbackTitleAndLines from "../StudentsFeedbackTitleAndLines";
// import FeedbackSwiperComponent22 from "../FeedbackSwiperComponent22";
import SupportedGradesSwiperComponent22 from "../SupportedGradesSwiperComponent22";
import WeSupportTheseCountries from "../WeSupportTheseCountries";
import CountersComponent from "../CountersComponent";
import CardLocationTableSection from "../CardLocationTableSection";
import HowItWorksSectionWithBG from "../HowItWorksSectionWithBG";
import UnlockPowerOfStudySection from "../UnlockPowerOfStudySection";
import "./HomePage.css";
import localForage from "localforage";
// import WhatsAppButton from "../WhatsAppButton";
import TextTR from "./TextTR";
import { useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { useDispatch } from "react-redux";
// import { setDataFetched, setHomeDataGlobal } from "../../store/DataFetched";

import SocialMediaIcons from "../SocialMediaIcons/SocialMediaIcons";
import { setNotificationDrawer } from "../../store/CheckLogin";
//new
import GetCardButton from "../GetCardButton/GetCardButton";
import Highlights from "../Highlights/Highlights";
import HomeOffers from "../offers/HomeOffers";

function HomePage(props) {
  const {
    overlapGroup1,
    place1,
    place2,
    swiperComponentProps,
    featuredCoursesSectionProps,
    feedbackSwiperComponent22Props,
    supportedGradesSwiperComponent22Props,
    unlockPowerOfStudySectionProps,
  } = props;

  /*Loading */
  // const [isLoading, setIsLoading] = useState(true);
  const [shouldReload, setShouldReload] = useState(false);
  const dispatch = useDispatch();
  // const isDataFetched = useSelector((state) => state.dataFetched.isDataFetched);
  // const homeDataGlobal = useSelector((state) => state.dataFetched.homeData);

  /*get the categoryData */
  const categData = [];
  {
    props.getHomeData?.categories &&
      props.getHomeData?.categories?.map((categItem) => {
        categData.push(categItem);
      });
  }

  localForage
    .setItem("countries", props.getHomeData?.countries)
    .then((value) => {
      // console.log("Retrieved value:", value);
    });

  localForage.setItem("cities", props.getHomeData?.cities).then((value) => {
    // console.log("Retrieved value:", value);
  });

  /*to go to the point of sales */
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const pointOfSales = searchParams.get("point-of-sales");
  if (pointOfSales == "1") {
    const element = document.getElementById("point-of-sales");
    if (element) {
      const top = element.getBoundingClientRect().top + window.scrollY - 100; // add some offset
      window.scrollTo({ top, behavior: "smooth" });
    }
  } else {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  /*set time out for the counter */
  const [showCounters, setShowCounters] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShowCounters(true);
    }, 1000);
  }, []);

  /**for responsivity */
  const isMobile = useMediaQuery({ query: "(max-width:650px)" });

  return (
    <div className="container-center-horizontal">
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>Schooltube</title>
        <meta
          name="description"
          content={`Schooltube offers a user-friendly platform that allows all grades students to access high-quality educational resources quickly and efficiently. Our curated library of materials covers a wide range of subjects and topics, providing students with accurate and reliable information. `}
        />
      </Helmet>

      {props.isLoading ? (
        <div className="home-loading">
          <img src="./img/all-grade-loading.gif" alt="loading" />
        </div>
      ) : (
        <div
          className="home-page screen"
          onClick={() => {
            dispatch(setNotificationDrawer(false));
          }}
        >
          <SwiperComponent
            bTNComponentProps={swiperComponentProps.bTNComponentProps}
            mainHomeSlider={props.getHomeData}
          />
          <Highlights hightlights={props.getHomeData?.highlights} />
          <HomeOffers offers={props.getHomeData?.offers} />
          {/* <FeaturedCoursesSection
            {...featuredCoursesSectionProps}
            FeaturedMaterialsMap={props.getHomeData}
          /> */}
          <GetCardButton />
          {/* <div className="students-feedback-section">
            <StudentsFeedbackTitleAndLines />
            <FeedbackSwiperComponent22
              FeedbackMap={props.getHomeData}
              {...feedbackSwiperComponent22Props}
            />
          </div> */}
          <div className="supported-grades-section">
            <div
              className="overlap-group1"
              style={{ backgroundImage: `url(${overlapGroup1})` }}
            >
              <TextTR
                className={
                  "all-grades-small-title poppins-semi-bold-white-16px"
                }
                text="lbl_all_grades2"
              />
              {/* <div className='all-grades-small-title poppins-semi-bold-white-16px'>
                
              </div> */}
              <div className="supported-grades-container">
                <SupportedGradesSwiperComponent22
                  {...supportedGradesSwiperComponent22Props}
                  SupportedGradesMap={props?.getHomeData}
                  supGradeLang={"English"}
                  // image_url={image_url}
                  categData={categData}
                />
                <TextTR
                  className={"supported-grades-title valign-text-middle"}
                  text="msg_supported_grades"
                />
                {/* <div className='supported-grades-title valign-text-middle'>
                 
                </div> */}
              </div>
              <Link to={"./all-grades"}>
                <div className="visit-all-grades-btn-section !w-48">
                  <div className=" bg-[#200793] text-center rounded-xl  leading-8 text-white text-2xl ">
                    View All
                  </div>
                </div>
              </Link>
            </div>
          </div>
          <WeSupportTheseCountries />
          <div className="flag-container max-[600px]:block">
            <div className="x-flag max-[600px]:float-left">
              <div
                className="x-flag-1"
                style={{ backgroundImage: `url(./img/lebanon-flag@2x.gif)` }}
              ></div>
              <div className="place valign-text-middle poppins-semi-bold-black-25px">
                {place1}
              </div>
            </div>
            <div className="x-flag max-[600px]:float-right">
              <div
                className="x-flag-1"
                style={{ backgroundImage: `url(./img/iraq-flag-2@2x.gif)` }}
              ></div>
              <div className="place-1 valign-text-middle poppins-semi-bold-black-25px">
                {place2}
              </div>
            </div>
          </div>
          {showCounters && (
            <CountersComponent
              studentsCount={props.getHomeData?.students_count}
              materialsCount={props.getHomeData?.materials_count}
              videosCount={props.getHomeData?.videos_count}
            />
          )}

          <SocialMediaIcons />

          <div id="point-of-sales">
            <CardLocationTableSection homeData={props.getHomeData} />
          </div>
          <img className="x962" src="./img/-962@1x.gif" alt="962" />
          <HowItWorksSectionWithBG
            how_it_works={props.getHomeData?.work_steps}
          />

          {isMobile ? null : (
            <UnlockPowerOfStudySection
              group148={unlockPowerOfStudySectionProps.group148}
              group147={unlockPowerOfStudySectionProps.group147}
            />
          )}
          {/* <WhatsAppButton /> */}
        </div>
      )}
    </div>
  );
}

export default HomePage;
