import React from "react";
import "./edit-profile.css";
import { useState, useEffect } from "react";
import { TextField } from "@mui/material";
import { Button, Form } from "react-bootstrap";
import { textAlign } from "@mui/system";
import InputAdornment from "@mui/material/InputAdornment";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import axios from "axios";
import Swal from "sweetalert2";
import { useTranslation } from "../translationUtils";

function EditProfile(props) {
  /**for alert translation */
  const { getTranslatedWord, setLanguage } = useTranslation();

  const check_note = "";
  const [currentPwd, setCurrentPwd] = useState();
  const [newPwd, setNewPwd] = useState();
  const [confirmPwd, setConfirmPwd] = useState();

  const [changePWdRes, setChangePwdRes] = useState();

  async function handleChangePwdSubmit(event) {
    event.preventDefault();

    if (newPwd === confirmPwd) {
      let formData = new FormData();
      formData.append(
        "token",
        localStorage.getItem("login-token") ??
          sessionStorage.getItem("login-token")
      );
      formData.append("current", currentPwd);
      formData.append("new", newPwd);
      formData.append("is_web", true);

      const response = await axios
        .post(`${props.url}users/change-user-password`, formData)
        .then((res) => {
          setChangePwdRes(res);
          if (res.data.succeeded) {
            Swal.fire({
              icon: "success",
              title: getTranslatedWord(res.data.title),
              text: getTranslatedWord(res.data.message),
            });
          } else {
            Swal.fire({
              icon: "warning",
              title: getTranslatedWord(res.data.title),
              text: getTranslatedWord(res.data.message),
            });
          }
        })
        .catch((err) => {
          alert(err.message);
        });
    } else {
      Swal.fire({
        icon: "warning",
        title: "Password Missmatch",
        text: "New Password and Confirm Must Match",
      });
    }
  }

  return (
    <div>
      <Form onSubmit={handleChangePwdSubmit}>
        <div className="edit-profile-form-container">
          {check_note !== "" ? (
            <h3 style={{ textAlign: "center" }}>{check_note}</h3>
          ) : null}

          <TextField
            id="outlined-password-input-1"
            label="Enter Current Password"
            type="password"
            autoComplete="current-password"
            onChange={(event) => setCurrentPwd(event.target.value)}
            style={{ width: "100%" }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockOutlinedIcon />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            id="outlined-password-input-2"
            label="Enter New Password"
            type="password"
            autoComplete="current-password"
            onChange={(event) => setNewPwd(event.target.value)}
            style={{ width: "100%" }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockOutlinedIcon />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            id="outlined-password-input-3"
            label="Confirm Password"
            type="password"
            autoComplete="current-password"
            onChange={(event) => setConfirmPwd(event.target.value)}
            style={{ width: "100%" }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockOutlinedIcon />
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className="edit-profile--form-btn">
          <Button type="submit" className=" bg-[#304ffe]">
            Save
          </Button>
        </div>
      </Form>
    </div>
  );
}

export default EditProfile;
