import React from "react";
import "./NavBarElements2.css";
import LoginModal from "../Modalbtsrp/LoginModal";
import Tr from "../translation/Utils";

function NavBarElements2(props) {
  return (
    <div className="login-btn !w-[60px] md:!w-[90px]">
      <div className="login roboto-bold-blue-14px">
        {/* <VerificationModal /> */}
        <LoginModal
          btnText={`${Tr("lbl_sign")}`}
          className="login-btn-bg"
          url={props.url}
        />
        {/* <AskAQuestion btnText='Answer the question' /> */}
      </div>
    </div>
  );
}

export default NavBarElements2;
