import React from "react";
import "./NavBarElements.css";
import { useState, useEffect } from "react";
import FormControl from "@mui/material/FormControl";
import { useDispatch, useSelector } from "react-redux";
import { translationAction } from "../../store/tr-slice";
import { setAboutUsLang } from "../../store/DataFetched";

function NavBarElements(props) {
  /**for the translation list and shortcut */
  const dispatch = useDispatch();
  /**for the dropdown */
  const [language, setLanguage] = useState(
    localStorage.getItem("lang-shortcut")
      ? localStorage.getItem("lang-shortcut")
      : "en"
  );
  const sc_local = localStorage.getItem("lang-shortcut") ?? "en";

  let sc;
  if (!sc_local || sc_local == "null") {
    sc = navigator.language.split("-")[0];
  } else {
    sc = sc_local;
  }
  if (sc) {
    dispatch(translationAction.setShortcut(sc));
    // console.log("i set the sc to the redux as: ", sc);
  } else {
    dispatch(translationAction.setShortcut("en"));
    // console.log("i set the sc to the redux as 'en'");
  }
  let selectedLanguage;
  const handleChange = (event) => {
    selectedLanguage = event.target.value;
    setLanguage(event.target.value);
    localStorage.setItem("lang-shortcut", event.target.value);

    // Dispatch the action after the state update is completed
    // Use the selectedLanguage instead of the 'language' state variable
    dispatch(setAboutUsLang(setReduxLangId(event.target.value)));
  };

  useEffect(() => {
    if (language) {
      localStorage.setItem("lang-id", setReduxLangId(language));
    }
    console.log();
  }, [language]);

  const setReduxLangId = (selectedLanguage) => {
    if (props.getHomeData) {
      const languageObj = props.getHomeData?.languages?.find(
        (lang) => lang.shortcut === selectedLanguage
      );
      return languageObj?.id ?? 1;
    } else {
      return 1;
    }
  };

  function getTranslation() {
    let formData = new FormData();
    var date = localStorage.getItem("tr_modif_date");
    if (!date) {
      date = "1970-01-01 00:00:00";
    }
    formData.append("date", date);

    const url1 = `https://stdev.beeflex.net/master/translations`;
    fetch(url1, {
      method: "POST",
      body: formData,
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(res.statusText);
        }
        return res.json();
      })
      .then((res) => {
        if (!res.is_updated) {
          var date = res.date;
          var list = res.list;

          localStorage.setItem("tr_modif_date", date);
          localStorage.setItem("tr_list", JSON.stringify(list));
        } else {
          var list = localStorage.getItem("tr_list");
        }
      })
      .catch((err) => console.log(err));

    dispatch(translationAction.setTranslation(localStorage.getItem("tr_list")));
  }
  useEffect(() => {
    getTranslation();
  }, []);

  return (
    <>
      <FormControl
        sx={{ m: 1 }}
        variant='standard'
        className='language-form-control-dropdown'
      >
        <select
          id='demo-customized-select-native'
          value={language}
          onChange={handleChange}
          style={{
            color: "blue",
            border: "1px solid #ccc",
            borderRadius: "10px",
            padding:"5px 3px",
            cursor :"pointer"
          }}
        >
          {props.getHomeData &&
            props.getHomeData?.languages &&
            props.getHomeData?.languages?.map((langItem, langIndex) => {
              if (langItem.is_app == 1) {
                return (
                  <option
                    key={langIndex}
                    value={langItem.shortcut}
                    style={{ color: "#3434ff", outline:"none" }}
                  >
                    {langItem.shortcut.toUpperCase()}
                  </option>
                );
              }
            })}
        </select>
      </FormControl>
    </>
  );
}

export default NavBarElements;
