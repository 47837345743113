import React, { useEffect, useState } from "react";
import { useLayoutEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { useRef } from "react";
import { useLocation } from "react-router-dom";

import MyLearninCourse6Card from "../MyLearninCourse6Card";
import TitleLines from "../TitleLines";

import Navbar from "../Navbar/Navbar";
import { useMediaQuery } from "react-responsive";
import QuizBGImage from "../BGImage/QuizBgImage";
import TeacherTitleLines from "../TitleLines/TeacherTitleLines";
import { useSelector } from "react-redux";
import { URL } from "../../static/data/config";

function SearchPage() {
  const [gradeCards, setGradeCards] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const homeDataGlobal = useSelector((state) => state.dataFetched.homeData);
  //new
  const [filteredGrades, setFilteredGrades] = useState([]);

  function getData() {
    let formData = new FormData();
    formData.append("token", localStorage.getItem("login-token"));
    formData.append("is_web", true);
    const url1 = `${URL}side/home-data`;
    fetch(url1, {
      method: "POST",
      body: formData,
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(res.statusText);
        }
        return res.json();
      })
      .then((res) => {
        setGradeCards(res);
        setIsLoading(false);
      })
      .catch((err) => console.log(err));
  }

  // console.log(gradeCards);

  useEffect(() => {
    if (homeDataGlobal) {
      setGradeCards(homeDataGlobal);
    } else {
      getData();
    }
  }, []);
  /*Ending fetching data here */

  /*getting the categ items */
  const categData = [];
  {
    gradeCards.categories &&
      gradeCards.categories.map((categItem, categIndex) => {
        categData.push(categItem);
      });
    // console.log(categData);
  }

  const [searchResults, setSearchResults] = useState([]);
  const location = useLocation();
  useEffect(() => {
    const query = new URLSearchParams(location.search).get("query");
    setSearchResults(query);
  }, [location.search]);

  /**for responsivity */
  const isMobile = useMediaQuery({ query: "(max-width:650px)" });

  useEffect(() => {
    if (searchResults && gradeCards.grades) {
      const f = gradeCards?.grades?.filter((g) =>
        g?.title?.toLowerCase()?.includes(searchResults.toLowerCase())
      );
      setFilteredGrades(f);
    } else {
      setFilteredGrades([]);
    }
  }, [searchResults, gradeCards.grades]);

  return (
    <>
      <div className="my-learning-container">
        <Helmet>
          <meta name="viewport" content="width=device-width, initial-scale=1" />
        </Helmet>
        {/* <Navbar /> */}
        <div className="container-center-horizontal">
          <div className="my-learning screen all-grade-page">
            <div
              className="overlap-group1-2 min-h-screen"
              style={{ backgroundImage: "url(./img/bg-image-tr.png)" }}
            >
              <div
                className="all-grade-title-section"
                style={
                  isMobile
                    ? { margin: "20% auto 0 auto" }
                    : { margin: "7% auto 0 auto" }
                }
              >
                <div className="all-grade-title-content">
                  <div
                    className="all-grades-1 valign-text-middle poppins-semi-bold-black-25px"
                    style={{ width: "fit-content" }}
                  >
                    Results of: {searchResults}
                  </div>
                </div>
                <TeacherTitleLines />
              </div>

              {/*checking if there is a result or no */}
              {isLoading && (
                <div
                  style={{
                    width: "80%",
                    height: "80%",
                    margin: "auto",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    style={{ height: "100%" }}
                    src="./img/all-grade-loading.gif"
                    alt="Loading..."
                  />
                </div>
              )}
              <div className="!grid !place-items-center !grid-cols-1 sm:!grid-cols-2 md:!grid-cols-3 lg:!grid-cols-4 !mt-8">
                {!isLoading &&
                  filteredGrades.length > 0 &&
                  filteredGrades.map((gradeCard, index) => {
                    const gradeTitle = gradeCard.title;
                    let card_img = gradeCard.image;

                    if (gradeCard.image == null) {
                      card_img = "./img/grade-image@2x.png";
                    }
                    return (
                      <Link
                        key={index}
                        to={{
                          pathname: `/watch-course`,
                          search: `id=${gradeCard.id}`,
                          state: { categData, gradeTitle },
                        }}
                      >
                        <MyLearninCourse6Card
                          gradeLevelImg={gradeTitle}
                          gradeLevelText={gradeTitle}
                          bgimage={card_img}
                          materialsCount={gradeCard?.materials.length}
                          videoCount={gradeCard.videos_count}
                          pdfCount={gradeCard.pdfs_count}
                          quizesCount={gradeCard.quizzes_count}
                        />
                      </Link>
                    );
                  })}
              </div>
              {!isLoading && filteredGrades?.length === 0 && (
                <h1 className="!text-center">No Matched Grades</h1>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SearchPage;
