import { useState } from "react";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import AvTimerIcon from "@mui/icons-material/AvTimer";
import Checkbox from "@mui/material/Checkbox";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import Accordion from "@mui/material/Accordion";
import IconButton from "@mui/material/IconButton";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AttachFileIcon from "@mui/icons-material/AttachFile";

const EBookItem = ({ item1, clickHandler }) => {
  return (
    <div
      className="h-16 w-full bg-white hover:bg-slate-300 flex items-center justify-start gap-2 my-2 p-2 rounded cursor-pointer"
      onClick={() => clickHandler()}
    >
      <MenuBookIcon fontSize="large" />
      <p className="text-black font-semibold">{item1.title}</p>
    </div>
  );
};

const VideoItem = ({ item1, isChecked, clickHandler, attachmentHandler,isSelected}) => {

  return (
    <div
    className={`h-24 w-full bg-white hover:!bg-slate-300 flex items-start justify-start gap-2 my-2 p-2 rounded cursor-pointer ${isSelected ? 'selected' : ''}`} 
      onClick={() => clickHandler()}
    >
      <img
        src={item1.thumbnail}
        alt={item1.video_name}
        className="w-20 h-full object-contain"
      />
      <div className="flex flex-col w-full">
        <p className="text-black font-semibold truncate-2-lines">
          {item1.video_name}
        </p>
        <div className="flex items-center justify-between w-full">
          <div className="flex items-center justify-start gap-1">
            <AvTimerIcon className="text-blue-700" />
            <p className="font-semibold">{item1.time}</p>
          </div>
          <div className="flex items-center justify-start">
            {item1.has_attachments === 1 && (
              <IconButton
                aria-label="attachment"
                className="!w-auto"
                onClick={attachmentHandler}
              >
                <AttachFileIcon />
              </IconButton>
            )}
              {item1.is_free === 1 && (
              <IconButton
                variant="contained"
                className="!w-auto"
                sx={{
                  borderRadius: '50px',
                  backgroundColor: 'red',
                  color: 'white',
                  textTransform: 'none',
                  padding: '5px 15px',
                  fontSize: '12px'
                }}
              >
                Free
              </IconButton>
            )}
            <Checkbox disabled checked={isChecked} />
          </div>
        </div>
      </div>
    </div>
  );
};

const QuizItem = ({ item1, clickHandler }) => {
  return (
    <div
      className="h-16 w-full bg-white hover:bg-slate-300 flex items-center justify-start gap-2 my-2 p-2 rounded cursor-pointer"
      onClick={() => clickHandler()}
    >
      <TextSnippetIcon fontSize="large" />
      <p className="text-black font-semibold">{item1.title}</p>
    </div>
  );
};

const MaterialItem = ({
  item,
  isChecked,
  checkedVideos = [],
  isSelected,
  handleEbookClick,
  handleVideoClick,
  handleQuizClick,
  handleAttachmentClick,
}) => {
  const [itemsToDisplay, setItemsToDisplay] = useState({ id: "" });
  const [expandedTab, setExpandedTab] = useState([]);

  const itemTabChangeHandler = (id) => {
    setItemsToDisplay({ id });
  };

  const handleAccordionToggle = (event, isExpanded, unit) => {
    if (isExpanded) {
      setExpandedTab(`${unit.title}`);
    } else {
      setExpandedTab(false);
    }

    if (unit.tabs && unit.tabs.length > 0) {
      setItemsToDisplay({
        id: unit.tabs[0].id,
      });
    }
  };

  return (
    <div >
      {item.itemType === "Ebook" && (
        <EBookItem item1={item} clickHandler={handleEbookClick} />
      )}
      {item.itemType === "Video" && (
        <VideoItem
          item1={item}
          clickHandler={handleVideoClick}
          attachmentHandler={handleAttachmentClick}
          isChecked={isChecked}
          isSelected={isSelected}
        />
      )}
      {item.itemType === "Quiz" && (
        <QuizItem item1={item} clickHandler={handleQuizClick} />
      )}
      {item.itemType === "Chapter" && (
        <Accordion
          sx={{ backgroundColor: "#fff", my: 1 }}
          onChange={(event, isExpanded) =>
            handleAccordionToggle(event, isExpanded, item)
          }
          expanded={expandedTab === `${item.title}`}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
            className="font-bold"
          >
            {item.title}
          </AccordionSummary>
          <AccordionDetails>
            <div className="!mb-2 flex items-start gap-2">
              {item?.tabs?.map((tab, tabIndex) => (
                <button
                  key={tabIndex}
                  className={`w-auto p-2 font-bold rounded ${
                    itemsToDisplay.id === tab.id
                      ? "bg-blue-700 text-white"
                      : "bg-blue-200 text-blue-700"
                  }`}
                  onClick={() => itemTabChangeHandler(tab.id)}
                >
                  {tab.title}
                </button>
              ))}
            </div>
            <div className="!mt-2">
              {item?.tabs
                ?.find((tabUnit) => tabUnit.id === itemsToDisplay.id)
                ?.items?.map((item, itemIndex) => (
                  <div key={itemIndex} className="gap-2">
                    {item.itemType === "Ebook" && (
                      <EBookItem
                        item1={item}
                        clickHandler={() => handleEbookClick(item)}
                      />
                    )}
                    {item.itemType === "Video" && (
                      <VideoItem
                        item1={item}
                        clickHandler={() => handleVideoClick(item)}
                        isChecked={
                          (checkedVideos && checkedVideos?.includes(item.id)) ||
                          item.is_finished
                        }
                        attachmentHandler={handleAttachmentClick}
                      />
                    )}
                    {item.itemType === "Quiz" && (
                      <QuizItem
                        item1={item}
                        clickHandler={() => handleQuizClick(item)}
                      />
                    )}
                  </div>
                )) || <h1>No Items Found</h1>}
            </div>
          </AccordionDetails>
        </Accordion>
      )}
    </div>
  );
};

export default MaterialItem;
