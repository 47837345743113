import React from "react";
import BaseCheckbox from "../BaseCheckbox";
import "./Frame299.css";

function Frame299(props) {
  const { grade6, className, baseCheckboxProps } = props;

  return (
    <div className={`frame-299-1 ${className || ""}`}>
      <BaseCheckbox className={baseCheckboxProps.className} />
      <div className="grade-7 poppins-medium-black-21px">{grade6}</div>
    </div>
  );
}

export default Frame299;
