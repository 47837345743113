import React from "react";
import Frame299 from "../Frame299";
import "./Group52.css";

function Group52(props) {
  const { frame299Props } = props;

  return (
    <div className="group-5-1">
      <img className="arrow-down-2-2-1" src="/img/arrow---down-2-2-1@2x.svg" alt="Arrow - Down 2 2" />
      <Frame299
        grade6={frame299Props.grade6}
        className={frame299Props.className}
        baseCheckboxProps={frame299Props.baseCheckboxProps}
      />
    </div>
  );
}

export default Group52;
