import React from "react";
import BuyGradesDropdown from "../BuyGradesDropdown";
import CancelBTN from "../CancelBTN";
import "./BuyCoursePopup.css";

function BuyCoursePopup(props) {
  const { selectYourGrade, addNotes, submitRequest, buyGradesDropdownProps, cancelBTNProps } = props;

  return (
    <div className="container-center-horizontal">
      <div className="buy-course-popup screen">
        <div className="select-grade-popup-with-scroll">
          <div className="select-your-grade">
            <div className="frame-376">
              <div className="select-your-grade-1 poppins-semi-bold-black-24px">{selectYourGrade}</div>
              <div className="frame-307">
                <BuyGradesDropdown {...buyGradesDropdownProps} />
                <div className="frame-306">
                  <div className="notes-1">
                    <div className="add-notes poppins-normal-mountain-mist-19-5px">{addNotes}</div>
                  </div>
                  <div className="group-56-1">
                    <div className="frame-14">
                      <div className="submit-request poppins-bold-white-24px">{submitRequest}</div>
                    </div>
                    <CancelBTN className={cancelBTNProps.className} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BuyCoursePopup;
