import { createSlice } from "@reduxjs/toolkit";

const checkLoginSlice = createSlice({
  name: "checkLogin",
  initialState: {
    user: null,
    isLogIn: true,
    isVerified: false,
    isLoggedIn: false,
    fadeLoginModal: false,
    drawerLogin: false,
    notificationDrawer: false,
    // new
    cities: null,
    countries: null,
    tableName: null,
    unReadNotifications: null,
  },
  reducers: {
    setLoginChecked(state) {
      state.isLogIn = !state.isLogIn;
    },
    login(state, action) {
      state.user = action.payload;
      state.isLoggedIn = true;
    },
    setVerified(state, action) {
      state.isVerified = false;
    },
    logout(state) {
      state.user = null;
      state.isLoggedIn = false;
      sessionStorage.removeItem("login-token");
      localStorage.removeItem("login-token");
      localStorage.removeItem("loginData");
    },
    setFadeLoginModal(state, action) {
      state.fadeLoginModal = action.payload;
    },
    setDrawerLogin(state, action) {
      state.drawerLogin = action.payload;
    },
    setNotificationDrawer(state, action) {
      state.notificationDrawer = action.payload;
    },
    //new
    setCities(state, action) {
      state.cities = action.payload;
    },
    setCountries(state, action) {
      state.countries = action.payload;
    },
    setTableName(state, action) {
      state.tableName = action.payload;
    },
    setUnReadNotifications(state, action) {
      state.unReadNotifications = action.unReadNotifications;
    },
  },
});

export const {
  logout,
  login,
  setVerified,
  setLoginChecked,
  setFadeLoginModal,
  setDrawerLogin,
  setNotificationDrawer,
  setCities,
  setCountries,
  setTableName,
  setUnReadNotifications,
} = checkLoginSlice.actions;
export default checkLoginSlice;
