import React from "react";
import "./CancelBTN.css";

function CancelBTN(props) {
  const { className } = props;

  return (
    <div className={`cancel-btn ${className || ""}`}>
      <div className="cancel poppins-medium-blue-24px">Cancel</div>
    </div>
  );
}

export default CancelBTN;
