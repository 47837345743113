import { Fragment, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

const AttachmentLink = ({ attachment }) => {
  const isPdf = attachment.toLowerCase().endsWith(".pdf");
  const isImage = /\.(jpg|jpeg|png|gif|bmp|webp)$/i.test(attachment);

  if (isPdf) {
    return (
      <div className="bg-slate-100 shadow rounded max-w-full overflow-hidden text-black font-bold whitespace-nowrap">
        <a
          href={`${attachment}#toolbar=0`}
          key={attachment}
          target="_blank"
          rel="noopener noreferrer"
          onClick={(e) => {
            e.preventDefault();
            window.open(`${attachment}#toolbar=0`, "_blank", "noopener,noreferrer");
          }}
          className="flex items-center overflow-hidden"
        >
          <PictureAsPdfIcon fontSize="large" className="!h-[10vh] ml-3 mr-1" />
          <span className="overflow-hidden text-ellipsis whitespace-nowrap">
            {attachment}
          </span>
        </a>
      </div>
    );
  }

  if (isImage) {
    return (
      <div className="m-auto shadow-inner rounded">
        <a
          href={attachment}
          key={attachment}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={attachment}
            alt="attachment"
            className="m-auto"
            style={{ maxWidth: "100px", maxHeight: "100px" }}
          />
        </a>
      </div>
    );
  }

  return (
    <a
      href={attachment}
      key={attachment}
      target="_blank"
      rel="noopener noreferrer"
    >
      {attachment}
    </a>
  );
};

const AttachmentDialog = ({ open, handleClose, attachments }) => {
  return (
    <Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Attachments</DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            className="flex flex-col gap-4"
          >
            {attachments?.map((attachment, index) => {
              return <AttachmentLink attachment={attachment} key={index} />;
            })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            close
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default AttachmentDialog;
