import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Form } from "react-bootstrap";
import { Container, Row, Col } from "react-bootstrap";
import "./AskAQuestion.css";
import { FileUploader } from "react-drag-drop-files";
import axios from "axios";
import Swal from "sweetalert2";
import Tr from "../translation/Utils";
import { height } from "@mui/system";
import { useTranslation } from "../translationUtils";

function AskAQuestion(props) {
  const { getTranslatedWord, setLanguage } = useTranslation();

  /*sending a post request for the faq upload question */
  const [questionBody, setQuestionBody] = useState();
  const [videoId, setVideoId] = useState();
  //const [uploadFaqImage, setUploadFaqImage] = useState();
  const [getFaqQuestionData, setFaqQuestionData] = useState([]);
  const [formDataImageArray, setFormDataImageArray] = useState([]);

  useEffect(() => {
    setVideoId(props.video_id);
    //console.log(props.video_id);
  }, [props]);

  /*for the drag and drop file uploader*/
  const fileTypes = ["JPG", "JPEG", "PNG", "GIF"];
  const [file, setFile] = useState([]);
  const [selectedDone, setSelectedDone] = useState(false);
  const handleChange = (event) => {
    // setFile(event.target.files[0]);
    const files = Array.from(event.target.files);

    setFormDataImageArray(files);
    // console.log(formDataImageArray);
    // Set the selected files
    const selectedFilesArray = Array.from(files);
    setFile(selectedFilesArray);

    setSelectedDone(true);
  };

  const handleCloseImageModal = () => {
    setFile([]);
    setSelectedDone(false);
  };
  
  async function handleAskQuestionSubmit(event) {
    event.preventDefault();
    if (videoId) {
      let formData = new FormData();
      formData.append(
        "token",
        localStorage.getItem("login-token") ??
          sessionStorage.getItem("login-token")
      );
      formData.append("video_id", videoId);
      formData.append("title", "");
      formData.append("body", questionBody);

      try {
        const response = await axios.post(
          `${props.url}material/upload-faq`,
          formData
        );

        setFaqQuestionData(response.data);

        if (response.data.succeeded && response.data.length !== 0) {
          Swal.fire({
            icon: "success",
            title: getTranslatedWord(response.data.title),
            text: getTranslatedWord(response.data.title),
          });

          for (const formImageData of formDataImageArray) {
            let formData = new FormData();
            formData.append(
              "token",
              localStorage.getItem("login-token") ??
                sessionStorage.getItem("login-token")
            );
            formData.append("table_name", response.data.table_name);
            formData.append("row_id", response.data.faq_id);
            formData.append("file", formImageData); // Append the "file" property from the formImageData object

            const secondResponse = await axios.post(
              `${props.url}side/upload-images`,
              formData
            );

            setFaqQuestionData(secondResponse.data);

            // if (secondResponse.data.succeeded) {
            //   Swal.fire({
            //     icon: "success",
            //     title: getTranslatedWord(secondResponse.data.title),
            //     text: getTranslatedWord(secondResponse.data.message),
            //   });
            // } else {
            //   Swal.fire({
            //     icon: "warning",
            //     title: getTranslatedWord(secondResponse.data.title),
            //     text: getTranslatedWord(secondResponse.data.message),
            //   });
            // }
          }
        } else {
          Swal.fire({
            icon: "warning",
            title: getTranslatedWord(response.data.title),
            text: getTranslatedWord(response.data.messgae),
          });
        }
      } catch (error) {
        alert(error.message);
      }
    }
  }

  const { className, btnText } = props;

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  /**to handle the upload image  */
  const [showPreview, setShowPreview] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileSelect = (event) => {
    setSelectedFile(event.target.files[0]);
    setShowPreview(true);
  };

  const handleUpload = () => {
    // TODO: Handle the file upload here
    setShowPreview(false);
  };

  const handleCancel = () => {
    setSelectedFile(null);
    setShowPreview(false);
  };
  /**handling upload image ends here */

  return (
    <>
      <Button variant="" onClick={handleShow} className={className}>
        Have a Question? Click here to ask a Question
      </Button>

      <Modal
        className="ask-question-modal"
        show={show}
        size={"m"}
        onHide={handleClose}
        dialogClassName="modal-10w"
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Form onSubmit={handleAskQuestionSubmit}>
          <Modal.Body>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label style={{ fontWeight: "700" }}>
                Ask Your Question
              </Form.Label>
              <Form.Control
                style={{ height: "250px" }}
                as="textarea"
                rows={3}
                onChange={(event) => setQuestionBody(event.target.value)}
              />
            </Form.Group>
            <Form.Group style={{ height: "100px" }}>
              <Form.Control
                type="file"
                name="myFile"
                onChange={(e) => handleChange(e)}
                multiple
              />
              <Modal
                style={{
                  width: "40%",
                }}
                show={selectedDone}
                onHide={() => setSelectedDone(false)}
              >
                <Modal.Body
                  style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}
                >
                  {file.map((file, index) => (
                    <img
                      key={index}
                      src={URL.createObjectURL(file)}
                      alt={`Selected file ${index}`}
                      style={{ width: "150px", height: "150px" }}
                    />
                  ))}
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleCloseImageModal}>
                    Close
                  </Button>
                </Modal.Footer>
              </Modal>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer className="ask-question-modal-footer">
            <Button
              className="ask-your-question-btn"
              variant="outline-primary"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              className="!bg-[#0d6efd] ask-your-question-btn"
              variant="primary"
              type="submit"
            >
              Submit
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}

export default AskAQuestion;
